import "../Scenario.scss";
import { useTranslation } from "react-i18next";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { SyntheticEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  ListMacroScenarioDto,
  UpdateMacroScenarioDto,
} from "orval/generated/models";
import UpdateDetailsModal from "../../../../../components/common/UpdateDetailsModal/UpdateDetailsModal";
import { useModal } from "components/common/Modal";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { CustomIcon, Icons } from "components/common";
import ThreeDotMenu from "components/common/ThreeDotMenu/ThreeDotMenu";
import ConfirmationModal from "components/common/ConfirmationModal/ConfirmationModal";
import {
  getGetAllMacroScenariosScenariosMacroGetQueryKey,
  useDeleteMacroScenarioByIdScenariosMacroScenarioIdDelete,
  useDuplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost,
  useUpdateMacroScenarioScenariosMacroScenarioIdPatch,
} from "orval/generated/endpoint";
import AdjustmentCountChip from "features/micro-tool/components/MicroAdjustmentsSection/MicroCustomAccordion/AdjustmentCountChip/AdjustmentCountChip";

export type MacroSingleScenarioInScenarioListProps = {
  scenario: ListMacroScenarioDto;
};

function MacroSingleScenarioInScenarioList({
  scenario,
}: MacroSingleScenarioInScenarioListProps) {
  const navigate = useNavigate();
  const { t } = useTranslation(["macro", "common", "micro"]);
  const { openModal, closeModal } = useModal();
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { palette } = useTheme();

  const handleViewScenario = (scenarioId: string): void => {
    navigate(`/country-level-analytics/scenario/${scenarioId}`);
  };

  const { mutateAsync: updateMacroScenario } =
    useUpdateMacroScenarioScenariosMacroScenarioIdPatch({
      mutation: {
        onSuccess: async () => {
          navigate(`/country-level-analytics/`);
          showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
          await queryClient.invalidateQueries({
            queryKey: getGetAllMacroScenariosScenariosMacroGetQueryKey(),
          });
          closeModal();
        },
        onError: (error: string | Error | string[] | AxiosError) => {
          showSnackbar(error, "error");
          return false;
        },
      },
    });

  const handleUpdateDetails = useCallback(
    async (
      scenarioId: number,
      data: UpdateMacroScenarioDto,
    ): Promise<boolean> => {
      try {
        await updateMacroScenario({
          scenarioId,
          data,
        });
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    [updateMacroScenario],
  );

  const onHandleUpdateDetails = useCallback(() => {
    if (scenario) {
      openModal(
        <UpdateDetailsModal
          open={true}
          scenario={scenario}
          updateMacroScenarioDetails={handleUpdateDetails}
        ></UpdateDetailsModal>,
      );
    }
  }, [scenario, openModal, handleUpdateDetails]);

  const { mutate: duplicateScenario } =
    useDuplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllMacroScenariosScenariosMacroGetQueryKey(),
          });
          showSnackbar(t("micro:notifications.scenarioDuplicated"), "success");
        },
        onError: (error: string | Error | string[] | AxiosError) => {
          showSnackbar(t("micro:errorMessages.errorDuplicating"), "error");
          console.warn(error);
        },
      },
    });

  const onHandleDuplicateScenario = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      const scenarioId: number = parseInt((event.target as Element).id);
      duplicateScenario({ scenarioId });
    },
    [duplicateScenario],
  );

  const { mutate: deleteMacroScenario } =
    useDeleteMacroScenarioByIdScenariosMacroScenarioIdDelete({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllMacroScenariosScenariosMacroGetQueryKey(),
          });
          showSnackbar(
            t(
              "micro:microViewAllScenariosPage.deleteScenarioModal.scenarioDeleted",
            ),
            "success",
          );
          closeModal();
        },
        onError: (error: string | Error | string[] | AxiosError) => {
          showSnackbar(error, "error");
        },
      },
    });

  const onHandleDeleteScenario = useCallback(() => {
    openModal(
      <ConfirmationModal
        title={`${t("micro:microViewAllScenariosPage.deleteScenarioModal.title")} ${scenario.name}${t(
          "micro:microViewAllScenariosPage.deleteScenarioModal.questionMark",
        )}`}
        description={t(
          "micro:microViewAllScenariosPage.deleteScenarioModal.description",
        )}
        actionTitle={t("common:actions.delete")}
        confirmAction={() => {
          scenario.id && deleteMacroScenario({ scenarioId: scenario.id });
        }}
        cancelAction={closeModal}
      />,
    );
  }, [
    closeModal,
    deleteMacroScenario,
    openModal,
    scenario.id,
    scenario.name,
    t,
  ]);

  const macroThreeDotsMenuItems = {
    menuItems: [
      {
        label: t("common:actions.updateDetails"),
        onClick: () => onHandleUpdateDetails(),
        id: scenario.id,
      },
      {
        label: t("common:actions.duplicate"),
        onClick: async (
          event: Event | React.SyntheticEvent<Element, Event>,
        ) => {
          onHandleDuplicateScenario(event);
        },
        isAsync: true,
        id: scenario.id,
      },
      {
        label: t("common:actions.delete"),
        onClick: () => {
          onHandleDeleteScenario();
        },
        id: scenario.id,
      },
    ],
  };

  return (
    <Box key={scenario.id} data-testid="single-scenario-box">
      <Box
        className="scenario-tile"
        key={scenario.id}
        sx={{ display: "flex", justifyContent: "space-between" }}
        mb={2}
      >
        <Grid width="100%">
          <Box display={"flex"} alignItems={"center"}>
            <Typography
              className={"header-H3 scenario-title"}
              onClick={() => {
                handleViewScenario(scenario.id!.toString());
              }}
              role="button"
              tabIndex={0}
            >
              {scenario.name}
            </Typography>
            <Box
              tabIndex={0}
              marginLeft="auto"
              display="flex"
              alignItems="center"
            >
              {scenario.adjustment_count > 0 ? (
                <>
                  <AdjustmentCountChip scenario={scenario} />
                  <FiberManualRecordIcon
                    sx={{
                      m: 1,
                      fontSize: "5px",
                      color: "black",
                    }}
                  />
                </>
              ) : null}
              <Typography>
                {scenario?.inputs?.years?.base_year !== null &&
                scenario?.inputs?.years?.target_year !== null
                  ? `${scenario?.inputs?.years?.base_year} - ${scenario?.inputs?.years?.target_year}`
                  : ""}
              </Typography>
              {scenario?.inputs?.years?.base_year !== null &&
                scenario?.inputs?.years?.target_year !== null && (
                  <FiberManualRecordIcon
                    sx={{
                      m: 1,
                      fontSize: "5px",
                      color: "black",
                    }}
                  />
                )}
              <Tooltip title={t("common:labels.updatedDate")}>
                <Box display="flex" alignItems="center">
                  <Box mr={0.5}>
                    <CustomIcon
                      name={Icons.UPDATE}
                      width={24}
                      height={24}
                      fill={palette.text.primary}
                    />
                  </Box>
                  <Typography>
                    {new Date(scenario.updated_at || "").toLocaleString(
                      undefined,
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      },
                    )}
                  </Typography>
                </Box>
              </Tooltip>
              <Box>
                <ThreeDotMenu
                  menuItems={macroThreeDotsMenuItems.menuItems}
                ></ThreeDotMenu>
              </Box>
            </Box>
          </Box>
          {scenario.description ? (
            <Box mt={2} pr={2} overflow="hidden">
              <Typography variant={"body1"}>{scenario?.description}</Typography>
            </Box>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
}

export default MacroSingleScenarioInScenarioList;
