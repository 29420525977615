import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import MacroSingleScenarioInScenarioList from "./MacroSingleScenarioInScenarioList/MacroSingleScenarioInScenarioList";
import { NoData, SearchBar } from "components/common";
import { SortConfig, SortValues } from "utils/SortConfig";
import Sort from "components/common/Sort/Sort";
import { type ListMacroScenarioDto } from "orval/generated/models";
import {
  useGetAllMacroScenariosScenariosMacroGet,
  useGetMemberCountriesMembersCountriesGet,
  useGetYearsYearsGet,
} from "orval/generated/endpoint";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import SelectDropdown from "components/common/SelectDropdown/SelectDropdown";
import LoadingList from "components/common/LoadingList/LoadingList";
import LoadingItem from "components/common/LoadingItem/LoadingItem";

export type MacroScenarioListProps = {
  sandboxMacroScenarioIdKey: string;
  sandboxMacroScenarioID: string | null;
  setSandboxMacroScenarioID: Dispatch<SetStateAction<string | null>>;
};

function MacroScenarioList({
  sandboxMacroScenarioIdKey,
  sandboxMacroScenarioID,
  setSandboxMacroScenarioID,
}: MacroScenarioListProps) {
  const defaultSortValue = SortValues.DATE_UPDATED_DESCENDING;
  const { t } = useTranslation("macro");
  const showSnackbar = useSnackbar();

  const [searchValue, setSearchValue] = useState<string>("");
  const [sortValue, setSortValue] = useState<SortValues>(defaultSortValue);
  const [sortedData, setSortedData] = useState<ListMacroScenarioDto[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedTargetYears, setSelectedTargetYears] = useState<number[]>([]);

  const { data: scenarioData, isPending: scenarioDataIsPending } =
    useGetAllMacroScenariosScenariosMacroGet();
  const {
    data: countriesData,
    isPending: countriesDataIsPending,
    isError: errorFetchingCountries,
  } = useGetMemberCountriesMembersCountriesGet();
  const {
    data: yearsData,
    isPending: yearsDataIsPending,
    isError: errorFetchingYears,
  } = useGetYearsYearsGet();

  useEffect(() => {
    if (errorFetchingCountries || errorFetchingYears) {
      showSnackbar(t("errorMessages.getScenarioDetails"), "error");
    }
  }, [errorFetchingCountries, errorFetchingYears, showSnackbar, t]);

  function onSearchChange(value: string): void {
    setSearchValue(value);
    applyDataFilters();
  }

  const applyDataFilters = useCallback(() => {
    if (!scenarioData) return;

    let filteredData = scenarioData?.filter((scenario) => {
      const nameIncludesSearchValue = searchValue
        ? scenario.name.toLowerCase().includes(searchValue.toLowerCase())
        : true;
      const hasSelectedCountry =
        selectedCountries.length > 0
          ? scenario.inputs?.countries?.some((country) =>
              selectedCountries.includes(country.display_name),
            )
          : true;
      const hasSelectedTargetYear =
        selectedTargetYears.length > 0 && scenario.inputs?.years?.target_year
          ? selectedTargetYears.includes(scenario.inputs?.years?.target_year)
          : true;
      return (
        nameIncludesSearchValue && hasSelectedCountry && hasSelectedTargetYear
      );
    });

    setSortedData(filteredData.sort(getSortFunction(sortValue)));
  }, [
    scenarioData,
    searchValue,
    selectedCountries,
    sortValue,
    selectedTargetYears,
  ]);

  function getSortFunction(sortValue: SortValues) {
    switch (sortValue) {
      case SortValues.DATE_UPDATED_ASCENDING:
        return (a: ListMacroScenarioDto, b: ListMacroScenarioDto) =>
          new Date(a.updated_at!).getTime() - new Date(b.updated_at!).getTime();
      case SortValues.DATE_UPDATED_DESCENDING:
        return (a: ListMacroScenarioDto, b: ListMacroScenarioDto) =>
          new Date(b.updated_at!).getTime() - new Date(a.updated_at!).getTime();
      case SortValues.NAME_ASCENDING:
        return (a: ListMacroScenarioDto, b: ListMacroScenarioDto) =>
          a.name.localeCompare(b.name);
      case SortValues.NAME_DESCENDING:
        return (a: ListMacroScenarioDto, b: ListMacroScenarioDto) =>
          b.name.localeCompare(a.name);
      default:
        return () => 0;
    }
  }

  useEffect(() => {
    if (!sandboxMacroScenarioID && sortedData.length) {
      localStorage.setItem(
        sandboxMacroScenarioIdKey,
        sortedData[0].id!.toString(),
      );
      setSandboxMacroScenarioID(sortedData[0].id!.toString());
    }
  }, [
    sandboxMacroScenarioIdKey,
    setSandboxMacroScenarioID,
    sandboxMacroScenarioID,
    sortedData,
  ]);

  const handleCountriesTypeChange = (newSelectedCountries: string[]) => {
    setSelectedCountries(newSelectedCountries);
  };

  const handleYearSelectionChange = (newSelectedYears: number[]) => {
    setSelectedTargetYears(newSelectedYears);
  };

  useEffect(() => {
    if (scenarioData) {
      applyDataFilters();
    }
  }, [scenarioData, applyDataFilters]);

  return (
    <Box>
      <Box>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid size={{ xs: 12, lg: 5, xl: 6 }} data-testid="searchbar">
            <SearchBar
              handleSearch={onSearchChange}
              disabled={scenarioData && scenarioData?.length < 1}
            />
          </Grid>

          <Grid size={{ xs: 4, lg: 2 }} mt={"4px"}>
            {countriesDataIsPending ? (
              <LoadingItem width="100%" height={40} />
            ) : (
              countriesData && (
                <Box width="100%" data-testid="coutries-dropdown">
                  <SelectDropdown
                    listItems={countriesData.map(
                      (country) => country.display_name,
                    )}
                    savedSelectedItems={selectedCountries}
                    onSave={handleCountriesTypeChange}
                    title={t("countryDropdown.countryDropdownPlaceholder")}
                    compact
                    showSearchBar={false}
                    selectAll={true}
                    disabled={scenarioData && scenarioData?.length < 1}
                  />
                </Box>
              )
            )}
          </Grid>

          <Grid size={{ xs: 4, lg: 2 }} mt={"4px"}>
            {yearsDataIsPending ? (
              <LoadingItem width="100%" height={40} />
            ) : (
              yearsData && (
                <Box width="100%" data-testid="years-dropdown">
                  <SelectDropdown<number>
                    listItems={yearsData.map((year) => year.year)}
                    savedSelectedItems={selectedTargetYears}
                    onSave={handleYearSelectionChange}
                    title={t(
                      "targetYearDropdown.targetYearDropdownPlaceholder",
                    )}
                    compact
                    disabled={scenarioData && scenarioData?.length < 1}
                  />
                </Box>
              )
            )}
          </Grid>

          <Grid
            size={{ xs: 4, lg: 3, xl: 2 }}
            display="flex"
            justifyContent="flex-end"
            data-testid="sort-dropdown"
          >
            <Sort<SortValues>
              sortValue={sortValue}
              setSortValue={setSortValue}
              values={SortConfig}
              disabled={scenarioData && scenarioData?.length < 1}
            />
          </Grid>
        </Grid>
      </Box>

      <Box my={2}>
        {scenarioDataIsPending ? (
          <LoadingList />
        ) : !scenarioData?.length ? (
          <NoData
            label={t("common:noData.emptyStateMessage1")}
            label2={t("common:noData.emptyStateMessage2")}
          />
        ) : !sortedData.length ? (
          <NoData label={t("common:noData.noSearchMatchMessage")} />
        ) : (
          sortedData.map((data) => (
            <Box key={data.id}>
              <MacroSingleScenarioInScenarioList scenario={data} />
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
}

export default MacroScenarioList;
