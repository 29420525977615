import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

export type DataExtractionFormPartOneProps = {
  handleDataTypeSelect: Function;
  dataTypeSelectValue: string;
  isPending: boolean;
};

export default function DataExtractionFormPartOne({
  handleDataTypeSelect,
  dataTypeSelectValue,
  isPending,
}: DataExtractionFormPartOneProps) {
  const { t } = useTranslation("productConfiguration");

  return (
    <FormControl sx={{ minWidth: "300px" }} size={"small"}>
      <InputLabel id="data-type">
        {t("dataManagementPage.dataExtraction.dataType.title")}
      </InputLabel>
      <Box display={"flex"} alignItems={"center"}>
        <Box width={"300px"}>
          <Select
            labelId="data-extraction-type-label-id"
            id="data-extraction-type-simple-select"
            value={dataTypeSelectValue}
            label={t("dataManagementPage.dataExtraction.dataType.title")}
            onChange={(event) => handleDataTypeSelect(event)}
            size={"small"}
            data-testid="data-extraction-data-type-input"
            variant="outlined"
            fullWidth
          >
            <MenuItem
              key={t(
                "dataManagementPage.dataExtraction.dataType.configurationData",
              )}
              value={t(
                "dataManagementPage.dataExtraction.dataType.configurationData",
              )}
              data-testid="data-extraction-data-type-configuration-data"
            >
              {t(
                "dataManagementPage.dataExtraction.dataType.configurationData",
              )}
            </MenuItem>
            <MenuItem
              key={t("dataManagementPage.dataExtraction.dataType.numericData")}
              value={t(
                "dataManagementPage.dataExtraction.dataType.numericData",
              )}
              data-testid="data-extraction-data-type-numeric-data"
            >
              {t("dataManagementPage.dataExtraction.dataType.numericData")}
            </MenuItem>
          </Select>
        </Box>
        {isPending ? <CircularProgress size={32} sx={{ ml: 1 }} /> : null}
      </Box>
    </FormControl>
  );
}
