import {
  Box,
  Button,
  MenuList,
  FormControl,
  FormHelperText,
  useTheme,
  MenuItem,
  Tooltip,
  Checkbox,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { SearchBar } from "../SearchBar";
import "./SelectDropdown.scss";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import "../../../assets/styles/mui-overrides/popover.scss";
import { FixedSizeList } from "react-window";
import {
  SelectDropdownListItem,
  SelectDropdownRow,
} from "./SelectDropdownRow/SelectDropdownRow";
import ListItemText from "@mui/material/ListItemText";
import SelectAllRow from "./SelectAllRow/SelectAllRow";
import NoItemsRow from "./NoItemsRow/NoItemsRow";
import { CustomIcon, Icons } from "components/common/CustomIcon";

export interface SelectDropdownProps<T extends string | number> {
  listItems: SelectDropdownListItem<T>[] | T[]; // strings need to be unique
  savedSelectedItems: T[]; // items selected previously and saved
  onSave: (selectedItems: T[], label?: string) => void;
  onRemove?: (items: T[]) => void;
  title: string; // title of the dropdown
  selectAll?: boolean;
  requireSave?: boolean; // if true, 'Apply' button is visible
  showSearchBar?: boolean;
  compact?: boolean;
  formikLabel?: string;
  helperText?: string;
  onOpen?: () => void;
  translate?: boolean;
  disabled?: boolean;
  virtualiseList?: boolean; // use if there are performance issues, but cannot use accordions and nested items
  singleSelect?: boolean;
  disabledItems?: T[];
}

const SelectDropdown = <T extends string | number>(
  props: SelectDropdownProps<T>,
) => {
  const { t } = useTranslation("common");
  const { spacing } = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedItems, setSelectedItems] = useState<Array<T>>([]);
  const [search, setSearch] = useState<string>("");
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [expanded, setExpanded] = useState<string | false>(false);
  const { palette } = useTheme();

  // ? USED FOR VIRTUALISED LIST
  // ? Calculating virtualised list height
  const boxRemHeight = 16;
  const fontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize,
  );
  const verticalSpacing = parseFloat(spacing(7));
  const fixedListPixelHeight = boxRemHeight * fontSize - verticalSpacing;

  const getWidestItemWidth = useCallback(
    (items: SelectDropdownListItem<T>[] | T[]): number => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (!context) return 0;

      context.font = getComputedStyle(document.body).font;
      let maxWidth = 0;

      const measureItem = (item: SelectDropdownListItem<T> | T): number => {
        if (typeof item === "string" || typeof item === "number") {
          return context.measureText(item.toString()).width;
        } else if (Array.isArray(item.value)) {
          return Math.max(...item.value.map(measureItem));
        } else {
          return context.measureText(item.value.toString()).width;
        }
      };

      items.forEach((item) => {
        const itemWidth = measureItem(item);
        if (itemWidth > maxWidth) {
          maxWidth = itemWidth;
        }
      });

      return maxWidth + 40; // Add some padding
    },
    [],
  );

  useEffect(() => {
    if (open) {
      const widestItemWidth = getWidestItemWidth(props.listItems);
      setContainerWidth(Math.max(widestItemWidth, containerWidth, 250));
    }
  }, [containerWidth, getWidestItemWidth, open, props.listItems]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);

  // ? USED TO RECURSIVELY FILTER DURING USE OF SEARCH BAR
  // ? TO PROVIDE A FILTERED LIST OF ITEMS
  const recursiveFilter = (
    items: SelectDropdownListItem<T>[] | Array<T>,
  ): SelectDropdownListItem<T>[] => {
    let result: SelectDropdownListItem<T>[] = [];

    items.forEach((item) => {
      if (typeof item === "string") {
        if (item.toLowerCase().includes(search.toLowerCase())) {
          result.push({ value: item });
        }
      } else if (typeof item === "number") {
        if (item.toString().toLowerCase().includes(search.toLowerCase())) {
          result.push({ value: item });
        }
      } else if (
        typeof item.value === "string" &&
        item.value.toLowerCase().includes(search.toLowerCase())
      ) {
        result.push(item);
      } else if (Array.isArray(item.value)) {
        if (item.title?.toLowerCase().includes(search.toLowerCase())) {
          result.push(item);
        } else {
          const nestedItems = recursiveFilter(item.value);
          if (nestedItems.length > 0) {
            result.push({ ...item, value: nestedItems });
          }
        }
      }
    });
    return result;
  };
  const filteredItems = recursiveFilter(props.listItems);

  const collectValues = (items: SelectDropdownListItem<T>[]): Array<T> => {
    let values: Array<T> = [];
    items.forEach((item) => {
      if (typeof item.value === "string") {
        values.push(item.value);
      } else if (Array.isArray(item.value)) {
        values = values.concat(collectValues(item.value));
      }
    });
    return values;
  };
  const allItemsSelected =
    selectedItems.length === collectValues(filteredItems).length;

  useEffect(() => {
    setSelectedItems(props.savedSelectedItems);
  }, [props.savedSelectedItems]);

  // ? HELPER ACTIONS
  const handleClose = (event?: Event | SyntheticEvent) => {
    if (
      event &&
      anchorRef.current &&
      event.target instanceof Node &&
      (anchorRef.current.contains(event.target) ||
        containerRef.current?.contains(event.target))
    ) {
      return;
    }
    setOpen(false);
    setSelectedItems(props.savedSelectedItems);
    setSearch("");
  };

  const addItem = (item: T) => {
    let newSelectedItems: Array<T>;
    if (props.singleSelect) {
      newSelectedItems = [item];
    } else {
      newSelectedItems = selectedItems.includes(item)
        ? selectedItems.filter((selectedItem: T) => selectedItem !== item)
        : [...selectedItems, item];
    }
    setSelectedItems(newSelectedItems);
    if (props.requireSave === false) {
      props.onSave(newSelectedItems, props.formikLabel);
    }
  };

  const addAllItems = () => {
    const newSelectedItems: Array<T> = allItemsSelected
      ? []
      : collectValues(filteredItems);
    setSelectedItems(newSelectedItems);
    if (props.requireSave === false) {
      props.onSave(newSelectedItems, props.formikLabel);
    }
  };

  const handleSave = (selectedItems: Array<T>) => {
    if (props.onRemove) {
      const removedItems: Array<T> = props.savedSelectedItems.filter(
        (item: T) => !selectedItems.includes(item),
      );
      if (removedItems.length > 0) {
        props.onRemove(removedItems);
      }
    }
    props.onSave(selectedItems, props.formikLabel);
    handleClose();
  };

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      event.stopPropagation(); // Prevent the event from bubbling up
      setExpanded(isExpanded ? panel : false);
    };

  const generateMenu = (
    items: SelectDropdownListItem<T>[],
    childElement?: boolean,
  ) => {
    return items.map((item: SelectDropdownListItem<T>, index) => {
      if (typeof item.value === "string" || typeof item.value === "number") {
        return (
          <MenuItem
            sx={{ height: "2.5em", maxWidth: "100%", overflow: "hidden" }}
            onClick={() => {
              addItem(item.value as T);
            }}
            key={item.value}
            data-testid={`${props.title}-select-dropdown-item-${index}`}
            disabled={
              !(
                !props.disabledItems ||
                !props.disabledItems.includes(item.value)
              )
            }
          >
            <Tooltip
              key={item.value}
              title={item.value.toString().length > 20 ? item.value : ""}
              placement="right"
            >
              <ListItemText
                primary={
                  !childElement && props.translate
                    ? t(`${item.value}`)
                    : item.value
                }
              />
            </Tooltip>
            {!props.singleSelect && (
              <Checkbox
                checked={selectedItems.includes(item.value)}
                onClick={() => {
                  addItem(item.value as T);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    addItem(item.value as T);
                  }
                }}
                data-testid={`${item.value}-checkbox`}
                disabled={
                  !(
                    !props.disabledItems ||
                    !props.disabledItems.includes(item.value)
                  )
                }
              />
            )}
          </MenuItem>
        );
      } else {
        return (
          <Accordion
            sx={{
              paddingLeft: "1em",
              overflow: "hidden",
            }}
            key={item.title}
            expanded={expanded === item.title}
            onChange={handleAccordionChange(item.title ?? index.toString())}
          >
            <AccordionSummary
              expandIcon={
                <CustomIcon
                  name={Icons.CHEVRON_DOWN}
                  width={24}
                  height={24}
                  fill={palette.primary.main}
                />
              }
              sx={{
                border: "none !important",
                margin: "0 !important",
                marginRight: "1em !important",
                minHeight: "0em !important",
                height: "2.5em !important",
              }}
            >
              <Typography className="p" sx={{ fontWeight: "500" }}>
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {generateMenu(item.value, true)}
            </AccordionDetails>
          </Accordion>
        );
      }
    });
  };

  return (
    <Box sx={{ zIndex: 20 }} ref={containerRef} data-testid="dropdown">
      {open && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 29,
          }}
          onClick={handleClose}
        />
      )}
      <FormControl
        variant="outlined"
        fullWidth
        size={props.compact ? "small" : undefined}
      >
        <InputLabel htmlFor={props.title} disabled={props.disabled}>
          {props.title}
        </InputLabel>
        <Select
          variant="outlined"
          id={props.title}
          multiple={!props.singleSelect}
          label={props.title}
          open={open}
          onClose={handleClose}
          onOpen={() => {
            setOpen(true);
            props.onOpen && props.onOpen();
          }}
          value={
            props.singleSelect
              ? props.savedSelectedItems[0] || ""
              : props.savedSelectedItems.length > 0
                ? props.savedSelectedItems
                : []
          }
          renderValue={(selected) => {
            if (props.singleSelect) {
              return selected ? selected.toString() : null;
            } else if (Array.isArray(selected)) {
              if (selected.length === 0) {
                return null;
              }
              const translatedSelected = props.translate
                ? selected.map((item) => t(`${item}`))
                : selected;
              return translatedSelected.join(", ");
            }
          }}
          data-testid={`${props.title.replace(/\s+/g, "-")}-select-dropdown`}
          disabled={props.disabled}
        >
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {(props.listItems.length > 9 || props.showSearchBar) && (
                <Box
                  sx={{ padding: 1 }}
                  onClick={(event) => event.stopPropagation()}
                >
                  <SearchBar handleSearch={setSearch} />
                </Box>
              )}
              <Button
                variant="outlined"
                onClick={() => {
                  if (props.requireSave) {
                    setSelectedItems([]);
                  } else {
                    handleSave([]);
                  }
                }}
                sx={{ marginTop: 1 }}
                className="button clear"
                disabled={
                  selectedItems.length === 0 ||
                  (props.disabledItems?.length !== undefined &&
                    selectedItems.length - props.disabledItems.length === 0)
                }
                data-testid={`${props.title}-select-dropdown-clear`}
              >
                {t("common:actions.clear")}{" "}
                {selectedItems.length !== 0
                  ? `(${selectedItems.length - (props.disabledItems?.length || 0)})`
                  : ""}{" "}
              </Button>
            </Box>
            {props.virtualiseList ? (
              <FixedSizeList
                height={fixedListPixelHeight}
                itemCount={filteredItems.length + (props.selectAll ? 1 : 0)}
                itemSize={40}
                width={containerWidth}
              >
                {({
                  index,
                  style,
                }: {
                  index: number;
                  style: React.CSSProperties;
                }) => (
                  <SelectDropdownRow
                    index={index}
                    style={style}
                    filteredItems={filteredItems}
                    selectedItems={selectedItems}
                    addAllItems={addAllItems}
                    allItemsSelected={allItemsSelected}
                    addItem={addItem}
                    translate={props.translate}
                    selectAll={props.selectAll}
                    disabledItems={props.disabledItems}
                  />
                )}
              </FixedSizeList>
            ) : (
              <MenuList
                id="composition-menu"
                aria-labelledby="composition-button"
                sx={{
                  height: "14em",
                  overflow: "auto",
                }}
              >
                {props.selectAll && filteredItems.length !== 0 && (
                  <SelectAllRow
                    addAllItems={addAllItems}
                    allItemsSelected={allItemsSelected}
                  ></SelectAllRow>
                )}
                {filteredItems.length === 0 ? (
                  <NoItemsRow></NoItemsRow>
                ) : (
                  <Box>{generateMenu(filteredItems)}</Box>
                )}
              </MenuList>
            )}
            {props.requireSave !== false && (
              <Box>
                <Button
                  variant="outlined"
                  disableElevation
                  className="button footer"
                  onClick={handleClose}
                  data-testid={`${props.title}-select-dropdown-cancel`}
                >
                  {t("common:actions.cancel")}
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  className="button footer"
                  onClick={() => {
                    handleSave(selectedItems);
                  }}
                  disabled={filteredItems.length === 0}
                  data-testid={`${props.title}-select-dropdown-apply`}
                >
                  {t("common:actions.apply")}
                </Button>
              </Box>
            )}
          </Box>
        </Select>
        <FormHelperText error>{props.helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default SelectDropdown;
