import {
  CountryViewModel,
  MacroFilter,
  UserDataTypes,
} from "orval/generated/models";
import { ReactNode } from "react";

export enum MacroAdjustmentPillars {
  Ingredients = "Ingredients",
  Packaging = "Packaging",
  Manufacturing = "Manufacturing",
  Logistics = "Logistics",
  CDE = "CDE",
  SalesMix = "Sales mix",
}

export enum MacroAdjustmentInputAreas {
  IngredientWeights = "Ingredient weights",
  IngredientEmissionFactors = "Ingredient emission factors",
  PackagingWeights = "Packaging weights",
  RecycledContent = "Recycled content",
  RecoveryRate = "Recovery rate",
  NetLossRate = "Net loss rate",
  PackagingEmissionFactors = "Packaging emission factors",
  EnergyUsageRatio = "Manufacturing EUR",
  SourceMixAndEmissionFactors = "Source mix and emission factors",
  DistanceTravelled = "Distance travelled",
  RoadVehicleMixAndEmissionFactors = "Road vehicle mix and emission factors",
  OtherVehicleMixAndEmissionFactors = "Other vehicle mix and emission factors",
  CDEFleetBreakdown = "CDE fleet breakdown",
  CDEEnergyMixAndEmissionFactors = "CDE energy mix and emission factors",
  SalesGrowth = "Sales growth (CAGR)",
  CategoryMix = "Category mix",
  PackMix = "Pack mix",
  DLZShare = "DLZ share",
}

export enum MacroAdjustmentTypes {
  MultiBrandAdjustment = "Multi brand adjustment",
  SpecificBrandAdjustment = "Specific brand adjustment",
  MultiSKUAdjustment = "Multi SKU adjustment",
  SpecificSKUAdjustment = "Specific SKU adjustment",
  NotApplicable = "Not applicable",
}

export enum MacroAdjustmentCombinations {
  Ingredients_IngredientWeights_MultiBrandAdjustment = "Ingredients_IngredientWeights_MultiBrandAdjustment",
  Ingredients_IngredientWeights_SpecificBrandAdjustment = "Ingredients_IngredientWeights_SpecificBrandAdjustment",
  Ingredients_IngredientEmissionFactors = "Ingredients_IngredientEmissionFactors",
  Packaging_PackagingWeights_MultiSKUAdjustment = "Packaging_PackagingWeights_MultiSKUAdjustment",
  Packaging_PackagingWeights_SpecificSKUAdjustment = "Packaging_PackagingWeights_SpecificSKUAdjustment",
  Packaging_RecycledContent = "Packaging_RecycledContent",
  Packaging_RecoveryRate = "Packaging_RecoveryRate",
  Packaging_NetLossRate = "Packaging_NetLossRate",
  Packaging_PackagingEmissionFactors = "Packaging_PackagingEmissionFactors",
  Manufacturing_ManufacturingEur = "Manufacturing_ManufacturingEur",
  Manufacturing_SourceMixAndEmissionFactors = "Manufacturing_SourceMixAndEmissionFactors",
  Logistics_DistanceTravelled = "Logistics_DistanceTravelled",
  Logistics_RoadVehicleMixAndEmissionFactors = "Logistics_RoadVehicleMixAndEmissionFactors",
  Logistics_OtherVehicleMixAndEmissionFactors = "Logistics_OtherVehicleMixAndEmissionFactors",
  CDE_CDEFleetBreakdown = "CDE_CDEFleetBreakdown",
  CDE_CDEEnergyMixAndEmissionFactors = "CDE_CDEEnergyMixAndEmissionFactors",
  SalesMix_SalesGrowth = "SalesMix_SalesGrowth",
  SalesMix_CategoryMix = "SalesMix_CategoryMix",
  SalesMix_PackMix = "SalesMix_PackMix",
  SalesMix_DLZShare = "SalesMix_DLZShare",
}

export type MacroAdjustmentCombination = {
  combo: MacroAdjustmentCombinations;
  name: string;
};

export interface MacroAdjustmentStep {
  index: number;
  label: string | null;
  component: ReactNode;
  leftBtnText: string;
  rightBtnText: string;
  stepTitle: string | null;
  stepSubtitle?: string;
}

export enum MacroFilterDropdownTypes {
  Countries = "countries",
  Categories = "categories",
  BrandGroups = "brand_groups",
  Brands = "brands",
}

export type MacroAdjustmentWizardFormikValues = {
  payload: {
    description?: string | null;
    entities: {
      action: UserDataTypes;
      adjusted_value: number;
      part_material_id?: number;
      ingredient_id?: number;
      country?: string;
      energy_type_id?: number;
      transport_mode_id?: number;
    }[];
    filters: { [key: string]: number[] | string[] };
    name: string;
  };
  selectedPillar: MacroAdjustmentPillars | null;
  selectedInputArea: MacroAdjustmentInputAreas | null;
  selectedAdjustmentType: MacroAdjustmentTypes | null;
  selectedCountries: CountryViewModel[];
  selectedEntities: MacroFilter[];
  selectedAction: "percentage" | "new_value";
  selectedProducts: number[];
};
