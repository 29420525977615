import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./MacroAdjustmentsPage.scss";
import { useState } from "react";
import { useGetMacroScenarioByIdScenariosMacroScenarioIdGet } from "orval/generated/endpoint";
import MacroAdjustmentsPageBody from "../../components/MacroAdjustmentsPageBody/MacroAdjustmentsPageBody";
import { MacroToolRoutesConfig } from "../../navigation/config";
import MacroAdjustmentsPageHeader from "../../components/MacroAdjustmentsPageHeader/MacroAdjustmentsPageHeader";

const MacroAdjustmentsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: urlScenarioId } = useParams<{ id: string }>();
  const scenarioId = urlScenarioId || location.state?.id;
  const [headersIndent, setHeadersIndent] = useState<string>(
    location.state?.headersIndent || "14px",
  );
  setTimeout(() => setHeadersIndent("0px"), 300);

  const scenarioPageURL = MacroToolRoutesConfig.scenarioPage.replace(
    ":id",
    scenarioId,
  );

  const handleNavigateToResults = () => {
    navigate(scenarioPageURL, {
      state: {
        headersIndent: headersIndent,
      },
    });
  };

  const { data: scenario } = useGetMacroScenarioByIdScenariosMacroScenarioIdGet(
    parseInt(scenarioId),
  );

  return (
    <>
      {scenario ? (
        <>
          <MacroAdjustmentsPageHeader
            scenario={scenario}
            handleNavigateToResults={handleNavigateToResults}
            headersIndent={headersIndent}
          ></MacroAdjustmentsPageHeader>
          <MacroAdjustmentsPageBody
            scenario={scenario}
          ></MacroAdjustmentsPageBody>
        </>
      ) : null}
    </>
  );
};

export default MacroAdjustmentsPage;
