import { Icons } from "components/common";

export const dataManagementTabs = {
  tabs: [
    {
      label: "productConfiguration:dataManagementPage.tabLabels.versionHistory",
      icon: Icons.REVERT,
    },
    {
      label: "productConfiguration:dataManagementPage.tabLabels.dataExtraction",
      icon: Icons.DOWNLOAD_SIMPLE,
    },
  ],
} as const;
