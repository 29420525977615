import {
  MRT_TableContainer as MaterialTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { OnChangeFn, VisibilityState } from "@tanstack/table-core";

export type TableEntityType = {
  id: number;
  baseYear?: number;
  targetYear?: number;
  adjusted_value: number | null;
  action?: "percentage" | "new_value" | null;
  country?: string;
};

export type MacroAdjustmentStepThreeBasicTableProps = {
  columns: MRT_ColumnDef<TableEntityType>[];
  tableData: any;
  columnVisibility: {
    baseYear: boolean;
    targetYear: boolean;
  };
  setColumnVisibility: any;
};

function MacroAdjustmentStepThreeBasicTable({
  columns,
  tableData,
  columnVisibility,
  setColumnVisibility,
}: MacroAdjustmentStepThreeBasicTableProps) {
  const table = useMaterialReactTable({
    columns,
    data: tableData,
    editDisplayMode: "table",
    enableEditing: true,
    enableColumnActions: false,
    enableSorting: false,
    enablePagination: true,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      columnOrder: ["id", "baseYear", "targetYear", "adjusted_value"],
    },
    state: { columnVisibility },
    onColumnVisibilityChange:
      setColumnVisibility as OnChangeFn<VisibilityState>,
    getRowId: (row: any) => row.id,
    muiTableContainerProps: {
      sx: {
        border: "1px solid #E0E0E0",
        overflowY: "auto",
      },
    },
  });
  return <MaterialTable className="macro-accordion" table={table} />;
}

export default MacroAdjustmentStepThreeBasicTable;
