import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { MacroAdjustmentStepOne } from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepOne/MacroAdjustmentStepOne";
import { MacroAdjustmentStepTwo } from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/MacroAdjustmentStepTwo";
import { MacroAdjustmentStepThree } from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepThree/MacroAdjustmentStepThree";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MacroToolRoutesConfig } from "../../navigation/config";
import { Form, Formik, FormikProps } from "formik";
import * as yup from "yup";
import ConfirmationModal from "../../../../components/common/ConfirmationModal/ConfirmationModal";
import { useModal } from "components/common/Modal";
import _ from "lodash";
import MacroAdjustmentsWizardHeaderAndStepper from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentsWizardHeaderAndStepper/MacroAdjustmentsWizardHeaderAndStepper";
import MacroAdjustmentsWizardFooter from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentsWizardFooter/MacroAdjustmentsWizardFooter";
import {
  MacroAdjustmentCombination,
  MacroAdjustmentCombinations,
  MacroAdjustmentInputAreas,
  MacroAdjustmentPillars,
  MacroAdjustmentStep,
  MacroAdjustmentTypes,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";
import {
  useEditScenariosMacroScenarioIdAdjustmentsAdjustmentIdPatch,
  useGetByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGet,
  useGetMacroFiltersAndFilteredRtdlScenariosMacroFiltersGet,
  getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey,
  useGetMacroScenarioByIdScenariosMacroScenarioIdGet,
  useMacroIngredientWeightAdjustmentScenariosMacroScenarioIdAdjustmentsIngredientsWeightPost,
  getGetByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGetQueryKey,
  useMacroPackagingWeightAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingWeightPost,
  useMacroPackagingRecycledContentAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingRecycledContentPost,
  useMacroPackagingBreakageRateAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingNetLossRatePost,
  useMacroPackagingRecoveryRateAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingRecoveryRatePost,
  useMacroManufacturingEurAdjustmentScenariosMacroScenarioIdAdjustmentsManufacturingEurPost,
  useMacroLogisticsDistanceTravelledAdjustmentScenariosMacroScenarioIdAdjustmentsLogisticsDistanceTravelledPost,
} from "orval/generated/endpoint";
import { useGlobalLoader } from "components/common";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import {
  CountryViewModel,
  IngredientMacroAdjustmentDto,
  type GetMacroFiltersAndFilteredRtdlScenariosMacroFiltersGetParams,
  IngredientEntity,
  MacroFilter,
  MacroFiltersAndFilteredRTDL,
  PackagingEntity,
  UserDataTypes,
  ManufacturingEntityWithCountryDto,
  LogisticsEntityWithCountryDto,
} from "orval/generated/models";
import { useQueryClient } from "@tanstack/react-query";

const MacroAdjustmentWizard = () => {
  const { t } = useTranslation(["macro", "common"]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal, closeModal } = useModal();
  const { id: urlScenarioId, adjustmentId } = useParams<{
    id: string;
    adjustmentId: string;
  }>();
  const scenarioId = urlScenarioId as string;

  const [activeStep, setActiveStep] = useState(0);
  const { showGlobalLoader } = useGlobalLoader();
  const headersIndent: string = location.state?.headersIndent || "0px";
  const showSnackbar = useSnackbar();
  const [adjustmentCombination, setAdjustmentCombination] =
    useState<MacroAdjustmentCombination | null>(null);

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string | undefined;
  }>({});

  const [isFormInitialized, setIsFormInitialized] = useState(false);

  const { data: scenarioDetails } =
    useGetMacroScenarioByIdScenariosMacroScenarioIdGet(parseInt(scenarioId), {
      query: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    });

  const { data: adjustmentDetails } =
    useGetByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGet(
      parseInt(scenarioId),
      parseInt(adjustmentId as string),
      {
        query: {
          enabled: Boolean(adjustmentId),
          refetchOnMount: false,
          refetchOnWindowFocus: false,
        },
      },
    );

  const [macroFiltersParams, setMacroFiltersParams] =
    useState<GetMacroFiltersAndFilteredRtdlScenariosMacroFiltersGetParams>({
      countries: [],
      target_year: 2030,
      categories: [],
      brands: [],
      brand_groups: [],
    });

  useEffect(() => {
    setMacroFiltersParams(
      Boolean(adjustmentId)
        ? {
            countries:
              (adjustmentDetails?.filters.countries as string[]) ||
              scenarioDetails?.inputs?.countries?.map(
                (country) => country.country,
              ) ||
              [],
            target_year: scenarioDetails?.inputs?.years?.target_year || 2030,
            categories: adjustmentDetails?.filters.categories as number[],
            brands: adjustmentDetails?.filters.brands as number[],
            brand_groups: adjustmentDetails?.filters.brand_groups as number[],
          }
        : {
            countries:
              scenarioDetails?.inputs?.countries?.map(
                (country) => country.country,
              ) || [],
            target_year: scenarioDetails?.inputs?.years?.target_year || 2030,
          },
    );
  }, [adjustmentDetails, adjustmentId, scenarioDetails]);

  const {
    data: filtersData,
    isPending: filtersDataIsPending,
    error: errorFetchingFiltersData,
  } = useGetMacroFiltersAndFilteredRtdlScenariosMacroFiltersGet(
    macroFiltersParams,
    {
      query: {
        enabled:
          Boolean(macroFiltersParams.countries?.length) &&
          Boolean(macroFiltersParams?.target_year),
      }, //TODO: call only if needed (based adjustment type)
    },
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    errorFetchingFiltersData
      ? showSnackbar("Error fetching filters data", "error")
      : null;
  }, [errorFetchingFiltersData, showSnackbar]);

  const getStepTitle = useCallback(
    (
      stepIndex: number,
      formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
    ) => {
      if (stepIndex === 0) {
        return Boolean(adjustmentId)
          ? t("macro:adjustmentsPage.wizard.stepOneUpdateTitle")
          : t("macro:adjustmentsPage.wizard.stepOneTitle");
      } else if (stepIndex === 1 || stepIndex === 2) {
        return (
          formikProps.values.payload.name ||
          t("macro:adjustmentsPage.wizard.stepOneTitle")
        );
      }
    },
    [adjustmentId, t],
  );

  const getStepSubtitle = useCallback(() => {
    return (
      (adjustmentCombination && adjustmentCombination.name) ||
      t("macro:adjustmentsPage.wizard.stepOneTitle")
    );
  }, [adjustmentCombination, t]);

  const handleLeftButtonClick = () => {
    if (activeStep === 0) {
      navigateToAdjustments();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const macroAdjustmentValidationSchema = yup.object({
    payload: yup.object({
      description: yup.string().nullable(),
      entities: yup
        .array()
        .of(
          yup.object().shape({
            ingredient_id: yup.number().nullable(),
            part_material_id: yup.number().nullable(),
            adjusted_value: yup.number(),
            action: yup
              .mixed<UserDataTypes>()
              .oneOf(["percentage", "new_value"]),
          }),
        )
        .required(),
      filters: yup.object({
        brand_groups: yup.array().of(yup.number()),
        brands: yup.array().of(yup.number()),
        categories: yup.array().of(yup.number()),
        countries: yup.array().of(yup.string()),
        products: yup.array().of(yup.number()),
      }),
      name: yup
        .string()
        .min(3)
        .required(
          t("macro:adjustmentsPage.wizard.validationErrors.adjustmentName"),
        ),
    }),
    selectedPillar: yup
      .mixed<MacroAdjustmentPillars>()
      .oneOf(Object.values(MacroAdjustmentPillars))
      .nullable(),
    selectedInputArea: yup
      .mixed<MacroAdjustmentInputAreas>()
      .oneOf(Object.values(MacroAdjustmentInputAreas))
      .nullable(),
    selectedAdjustmentType: yup
      .mixed<MacroAdjustmentTypes>()
      .oneOf(Object.values(MacroAdjustmentTypes))
      .nullable(),
    selectedCountries: yup.array().of(
      yup.object().shape({
        country: yup.string().required(),
      }),
    ),
    selectedEntities: yup.array().of(
      yup.object().shape({
        id: yup.number().required(),
      }),
    ),
    selectedAction: yup
      .mixed<UserDataTypes>()
      .oneOf(["percentage", "new_value"]),
    selectedProducts: yup.array().of(yup.number()),
  });

  const updateAdjustmentCombination = useCallback(
    (
      selectedPillar: MacroAdjustmentPillars | null,
      selectedInputArea: MacroAdjustmentInputAreas | null,
      selectedAdjustmentType: MacroAdjustmentTypes | null,
    ) => {
      if (selectedPillar && selectedInputArea && selectedAdjustmentType) {
        const newSelectedInputArea = _.upperFirst(
          _.camelCase(selectedInputArea),
        );
        const newSelectedAdjustmentType = selectedAdjustmentType.includes("SKU")
          ? selectedAdjustmentType.replace(" SKU adjustment", "SKUAdjustment")
          : _.upperFirst(_.camelCase(selectedAdjustmentType));
        const combination: MacroAdjustmentCombination = {
          combo:
            `${selectedPillar}_${newSelectedInputArea}${selectedAdjustmentType !== MacroAdjustmentTypes.NotApplicable ? "_" + newSelectedAdjustmentType : ""}` as MacroAdjustmentCombinations,
          name: `${selectedPillar} - ${selectedInputArea}${selectedAdjustmentType !== MacroAdjustmentTypes.NotApplicable ? " - " + selectedAdjustmentType : ""}`,
        };
        setAdjustmentCombination(combination);
      } else {
        setAdjustmentCombination(null);
      }
    },
    [],
  );

  const steps = useCallback(
    (
      formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
    ): MacroAdjustmentStep[] => [
      {
        index: 0,
        label: adjustmentId
          ? t("macro:adjustmentsPage.wizard.stepOne.updateLabel")
          : t("macro:adjustmentsPage.wizard.stepOne.label"),
        component: (
          <MacroAdjustmentStepOne
            updateAdjustmentCombination={updateAdjustmentCombination}
            isEditWizard={Boolean(adjustmentId)}
            setMacroFiltersParams={setMacroFiltersParams}
            scenarioDetails={scenarioDetails}
          />
        ),
        leftBtnText: t("common:actions.cancel"),
        rightBtnText: t("common:actions.next"),
        stepTitle: adjustmentId
          ? t("macro:adjustmentsPage.wizard.stepOneUpdateTitle")
          : t("macro:adjustmentsPage.wizard.stepOneTitle"),
      },
      {
        index: 1,
        label: t("macro:adjustmentsPage.wizard.stepTwo.label"),
        component: (
          <MacroAdjustmentStepTwo
            adjustmentCombination={adjustmentCombination}
            scenario={scenarioDetails}
            filtersData={filtersData as MacroFiltersAndFilteredRTDL}
            setMacroFiltersParams={setMacroFiltersParams}
            filtersDataIsPending={filtersDataIsPending}
          />
        ),
        leftBtnText: t("common:actions.back"),
        rightBtnText: t("common:actions.next"),
        stepTitle: getStepTitle(1, formikProps) || "",
        stepSubtitle: getStepSubtitle(),
      },
      {
        index: 2,
        label: t("macro:adjustmentsPage.wizard.stepThree.label"),
        component: (
          <MacroAdjustmentStepThree
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            scenario={scenarioDetails}
            adjustmentCombination={adjustmentCombination?.combo}
          />
        ),
        leftBtnText: t("common:actions.back"),
        rightBtnText: t("common:actions.save"),
        stepTitle: getStepTitle(2, formikProps) || "",
        stepSubtitle: getStepSubtitle(),
      },
    ],
    [
      adjustmentCombination,
      adjustmentId,
      filtersData,
      filtersDataIsPending,
      getStepSubtitle,
      getStepTitle,
      scenarioDetails,
      t,
      updateAdjustmentCombination,
      validationErrors,
    ],
  );

  const navigateToAdjustments = useCallback(() => {
    navigate(MacroToolRoutesConfig.adjustmentsPage.replace(":id", scenarioId), {
      state: {
        headersIndent: headersIndent,
      },
    });
  }, [headersIndent, navigate, scenarioId]);

  const handleNavigateToAdjustmentsPage = (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => {
    const showModal = !_.isEqual(formikProps.values, formikProps.initialValues);
    const modalProps = {
      title: t("macro:adjustmentsPage.wizard.lockInModal.title"),
      description: t("macro:adjustmentsPage.wizard.lockInModal.description"),
      actionTitle: t(
        "macro:adjustmentsPage.wizard.lockInModal.backToAdjustments",
      ),
      confirmAction: () => {
        navigateToAdjustments();
        closeModal();
      },
      cancelAction: closeModal,
    };
    showModal
      ? openModal(<ConfirmationModal {...modalProps} />)
      : navigateToAdjustments();
  };

  const createOptions = useMemo(
    () => ({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey(
              parseInt(scenarioId),
            ),
          });

          navigateToAdjustments();

          showSnackbar(
            t("macro:adjustmentsPage.wizard.successMessage"),
            "success",
          );
        },
      },
    }),
    [navigateToAdjustments, queryClient, scenarioId, showSnackbar, t],
  );

  const editOptions = useMemo(
    () => ({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey(
              parseInt(scenarioId),
            ),
          });

          await queryClient.invalidateQueries({
            queryKey:
              getGetByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGetQueryKey(
                parseInt(scenarioId),
                adjustmentId as unknown as number,
              ),
          });

          navigateToAdjustments();

          showSnackbar(
            t("macro:adjustmentsPage.wizard.updateSuccessMessage"),
            "success",
          );
        },
      },
    }),
    [
      adjustmentId,
      navigateToAdjustments,
      queryClient,
      scenarioId,
      showSnackbar,
      t,
    ],
  );

  const { mutateAsync: createIngredientWeightsAdjustment } =
    useMacroIngredientWeightAdjustmentScenariosMacroScenarioIdAdjustmentsIngredientsWeightPost(
      createOptions,
    );

  const { mutateAsync: createRecycledContentAdjustment } =
    useMacroPackagingRecycledContentAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingRecycledContentPost(
      createOptions,
    );

  const { mutateAsync: createNetLossAdjustment } =
    useMacroPackagingBreakageRateAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingNetLossRatePost(
      createOptions,
    );

  const { mutateAsync: createRecoveryRateAdjustment } =
    useMacroPackagingRecoveryRateAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingRecoveryRatePost(
      createOptions,
    );

  const { mutateAsync: createPackagingWeightsAdjustment } =
    useMacroPackagingWeightAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingWeightPost(
      createOptions,
    );

  const { mutateAsync: createEurAdjustment } =
    useMacroManufacturingEurAdjustmentScenariosMacroScenarioIdAdjustmentsManufacturingEurPost(
      createOptions,
    );

  const { mutateAsync: createDistanceTranveledAdjustment } =
    useMacroLogisticsDistanceTravelledAdjustmentScenariosMacroScenarioIdAdjustmentsLogisticsDistanceTravelledPost(
      createOptions,
    );

  const { mutateAsync: editAdjustment } =
    useEditScenariosMacroScenarioIdAdjustmentsAdjustmentIdPatch(editOptions);

  const getSaveFunction = useCallback(() => {
    if (!adjustmentCombination) return undefined;

    if (adjustmentId) return editAdjustment;

    switch (adjustmentCombination.combo) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        return createIngredientWeightsAdjustment;
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return createPackagingWeightsAdjustment;

      case MacroAdjustmentCombinations.Packaging_RecycledContent:
        return createRecycledContentAdjustment;

      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return createNetLossAdjustment;

      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return createRecoveryRateAdjustment;

      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return createEurAdjustment;

      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return createDistanceTranveledAdjustment;

      default:
        return undefined;
    }
  }, [
    adjustmentCombination,
    adjustmentId,
    createDistanceTranveledAdjustment,
    createEurAdjustment,
    createIngredientWeightsAdjustment,
    createNetLossAdjustment,
    createPackagingWeightsAdjustment,
    createRecoveryRateAdjustment,
    createRecycledContentAdjustment,
    editAdjustment,
  ]);

  const getAdjustmentType = useCallback((): string | undefined => {
    if (!adjustmentCombination) return undefined;

    switch (adjustmentCombination.combo) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
        return "multi";
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return "specific";
      default:
        return undefined;
    }
  }, [adjustmentCombination]);

  const getPayload = useCallback(
    (formikValues: MacroAdjustmentWizardFormikValues) => {
      if (!adjustmentCombination) return undefined;

      switch (adjustmentCombination.combo) {
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
          const payloadIngredientEntities: IngredientEntity[] = formikValues
            .payload.entities as IngredientEntity[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadIngredientEntities.map(
              (entity: IngredientEntity) => ({
                adjusted_value: entity.adjusted_value || 0,
                ingredient_id: entity.ingredient_id,
                action: formikValues.selectedAction,
              }),
            ) as IngredientEntity[],
            filters: {
              brand_groups: formikValues.payload.filters.brand_groups || [],
              brands: formikValues.payload.filters.brands || [],
              categories: formikValues.payload.filters.categories || [],
              countries: formikValues.payload.filters.countries || [],
            },
          } as IngredientMacroAdjustmentDto;
        case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
        case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        case MacroAdjustmentCombinations.Packaging_RecycledContent:
        case MacroAdjustmentCombinations.Packaging_NetLossRate:
        case MacroAdjustmentCombinations.Packaging_RecoveryRate:
          const payloadPackagingEntities: PackagingEntity[] = formikValues
            .payload.entities as PackagingEntity[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadPackagingEntities
              .map((entity: PackagingEntity) => ({
                adjusted_value: entity.adjusted_value || 0,
                part_material_id: entity.part_material_id,
                action: formikValues.selectedAction,
                ...(adjustmentCombination.combo ===
                MacroAdjustmentCombinations.Packaging_RecoveryRate
                  ? { country: entity.country }
                  : {}),
              }))
              .filter(
                (entity) =>
                  !isNaN(entity.part_material_id) &&
                  !isNaN(entity.adjusted_value),
              ) as PackagingEntity[],
            filters: {
              products: formikValues.selectedProducts || [],
              countries: formikValues.payload.filters.countries || [],
            },
          };
        case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
          const payloadManufacturingEntities: ManufacturingEntityWithCountryDto[] =
            formikValues.payload
              .entities as ManufacturingEntityWithCountryDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            action: formikValues.selectedAction,
            entities: payloadManufacturingEntities
              .map((entity: ManufacturingEntityWithCountryDto) => ({
                adjusted_value: entity.adjusted_value || 0,
                energy_type_id: entity.energy_type_id,
                country: entity.country,
              }))
              .filter(
                (entity) =>
                  !isNaN(entity.energy_type_id) &&
                  !isNaN(entity.adjusted_value),
              ),
            filters: {
              products: formikValues.selectedProducts || [],
              countries: formikValues.payload.filters.countries || [],
            },
          };

        case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
          const payloadDistanceTravelledEntities: LogisticsEntityWithCountryDto[] =
            formikValues.payload.entities as LogisticsEntityWithCountryDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            action: formikValues.selectedAction,
            entities: payloadDistanceTravelledEntities
              .map((entity: LogisticsEntityWithCountryDto) => ({
                adjusted_value: entity.adjusted_value || 0,
                transport_mode_id: entity.transport_mode_id,
                country: entity.country,
              }))
              .filter(
                (entity) =>
                  !isNaN(entity.transport_mode_id) &&
                  !isNaN(entity.adjusted_value),
              ),
            filters: {
              products: formikValues.selectedProducts || [],
              countries: formikValues.payload.filters.countries || [],
            },
          };
        default:
          return undefined;
      }
    },
    [adjustmentCombination],
  );

  const handleSaveAdjustment = useCallback(
    async (formikValues: MacroAdjustmentWizardFormikValues): Promise<void> => {
      try {
        const saveFunction: any = getSaveFunction();
        const type = getAdjustmentType();
        const payload = getPayload(formikValues);
        if (saveFunction) {
          if (!adjustmentId && type) {
            await saveFunction({
              scenarioId: scenarioId,
              data: payload,
              params: {
                adjustment_type: type,
              },
            });
          } else {
            await saveFunction({
              scenarioId: scenarioId,
              adjustmentId: adjustmentId,
              data: payload,
            });
          }
        }
      } catch (error: any) {
        showSnackbar(
          t("macro:adjustmentsPage.wizard.failedToUpdateAdjustment"),
          "error",
        );
        console.warn(
          `Error ${adjustmentId ? "updating" : "creating"} adjustment: `,
          error,
        );
      }
    },
    [
      getSaveFunction,
      getAdjustmentType,
      getPayload,
      adjustmentId,
      scenarioId,
      showSnackbar,
      t,
    ],
  );

  useEffect(() => {
    filtersDataIsPending ? showGlobalLoader(true) : showGlobalLoader(false);
  }, [filtersDataIsPending, showGlobalLoader]);

  // TODO UPDATE FOR MANUFACTURING
  const formikInitialValues: MacroAdjustmentWizardFormikValues = useMemo(() => {
    return {
      payload: {
        description: adjustmentDetails?.description || "",
        entities:
          adjustmentDetails?.entities.map((entity) => ({
            ...(entity.ingredient_id
              ? { ingredient_id: entity.ingredient_id as number }
              : entity.part_material_id
                ? {
                    part_material_id: entity.part_material_id as number,
                    ...(entity.country
                      ? { country: entity.country as string }
                      : {}),
                  }
                : entity.energy_type_id
                  ? {
                      energy_type_id: entity.energy_type_id as number,
                      ...(entity.country
                        ? { country: entity.country as string }
                        : {}),
                    }
                  : {
                      transport_mode_id: entity.transport_mode_id as number,
                      ...(entity.country
                        ? { country: entity.country as string }
                        : {}),
                    }),
            adjusted_value: entity.adjusted_value as number,
            action: (entity.action as UserDataTypes) ?? "percentage",
          })) || [],
        filters: {
          brand_groups:
            (adjustmentDetails?.filters.brand_groups as number[]) || [],
          brands: (adjustmentDetails?.filters.brands as number[]) || [],
          categories: (adjustmentDetails?.filters.categories as number[]) || [],
          countries: (adjustmentDetails?.filters.countries as string[]) || [],
          products: (adjustmentDetails?.filters.products as number[]) || [],
        },
        name: adjustmentDetails?.name || "",
      },
      selectedPillar:
        (adjustmentDetails?.pillar as MacroAdjustmentPillars) || null,
      selectedInputArea:
        (adjustmentDetails?.input_area as MacroAdjustmentInputAreas) || null,
      selectedAdjustmentType: adjustmentDetails?.adjustment_type
        ? (adjustmentDetails?.adjustment_type as MacroAdjustmentTypes)
        : MacroAdjustmentTypes.NotApplicable || null,
      selectedCountries:
        (adjustmentDetails?.filters.countries as string[])?.map(
          (country) =>
            scenarioDetails?.inputs?.countries?.find(
              (c) => c.country === country,
            ) as CountryViewModel,
        ) || [],
      selectedEntities:
        (adjustmentDetails?.entities.map((entity) => {
          switch (adjustmentDetails.pillar) {
            case MacroAdjustmentPillars.Packaging:
              return filtersData?.filters.part_materials.find(
                (part_material) => {
                  return part_material.id === entity.part_material_id;
                },
              );
            case MacroAdjustmentPillars.Manufacturing:
              return filtersData?.filters.energy_types.find((energy_type) => {
                return energy_type.id === entity.energy_type_id;
              });
            case MacroAdjustmentPillars.Logistics:
              return filtersData?.filters.transport_modes.find(
                (transport_mode) => {
                  return transport_mode.id === entity.transport_mode_id;
                },
              );
            default:
              return filtersData?.filters.ingredients.find((ingredient) => {
                return ingredient.id === entity.ingredient_id;
              });
          }
        }) as MacroFilter[]) || [],
      selectedAction:
        (adjustmentDetails?.entities?.[0]?.action as UserDataTypes) ||
        "percentage",
      selectedProducts:
        (adjustmentDetails?.filters.products as number[]) || ([] as number[]),
    };
  }, [
    adjustmentDetails,
    filtersData?.filters,
    scenarioDetails?.inputs?.countries,
  ]);

  const handleRightButtonClick = useCallback(
    async (
      formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
    ): Promise<void> => {
      if (activeStep < steps(formikProps).length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        await handleSaveAdjustment(formikProps.values);
      }
    },
    [activeStep, handleSaveAdjustment, steps],
  );

  useEffect(() => {
    if (adjustmentDetails) {
      updateAdjustmentCombination(
        adjustmentDetails.pillar as MacroAdjustmentPillars,
        adjustmentDetails.input_area as MacroAdjustmentInputAreas,
        adjustmentDetails?.adjustment_type
          ? (adjustmentDetails?.adjustment_type as MacroAdjustmentTypes)
          : MacroAdjustmentTypes.NotApplicable,
      );
    }
  }, [adjustmentDetails, updateAdjustmentCombination]);

  useEffect(() => {
    if (filtersData && scenarioDetails && adjustmentDetails) {
      setIsFormInitialized(true);
    }
  }, [adjustmentDetails, filtersData, scenarioDetails]);

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={macroAdjustmentValidationSchema}
      enableReinitialize={!isFormInitialized}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        return (
          <Form>
            <Box>
              <MacroAdjustmentsWizardHeaderAndStepper
                handleNavigateToAdjustmentsPage={
                  handleNavigateToAdjustmentsPage
                }
                formikProps={formikProps}
                headersIndent={headersIndent}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                steps={steps}
                adjustmentCombination={adjustmentCombination?.combo}
              ></MacroAdjustmentsWizardHeaderAndStepper>

              {/*IMPORTANT: This is the actual step component*/}
              <Box mb={4}>{steps(formikProps)[activeStep].component}</Box>

              <MacroAdjustmentsWizardFooter
                handleLeftButtonClick={handleLeftButtonClick}
                steps={steps}
                formikProps={formikProps}
                activeStep={activeStep}
                handleRightButtonClick={handleRightButtonClick}
                adjustmentCombination={adjustmentCombination?.combo || null}
                validationErrors={validationErrors}
              ></MacroAdjustmentsWizardFooter>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MacroAdjustmentWizard;
