import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import "./MicroViewAllScenariosPage.scss";
import ThreeDotMenu from "../../../../components/common/ThreeDotMenu/ThreeDotMenu";
import { useNavigate } from "react-router-dom";
import { CustomIcon, Icons, NoData, SearchBar } from "components/common";
import { SortConfig, SortValues } from "utils/SortConfig";
import Sort from "../../../../components/common/Sort/Sort";
import {
  ListMicroScenarioDto,
  ViewMicroScenarioDto,
} from "orval/generated/models";
import { AppConstant } from "constants/AppConstant";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useModal } from "components/common/Modal";
import UpdateDetailsModal from "../../../../components/common/UpdateDetailsModal/UpdateDetailsModal";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { AxiosError } from "axios";
import {
  getGetAllMicroScenariosScenariosMicroGetQueryKey,
  getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey,
  useCreateMicroScenarioScenariosMicroPost,
  useDeleteMicroScenarioByIdScenariosMicroScenarioIdDelete,
  useDuplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost,
  useGetAllMicroScenariosScenariosMicroGet,
  useUpdateMicroScenarioDetailsScenariosMicroScenarioIdPatch,
} from "orval/generated/endpoint";
import ConfirmationModal from "../../../../components/common/ConfirmationModal/ConfirmationModal";
import { STATUS_CODES } from "utils/HTTPUtils/constants";
import LoadingList from "components/common/LoadingList/LoadingList";
import AdjustmentCountChip from "features/micro-tool/components/MicroAdjustmentsSection/MicroCustomAccordion/AdjustmentCountChip/AdjustmentCountChip";

function MicroViewAllScenariosPage() {
  const { t } = useTranslation(["micro", "common"]);
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const [filteredScenarios, setFilteredScenarios] = useState<
    ListMicroScenarioDto[]
  >([]);
  const defaultSortValue =
    parseInt(
      localStorage.getItem(AppConstant.localStorageKeys.sortValue) || "0",
    ) || SortValues.DATE_UPDATED_DESCENDING;
  const [sortValue, setSortValue] = useState<SortValues>(defaultSortValue);
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();
  const { palette } = useTheme();

  const { data: microScenariosData, isPending: microScenarioDataIsPending } =
    useGetAllMicroScenariosScenariosMicroGet();

  useEffect(() => {
    if (!microScenarioDataIsPending) {
      const lowerCaseSearchString = searchString.toLowerCase();
      const _scenarios = microScenariosData || [];
      if (Array.isArray(_scenarios)) {
        const filtered = _scenarios.filter(
          (_scenario) =>
            _scenario.name.toLowerCase().includes(lowerCaseSearchString) ||
            (_scenario.description?.toLowerCase() ?? "").includes(
              lowerCaseSearchString,
            ),
        );

        const sortedData = [...filtered].sort((a, b) => {
          switch (sortValue as number) {
            case SortValues.DATE_UPDATED_ASCENDING:
              return (
                new Date(a.updated_at || "").getTime() -
                new Date(b.updated_at || "").getTime()
              );
            case SortValues.DATE_UPDATED_DESCENDING:
              return (
                new Date(b.updated_at || "").getTime() -
                new Date(a.updated_at || "").getTime()
              );
            case SortValues.NAME_ASCENDING:
              return a.name.localeCompare(b.name);
            case SortValues.NAME_DESCENDING:
              return b.name.localeCompare(a.name);
            default:
              return 0;
          }
        });
        setFilteredScenarios(sortedData);
      }
    }
  }, [searchString, microScenariosData, sortValue, microScenarioDataIsPending]);

  useEffect(() => {
    localStorage.setItem(
      AppConstant.localStorageKeys.sortValue,
      sortValue.toString(),
    );
  }, [sortValue]);

  function handleSearch(value: string): void {
    setSearchString(value);
  }

  function handleNavigateToScenario(scenario: ListMicroScenarioDto) {
    if (scenario && scenario.id) {
      navigate(`/product-level-analytics/scenario/${scenario?.id.toString()}`, {
        state: {
          id: scenario.id,
          title: scenario.name,
          description: scenario.description,
        },
      });
    }
  }

  const {
    mutateAsync: deleteMicroScenario,
    isPending: deleteMicroScenarioIsLoading,
  } = useDeleteMicroScenarioByIdScenariosMicroScenarioIdDelete({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getGetAllMicroScenariosScenariosMicroGetQueryKey(),
        });
        showSnackbar(
          t(
            "micro:microViewAllScenariosPage.deleteScenarioModal.scenarioDeleted",
          ),
          "success",
        );
        closeModal();
      },
      onError: (error: string | Error | string[] | AxiosError) => {
        showSnackbar(error, "error");
      },
    },
  });

  const onHandleDeleteScenario = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      const scenarioId = (event.target as Element).id.toString();
      let scenario: ListMicroScenarioDto | undefined;
      if (microScenariosData) {
        scenario = microScenariosData.find(
          (foundScenario: ListMicroScenarioDto) =>
            foundScenario.id && scenarioId === foundScenario.id.toString(),
        );
      }
      if (scenario && scenario.id) {
        openModal(
          <ConfirmationModal
            title={`${t("micro:microViewAllScenariosPage.deleteScenarioModal.title")} ${scenario?.name}${t(
              "micro:microViewAllScenariosPage.deleteScenarioModal.questionMark",
            )}`}
            description={t(
              "micro:microViewAllScenariosPage.deleteScenarioModal.description",
            )}
            actionTitle={t("common:actions.delete")}
            confirmAction={async () => {
              if (scenario?.id) {
                try {
                  await deleteMicroScenario({ scenarioId: scenario?.id }).then(
                    closeModal,
                  );
                } catch {
                  console.warn("Error deleting scenario");
                }
              }
            }}
            cancelAction={closeModal}
          />,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [microScenariosData],
  );

  const { mutateAsync: updateMicroScenarioDetails } =
    useUpdateMicroScenarioDetailsScenariosMicroScenarioIdPatch({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllMicroScenariosScenariosMicroGetQueryKey(),
          });
          showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
        },
        onError: (error: any) => {
          showSnackbar(t("errorMessages.errorUpdating"), "error");
          console.warn(error);
          return error;
        },
      },
    });

  const openHandleUpdateDetailsModal = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      const scenarioId = (event.target as Element).id.toString();
      let scenario: ListMicroScenarioDto | undefined;
      if (microScenariosData) {
        scenario = microScenariosData.find(
          (foundScenario: ListMicroScenarioDto) =>
            foundScenario.id && scenarioId === foundScenario.id.toString(),
        );
      }
      if (scenario) {
        openModal(
          <UpdateDetailsModal
            open={true}
            scenario={scenario}
            updateMicroScenarioDetails={updateMicroScenarioDetails}
          ></UpdateDetailsModal>,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [microScenariosData],
  );

  const { mutate: duplicateScenario, isPending: duplicateScenarioIsPending } =
    useDuplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllMicroScenariosScenariosMicroGetQueryKey(),
          });
          showSnackbar(t("micro:notifications.scenarioDuplicated"), "success");
        },
        onError: () => {
          showSnackbar(t("micro:errorMessages.errorDuplicating"), "error");
        },
      },
    });

  const onHandleDuplicateScenario = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      try {
        const scenarioId: number = parseInt((event.target as Element).id);
        duplicateScenario({ scenarioId });
      } catch {
        showSnackbar(t("micro:errorMessages.errorDuplicating"), "error");
      }
    },
    [duplicateScenario, showSnackbar, t],
  );

  const { mutate: createMicroScenario, isPending: createMicroIsPending } =
    useCreateMicroScenarioScenariosMicroPost({
      mutation: {
        onSuccess: (microScenario: ViewMicroScenarioDto) => {
          const queryKey =
            getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
              microScenario.id,
            );
          queryClient.setQueryData(queryKey, microScenario);

          navigate(`/product-level-analytics/scenario/${microScenario.id}`, {
            state: { firstVisit: true },
          });
          queryClient.setQueryData(
            getGetAllMicroScenariosScenariosMicroGetQueryKey(),
            undefined,
          );
        },
        onError: (createMicroError: AxiosError) => {
          if (createMicroError.status !== STATUS_CODES.UNAVAILABLE)
            showSnackbar("micro:errorMessages.createScenario", "error");
        },
      },
    });

  return (
    <Box
      sx={{
        position: "sticky",
        top: 64,
        backgroundColor: "white",
        zIndex: 1,
        minHeight: "100%",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }} mb={2}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography className={"header-H2"} fontWeight="500" mb={2}>
            {t("micro:microViewAllScenariosPage.title")}
          </Typography>

          <Typography className="header-H3 text-ellipsis">
            {t("micro:microViewAllScenariosPage.subtitle")}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              createMicroScenario();
            }}
            disabled={createMicroIsPending}
          >
            {t("micro:microViewAllScenariosPage.labels.createNewScenarioBtn")}
            {createMicroIsPending ? (
              <CircularProgress size={24} sx={{ color: "white", ml: 1 }} />
            ) : null}
          </Button>
        </Box>
      </Box>

      <Box mb={2}>
        <Typography>{t("description")}</Typography>
      </Box>

      {microScenariosData && microScenariosData?.length > 0 ? (
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <SearchBar handleSearch={handleSearch} />
          <Sort
            sortValue={sortValue}
            setSortValue={setSortValue}
            values={SortConfig}
          />
        </Box>
      ) : null}

      <Box mb={4}>
        {microScenarioDataIsPending ||
        duplicateScenarioIsPending ||
        deleteMicroScenarioIsLoading ? (
          <LoadingList />
        ) : !microScenariosData?.length ? (
          <Box mt={2}>
            <NoData
              label={t("common:noData.emptyStateMessage1")}
              label2={t("common:noData.emptyStateMessage2")}
            />
          </Box>
        ) : !filteredScenarios.length ? (
          <NoData label={t("common:noData.noSearchMatchMessage")} />
        ) : (
          filteredScenarios.map((scenario: ListMicroScenarioDto) => (
            <Box
              className="scenario-tile"
              key={scenario.id}
              sx={{ display: "flex", justifyContent: "space-between" }}
              mb={2}
            >
              <Grid width="100%">
                <Box display={"flex"} alignItems={"center"}>
                  <Typography
                    className="header-H3 scenario-title"
                    onClick={() => {
                      handleNavigateToScenario(scenario);
                    }}
                    onKeyDown={(e) => {
                      if (
                        (e.ctrlKey && e.altKey && e.code === "Space") ||
                        e.code === "Enter"
                      ) {
                        handleNavigateToScenario(scenario);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {scenario.name}
                  </Typography>
                  <Box
                    tabIndex={0}
                    marginLeft="auto"
                    display="flex"
                    alignItems="center"
                  >
                    {scenario.adjustment_count > 0 ? (
                      <>
                        <AdjustmentCountChip scenario={scenario} />
                        <FiberManualRecordIcon
                          sx={{
                            m: 1,
                            fontSize: "5px",
                            color: "black",
                          }}
                        />
                      </>
                    ) : null}
                    <Typography>
                      {scenario.product_count === 0
                        ? "No"
                        : scenario.product_count}{" "}
                      {t("common:products")}
                    </Typography>
                    <FiberManualRecordIcon
                      sx={{
                        m: 1,
                        fontSize: "5px",
                        color: "black",
                      }}
                    />
                    <Tooltip title={t("common:labels.updatedDate")}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box mr={0.5}>
                          <CustomIcon
                            name={Icons.UPDATE}
                            width={24}
                            height={24}
                            fill={palette.text.primary}
                          />
                        </Box>
                        <Typography>
                          {new Date(scenario.updated_at || "").toLocaleString(
                            undefined,
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false,
                            },
                          )}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                  <ThreeDotMenu
                    menuItems={[
                      {
                        label: t(
                          "microViewAllScenariosPage.labels.updateDetailsBtn",
                        ),
                        onClick: (
                          event: Event | React.SyntheticEvent<Element, Event>,
                        ) => openHandleUpdateDetailsModal(event),
                        id: scenario.id,
                      },
                      {
                        label: t("common:actions.duplicate"),
                        onClick: (
                          event: Event | React.SyntheticEvent<Element, Event>,
                        ) => onHandleDuplicateScenario(event),
                        isAsync: true,
                        id: scenario.id,
                      },
                      {
                        label: t("common:actions.delete"),
                        onClick: (
                          event: Event | React.SyntheticEvent<Element, Event>,
                        ) => onHandleDeleteScenario(event),
                        id: scenario.id,
                      },
                    ]}
                  ></ThreeDotMenu>
                </Box>
                {scenario.description && (
                  <Box mt={2} pr={2} overflow="hidden">
                    <Typography tabIndex={0}>{scenario.description}</Typography>
                  </Box>
                )}
              </Grid>
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
}

export default MicroViewAllScenariosPage;
