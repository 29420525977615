import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import BannerAlert from "../../../../../components/common/BannerAlert/BannerAlert";
import { MetadataActiveAlert } from "orval/generated/models";
import { MouseEvent } from "react";
import { CustomIcon, Icons } from "components/common";
import { AlignmentEnum } from "features/tool-configuration/components/BannerAlertConfigurationPage/types";

export type EnableBannerAlertModalProps = {
  closeModal: () => void;
  handleTurnBannerOn: Function;
  previewBanner: MetadataActiveAlert;
  handleAlignment: Function;
};

function EnableBannerAlertModal({
  closeModal,
  handleTurnBannerOn,
  previewBanner,
  handleAlignment,
}: EnableBannerAlertModalProps) {
  const { t } = useTranslation(["toolConfiguration", "common"]);
  const { palette } = useTheme();

  return (
    <Box maxWidth={500}>
      <Box marginRight={1} marginTop={1} display="flex" justifyContent="right">
        <IconButton
          aria-label="delete"
          sx={{ aspectRatio: 1, float: "right" }}
          onClick={() => {
            handleAlignment(AlignmentEnum.bannerOff);
            closeModal();
          }}
        >
          <CustomIcon
            name={Icons.CLOSE}
            width={24}
            height={24}
            fill={palette.text.primary}
          />
        </IconButton>
      </Box>
      <Box paddingX={5} paddingBottom={4}>
        <Typography textAlign="center" variant="h4" marginBottom={3}>
          {t("alertConfigurationPage.enableAlertModal.title")}
        </Typography>
        <Typography textAlign="center" variant="body2" marginBottom={3}>
          {t("alertConfigurationPage.enableAlertModal.description")}
        </Typography>
        <Box mb={3}>
          <BannerAlert preview={true} activeAlert={previewBanner} />
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            onClick={() => {
              handleAlignment(AlignmentEnum.bannerOff);
              closeModal();
            }}
            data-testid="cancel-button"
          >
            {t("common:actions.cancel")}
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={() => {
              handleTurnBannerOn();
            }}
            data-testid="enable-button"
          >
            {t("common:actions.enable")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default EnableBannerAlertModal;
