import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface MekkoPillarCustomLegendProps {
  pillars: string[];
  chartBaseRgbColours: { r: number; g: number; b: number }[];
}

export const MekkoPillarCustomLegend = ({
  pillars,
  chartBaseRgbColours,
}: MekkoPillarCustomLegendProps) => {
  const { t } = useTranslation("macro");

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      data-testid="mekko-pillar-custom-legend"
      mb={-2}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: "12px" }}>
            {t("mekkoAnalysis.pillarLegend")}
          </Typography>
        </Box>
        {pillars.map((pillar, index) => {
          const baseColour =
            chartBaseRgbColours[index % chartBaseRgbColours.length];
          const color = `rgba(${baseColour.r}, ${baseColour.g}, ${baseColour.b}, 1)`;
          return (
            <Box
              key={index}
              p={1}
              display={"flex"}
              alignItems={"center"}
              sx={{
                borderRadius: 1,
                flexShrink: 0,
                fontFamily: "sans-serif",
                fontSize: "12px",
                fill: "rgb(51, 51, 51)",
              }}
            >
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: color,
                }}
                mr={1}
                data-testid={`pillar-legend-colour-${index}`}
              ></Box>
              <span>
                {pillar === "cde"
                  ? pillar.toUpperCase()
                  : pillar.charAt(0).toUpperCase() + pillar.slice(1)}
              </span>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default MekkoPillarCustomLegend;
