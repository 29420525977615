import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import React, {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MRT_ColumnDef } from "material-react-table";
import { useFormikContext } from "formik";
import { AppConstant } from "constants/AppConstant";
import {
  MacroAdjustmentCombinations,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";
import {
  CountryAverageDistanceTravelled,
  CountryAverageManufacturingEur,
  CountryPackagingAverageRecoveryRate,
  EntityWeightAggregate,
  IngredientEntity,
  LogisticsEntityWithCountryDto,
  ManufacturingEntityWithCountryDto,
  PackagingEntity,
  ViewMacroScenarioDto,
  WeightedAverageNetLossRateAggregate,
  WeightedAverageRecycledContentAggregate,
} from "orval/generated/models";
import {
  formatNumberButNotIncreaseDecimalPlaces,
  getDecimalPlacesForDataStructureKey,
} from "utils/formatNumber/formatNumber";
import { Loader } from "components/common";
import { CustomSelect } from "components/common/CustomSelect";
import {
  useGetAggregatedDistanceTravelledScenariosMacroAdjustmentsAggregatedLogisticsDistanceTravelledPost,
  useGetAggregatedIngredientsWeightScenariosMacroAdjustmentsAggregatedIngredientsWeightPost,
  useGetAggregatedManufacturingEurScenariosMacroAdjustmentsAggregatedManufacturingEurPost,
  useGetAggregatedPackagingWeightedAvgBreakageRateScenariosMacroAdjustmentsAggregatedPackagingNetLossRatePost,
  useGetAggregatedPackagingWeightedAvgRecoveryRateScenariosMacroAdjustmentsAggregatedPackagingRecoveryRatePost,
  useGetAggregatedPackagingWeightedAvgRecycledContentScenariosMacroAdjustmentsAggregatedPackagingRecycledContentPost,
  useGetAggregatedPackagingWeightScenariosMacroAdjustmentsAggregatedPackagingWeightPost,
} from "orval/generated/endpoint";
import MacroAdjustmentStepThreeBasicTable, {
  TableEntityType,
} from "./MacroAdjustmentStepThreeBasicTable";
import MacroAdjustmentStepThreeAccordionArray from "./MacroAdjustmentStepThreeAccordionArray";

export type MacroAdjustmentStepThreeProps = {
  validationErrors: { [key: string]: string | undefined };
  setValidationErrors: (
    errors: (prev: { [key: string]: string | undefined }) => {
      [key: string]: string | undefined;
    },
  ) => void;
  scenario: ViewMacroScenarioDto | undefined;
  adjustmentCombination: MacroAdjustmentCombinations | undefined;
};

export const MacroAdjustmentStepThree = ({
  validationErrors,
  setValidationErrors,
  scenario,
  adjustmentCombination,
}: MacroAdjustmentStepThreeProps) => {
  const { t } = useTranslation(["macro", "common"]);
  const { palette } = useTheme();
  const { setFieldValue, values } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  const [columnVisibility, setColumnVisibility] = useState({
    baseYear: false,
    targetYear: false,
  });

  useEffect(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        setColumnVisibility({ baseYear: true, targetYear: true });
        break;
      default:
        break;
    }
  }, [adjustmentCombination]);

  const [aggregatedWeights, setAggregatedWeights] = useState<
    | WeightedAverageRecycledContentAggregate
    | WeightedAverageNetLossRateAggregate
    | EntityWeightAggregate
    | null
  >(null);

  const [aggregatedWeightsForCountries, setAggregatedWeightsForCountries] =
    useState<
      | CountryPackagingAverageRecoveryRate[]
      | CountryAverageManufacturingEur[]
      | CountryAverageDistanceTravelled[]
      | null
    >(null);

  const { mutateAsync: getAggregatedIngredientsWeights } =
    useGetAggregatedIngredientsWeightScenariosMacroAdjustmentsAggregatedIngredientsWeightPost();

  const { mutateAsync: getAggregatedPackagingWeights } =
    useGetAggregatedPackagingWeightScenariosMacroAdjustmentsAggregatedPackagingWeightPost();

  const { mutateAsync: getAggregatedRecycledContentWeights } =
    useGetAggregatedPackagingWeightedAvgRecycledContentScenariosMacroAdjustmentsAggregatedPackagingRecycledContentPost();

  const { mutateAsync: getAggregatedNetLossWeights } =
    useGetAggregatedPackagingWeightedAvgBreakageRateScenariosMacroAdjustmentsAggregatedPackagingNetLossRatePost();

  const { mutateAsync: getAggregatedRecoveryRateWeights } =
    useGetAggregatedPackagingWeightedAvgRecoveryRateScenariosMacroAdjustmentsAggregatedPackagingRecoveryRatePost();

  const { mutateAsync: getAggregatedManufacturingEur } =
    useGetAggregatedManufacturingEurScenariosMacroAdjustmentsAggregatedManufacturingEurPost();

  const { mutateAsync: getAggregatedLogisticsDistanceTravelledData } =
    useGetAggregatedDistanceTravelledScenariosMacroAdjustmentsAggregatedLogisticsDistanceTravelledPost();

  useEffect(() => {
    const fetchAggregatedWeights = async () => {
      try {
        let data;
        if (
          adjustmentCombination ===
          MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment
        ) {
          data = await getAggregatedIngredientsWeights({
            data: {
              base_year: scenario?.inputs?.years?.base_year ?? 0,
              target_year: scenario?.inputs?.years?.target_year ?? 0,
              filters: {
                country: values.payload.filters.countries[0] as string,
                brand: values.payload.filters.brands[0] as number,
              },
              entities: values.selectedEntities.map((entity) => entity.id),
            },
          });
          setAggregatedWeights(data);
        } else if (values.selectedProducts?.length) {
          const payload = {
            products: values.selectedProducts,
            entities: values.selectedEntities.map((entity) => entity.id),
            base_year: scenario?.inputs?.years?.base_year ?? 0,
            target_year: scenario?.inputs?.years?.target_year ?? 0,
          };

          switch (adjustmentCombination) {
            case MacroAdjustmentCombinations.Packaging_RecycledContent:
              data = await getAggregatedRecycledContentWeights({
                data: payload,
              });
              setAggregatedWeights(data);
              break;
            case MacroAdjustmentCombinations.Packaging_NetLossRate:
              data = await getAggregatedNetLossWeights({ data: payload });
              setAggregatedWeights(data);
              break;
            case MacroAdjustmentCombinations.Packaging_RecoveryRate:
              data = await getAggregatedRecoveryRateWeights({ data: payload });
              setAggregatedWeightsForCountries(data);
              break;
            case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
              data = await getAggregatedManufacturingEur({ data: payload });
              setAggregatedWeightsForCountries(data);
              break;
            case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
              data = await getAggregatedLogisticsDistanceTravelledData({
                data: payload,
              });
              setAggregatedWeightsForCountries(data);
              break;
            default:
              data = await getAggregatedPackagingWeights({
                data: { ...payload, product: values.selectedProducts[0] },
              });
              setAggregatedWeights(data);
              break;
          }
        }
      } catch (e) {
        console.warn(e);
      }
    };

    fetchAggregatedWeights();
  }, [
    adjustmentCombination,
    getAggregatedIngredientsWeights,
    getAggregatedLogisticsDistanceTravelledData,
    getAggregatedManufacturingEur,
    getAggregatedNetLossWeights,
    getAggregatedPackagingWeights,
    getAggregatedRecoveryRateWeights,
    getAggregatedRecycledContentWeights,
    scenario,
    values.payload.filters,
    values.selectedEntities,
    values.selectedProducts,
  ]);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, row: any) => {
      const updatedEntity = { ...row.original };

      const newValue = e.target.value === "" ? null : Number(e.target.value);
      switch (adjustmentCombination) {
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment: {
          const payloadIngredientEntities: IngredientEntity[] = values.payload
            .entities as IngredientEntity[];
          setFieldValue(
            "payload.entities",
            payloadIngredientEntities.map((entity: IngredientEntity) => {
              if (entity.ingredient_id === updatedEntity.id) {
                return {
                  ...entity,
                  ingredient_id: updatedEntity.id,
                  adjusted_value: newValue,
                } as IngredientEntity;
              }
              return entity;
            }),
          ).catch((e) => console.warn(e));
          break;
        }
        case MacroAdjustmentCombinations.Packaging_RecoveryRate: {
          const payloadPackagingEntities: PackagingEntity[] = values.payload
            .entities as PackagingEntity[];
          const updatedEntities = payloadPackagingEntities.map(
            (entity: PackagingEntity) => {
              if (
                entity.part_material_id === updatedEntity.id &&
                entity.country === updatedEntity.country
              ) {
                return {
                  ...entity,
                  part_material_id: updatedEntity.id,
                  adjusted_value: newValue,
                } as PackagingEntity;
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.part_material_id === updatedEntity.id &&
                e.country === updatedEntity.country,
            )
          )
            updatedEntities.push({
              part_material_id: updatedEntity.id,
              adjusted_value: newValue as number,
              action: "new_value",
              country: updatedEntity.country,
            });

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
        case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur: {
          const payloadEntities: ManufacturingEntityWithCountryDto[] = values
            .payload.entities as ManufacturingEntityWithCountryDto[];
          const updatedEntities = payloadEntities.map(
            (entity: ManufacturingEntityWithCountryDto) => {
              if (
                entity.energy_type_id === updatedEntity.id &&
                entity.country === updatedEntity.country
              ) {
                return {
                  ...entity,
                  energy_type_id: updatedEntity.id,
                  adjusted_value: newValue,
                } as ManufacturingEntityWithCountryDto;
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.energy_type_id === updatedEntity.id &&
                e.country === updatedEntity.country,
            )
          )
            updatedEntities.push({
              energy_type_id: updatedEntity.id,
              adjusted_value: newValue as number,
              country: updatedEntity.country,
            });

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
        case MacroAdjustmentCombinations.Logistics_DistanceTravelled: {
          const payloadEntities: LogisticsEntityWithCountryDto[] = values
            .payload.entities as LogisticsEntityWithCountryDto[];
          const updatedEntities = payloadEntities.map(
            (entity: LogisticsEntityWithCountryDto) => {
              if (
                entity.transport_mode_id === updatedEntity.id &&
                entity.country === updatedEntity.country
              ) {
                return {
                  ...entity,
                  transport_mode_id: updatedEntity.id,
                  adjusted_value: newValue,
                } as LogisticsEntityWithCountryDto;
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.transport_mode_id === updatedEntity.id &&
                e.country === updatedEntity.country,
            )
          )
            updatedEntities.push({
              transport_mode_id: updatedEntity.id,
              adjusted_value: newValue as number,
              country: updatedEntity.country,
            });

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
        default: {
          const payloadPackagingEntities: PackagingEntity[] = values.payload
            .entities as PackagingEntity[];
          const updatedEntities = payloadPackagingEntities.map(
            (entity: PackagingEntity) => {
              if (entity.part_material_id === updatedEntity.id) {
                return {
                  ...entity,
                  part_material_id: updatedEntity.id,
                  adjusted_value: newValue,
                } as PackagingEntity;
              }
              return entity;
            },
          );
          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
      }
    },
    [adjustmentCombination, setFieldValue, values.payload.entities],
  );

  const entityColumnHeader = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return t("adjustmentsPage.wizard.stepThree.ingredient");
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return t("adjustmentsPage.wizard.stepThree.packagingMaterial");
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return t("adjustmentsPage.wizard.stepThree.energyType");
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return t("adjustmentsPage.wizard.stepThree.transportMode");
      default:
        return "";
    }
  }, [adjustmentCombination, t]);

  const baseYearColumnHeader = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return `${scenario?.inputs?.years?.base_year} ${t("adjustmentsPage.wizard.stepThree.ingredientsBaseYear")}`;
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return `${scenario?.inputs?.years?.base_year} ${t("adjustmentsPage.wizard.stepThree.packagingBaseYear")}`;
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
        return `${scenario?.inputs?.years?.base_year} ${t("adjustmentsPage.wizard.stepThree.recycledContent")} (%)`;
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return `${scenario?.inputs?.years?.base_year} ${t("adjustmentsPage.wizard.stepThree.recoveryRate")} (%)`;
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return `${scenario?.inputs?.years?.base_year} ${t("adjustmentsPage.wizard.stepThree.netLossRate")} (%)`;
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return `${scenario?.inputs?.years?.base_year} ${t("adjustmentsPage.wizard.stepThree.energyUsageRatio")}`;
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return `${scenario?.inputs?.years?.base_year} ${t("adjustmentsPage.wizard.stepThree.distanceTravelled")}`;
      default:
        return "";
    }
  }, [adjustmentCombination, scenario?.inputs?.years?.base_year, t]);

  const targetYearColumnHeader = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return `${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.ingredientsTargetYear")}`;
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return `${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.packagingTargetYear")}`;
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
        return `${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.recycledContent")} (%)`;
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return `${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.recoveryRate")} (%)`;
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return `${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.netLossRate")} (%)`;
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return `${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.energyUsageRatio")}`;
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return `${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.distanceTravelled")}`;
      default:
        return "";
    }
  }, [adjustmentCombination, scenario?.inputs?.years?.target_year, t]);

  const descriptionKey = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
        return "multiSKUDescription";
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return "specificSKUDescription";
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        return "specificBrandDescription";
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return "multiBrandDescription";
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
        return "recycledContentDescription";
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return "recoveryRateDescription";
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return "netLossRateDescription";
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return "manufacturingEURDescription";
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return "logisticsDistanceTravelledDescription";
      default:
        return "";
    }
  }, [adjustmentCombination]);

  const adjustedValueColumnHeader = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return values.selectedAction === "percentage"
          ? t(
              "adjustmentsPage.wizard.stepThree.adjustedValueHeaderPercentagePackaging",
            )
          : t(
              "adjustmentsPage.wizard.stepThree.adjustedValueHeaderNewValuePackaging",
            );
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
        return t(
          "adjustmentsPage.wizard.stepThree.adjustedValueHeaderPercentagePackaging",
        );
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
        return `${t("adjustmentsPage.wizard.stepThree.override")} ${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.recycledContent")} (%)`;
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return `${t("adjustmentsPage.wizard.stepThree.override")} ${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.recoveryRate")} (%)`;
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return `${t("adjustmentsPage.wizard.stepThree.override")} ${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.netLossRate")} (%)`;
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return `${t("adjustmentsPage.wizard.stepThree.adjust")} ${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.energyUsageRatio")}`;
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        return `${t("adjustmentsPage.wizard.stepThree.adjust")} ${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.ingredientWeight")}`;
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return `${t("adjustmentsPage.wizard.stepThree.adjust")} ${scenario?.inputs?.years?.target_year} ${t("adjustmentsPage.wizard.stepThree.distanceTravelled")}`;
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: palette.primary.main,
                fontWeight: 600,
                fontSize: "0.875rem",
              }}
            >
              {t(
                "adjustmentsPage.wizard.stepThree.adjustedValueHeaderPercentageIngredients",
              )}
            </Typography>
            <Typography sx={{ color: "#6A6A6A", fontSize: "0.875rem" }}>
              ({t("adjustmentsPage.wizard.stepThree.vs")}{" "}
              {scenario?.inputs?.years?.target_year}{" "}
              {t("adjustmentsPage.wizard.stepThree.glidepath")})
            </Typography>
          </Box>
        );
      default:
        return t("adjustmentsPage.wizard.stepThree.adjustedValue");
    }
  }, [
    adjustmentCombination,
    values.selectedAction,
    scenario?.inputs?.years?.target_year,
    t,
    palette.primary.main,
  ]);

  const tableData = useMemo(() => {
    return values.selectedEntities.map((entity) => {
      let existingEntity:
        | IngredientEntity
        | PackagingEntity
        | ManufacturingEntityWithCountryDto
        | null = null;
      switch (adjustmentCombination) {
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
          existingEntity = values.payload.entities.find(
            (adjustedEntity: any) =>
              (adjustedEntity as IngredientEntity).ingredient_id === entity.id,
          ) as IngredientEntity;
          break;
        case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
        case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        case MacroAdjustmentCombinations.Packaging_RecycledContent:
        case MacroAdjustmentCombinations.Packaging_NetLossRate:
          existingEntity = values.payload.entities.find(
            (adjustedEntity: any) =>
              (adjustedEntity as PackagingEntity).part_material_id ===
              entity.id,
          ) as PackagingEntity;
          break;
        case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
          existingEntity = values.payload.entities.find(
            (adjustedEntity: any) =>
              adjustedEntity.energy_type_id === entity.id,
          ) as ManufacturingEntityWithCountryDto;
          break;
        default:
          break;
      }

      let tableRow: TableEntityType;
      tableRow = {
        id: entity.id,
        adjusted_value: existingEntity ? existingEntity.adjusted_value : null,
        action:
          existingEntity && "action" in existingEntity
            ? existingEntity.action
            : null,
        baseYear: (() => {
          switch (adjustmentCombination) {
            case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
              return (
                aggregatedWeights as EntityWeightAggregate
              )?.base_year.find((item: any) => item.id === entity.id)?.weight;
            case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
              return (
                aggregatedWeights as EntityWeightAggregate
              )?.base_year.find((item: any) => item.id === entity.id)?.weight;
            case MacroAdjustmentCombinations.Packaging_RecycledContent:
              return (
                aggregatedWeights as WeightedAverageRecycledContentAggregate
              )?.base_year.find((item: any) => item.id === entity.id)
                ?.avg_recycled_content;
            case MacroAdjustmentCombinations.Packaging_NetLossRate:
              return (
                aggregatedWeights as WeightedAverageNetLossRateAggregate
              )?.base_year.find((item: any) => item.id === entity.id)
                ?.avg_net_loss_rate;
            default:
              return undefined;
          }
        })(),
        targetYear: (() => {
          switch (adjustmentCombination) {
            case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
              return (
                aggregatedWeights as EntityWeightAggregate
              )?.target_year.find((item: any) => item.id === entity.id)?.weight;
            case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
              return (
                aggregatedWeights as EntityWeightAggregate
              )?.target_year.find((item: any) => item.id === entity.id)?.weight;
            case MacroAdjustmentCombinations.Packaging_RecycledContent:
              return (
                aggregatedWeights as WeightedAverageRecycledContentAggregate
              )?.target_year.find((item: any) => item.id === entity.id)
                ?.avg_recycled_content;
            case MacroAdjustmentCombinations.Packaging_NetLossRate:
              return (
                aggregatedWeights as WeightedAverageNetLossRateAggregate
              )?.target_year.find((item: any) => item.id === entity.id)
                ?.avg_net_loss_rate;
            default:
              return undefined;
          }
        })(),
      };
      return tableRow;
    });
  }, [
    adjustmentCombination,
    aggregatedWeights,
    values.payload.entities,
    values.selectedEntities,
  ]);

  const getRegex = (decimalPlaces: number) => {
    return new RegExp(`^-?\\d*\\.?\\d{0,${decimalPlaces}}$`);
  };

  const columns = useMemo<MRT_ColumnDef<TableEntityType>[]>(
    () => [
      {
        accessorKey: "id",
        header: entityColumnHeader,
        enableEditing: false,
        Cell: ({ row }: { row: any }) => {
          return values.selectedEntities.find(
            (entity: any) => entity.id === row.original.id,
          )?.value;
        },
      },
      {
        accessorKey: "baseYear",
        header: baseYearColumnHeader,
        enableEditing: false,
        Cell: ({ row }) => {
          const baseYear = row.original.baseYear;
          if (baseYear == null) {
            return AppConstant.emptyCell;
          }
          return formatNumberButNotIncreaseDecimalPlaces(
            baseYear,
            adjustmentCombination as string,
          );
        },
      },
      {
        accessorKey: "targetYear",
        header: targetYearColumnHeader,
        enableEditing: false,
        Cell: ({ row }) => {
          const targetYear = row.original.targetYear;
          if (targetYear == null) {
            return AppConstant.emptyCell;
          }
          return formatNumberButNotIncreaseDecimalPlaces(
            targetYear,
            adjustmentCombination as string,
          );
        },
      },
      {
        accessorKey: "adjusted_value",
        Header: adjustedValueColumnHeader,
        header: t(
          "adjustmentsPage.wizard.stepThree.adjustedValueHeaderPercentagePackaging",
        ),
        enableColumnActions: false,
        muiEditTextFieldProps: ({ row }: { row: any }) => ({
          type: "number",
          required: true,
          placeholder: AppConstant.emptyCell,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            handleOnChange(e, row);
          },
          error:
            !!validationErrors?.[
              `${row.original.id}${adjustmentCombination === MacroAdjustmentCombinations.Packaging_RecoveryRate ? row.original.country : ""}` as string
            ],
          helperText:
            validationErrors?.[
              `${row.original.id}${adjustmentCombination === MacroAdjustmentCombinations.Packaging_RecoveryRate ? row.original.country : ""}` as string
            ],
          onBlur: (event: FocusEvent<HTMLInputElement>) => {
            let validationError: string | undefined = undefined;
            const value = Number(event.currentTarget.value) || 0;
            if (values.selectedAction === "percentage") {
              if (value < -100) {
                validationError = t(
                  "common:validation.greaterThanOrEqualToMinusOneHundred",
                );
              }
            } else if (
              adjustmentCombination ===
                MacroAdjustmentCombinations.Packaging_RecoveryRate &&
              (value < 0 || value > 100)
            ) {
              validationError = t(
                "common:validation.greaterThanOrEqualZeroAndLessThanOrEqualHundred",
              );
              // } else if (value < 0) {
              //   validationError = t("common:validation.greaterThanZero");
            } else {
              validationError = undefined;
            }

            setValidationErrors((prev: Record<number, string | undefined>) => ({
              ...prev,
              [`${row.original.id}${adjustmentCombination === MacroAdjustmentCombinations.Packaging_RecoveryRate ? row.original.country : ""}`]:
                validationError,
            }));
          },
          onInput: (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            const decimalPlaces = getDecimalPlacesForDataStructureKey(
              adjustmentCombination as string,
            );
            const regex = getRegex(decimalPlaces);
            if (decimalPlaces === 0 && value.includes(".")) {
              event.target.value = value.replace(".", "");
            } else if (!regex.test(value)) {
              event.target.value = value.slice(0, -1);
            }
          },
          onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
            const paste = event.clipboardData.getData("text");
            const decimalPlaces = getDecimalPlacesForDataStructureKey(
              adjustmentCombination as string,
            );
            const regex = getRegex(decimalPlaces);
            if (!regex.test(paste)) {
              event.preventDefault();
            }
          },
        }),
        muiTableHeadCellProps: () => ({
          sx: {
            color: palette.primary.main,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          },
        }),
      },
    ],
    [
      entityColumnHeader,
      baseYearColumnHeader,
      targetYearColumnHeader,
      adjustedValueColumnHeader,
      t,
      values.selectedEntities,
      values.selectedAction,
      validationErrors,
      handleOnChange,
      adjustmentCombination,
      setValidationErrors,
      palette.primary.main,
    ],
  );

  const renderAdjustmentComponent = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return (
          <MacroAdjustmentStepThreeAccordionArray
            aggregatedData={aggregatedWeightsForCountries}
            columns={columns}
            adjustmentCombination={adjustmentCombination}
          />
        );
      default:
        return (
          <MacroAdjustmentStepThreeBasicTable
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            columns={columns}
            tableData={tableData}
          />
        );
    }
  }, [
    adjustmentCombination,
    aggregatedWeightsForCountries,
    columns,
    columnVisibility,
    tableData,
  ]);
  const isLoading: boolean = useMemo(() => {
    return (
      ([
        MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment,
        MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment,
        MacroAdjustmentCombinations.Packaging_RecycledContent,
        MacroAdjustmentCombinations.Packaging_NetLossRate,
      ].includes(adjustmentCombination as MacroAdjustmentCombinations) &&
        !aggregatedWeights) ||
      ([
        MacroAdjustmentCombinations.Packaging_RecoveryRate,
        MacroAdjustmentCombinations.Manufacturing_ManufacturingEur,
        MacroAdjustmentCombinations.Logistics_DistanceTravelled,
      ].includes(adjustmentCombination as MacroAdjustmentCombinations) &&
        !aggregatedWeightsForCountries)
    );
  }, [adjustmentCombination, aggregatedWeights, aggregatedWeightsForCountries]);

  return (
    <Box>
      <Box>
        <Box mb={1}>
          <Typography className="header-H2 text-ellipsis">
            {t("adjustmentsPage.wizard.stepThree.title")}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            {t(`adjustmentsPage.wizard.stepThree.${descriptionKey}`)}
          </Typography>
        </Box>
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          {adjustmentCombination ===
            MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment ||
          adjustmentCombination ===
            MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment ||
          adjustmentCombination ===
            MacroAdjustmentCombinations.Manufacturing_ManufacturingEur ||
          adjustmentCombination ===
            MacroAdjustmentCombinations.Logistics_DistanceTravelled ? (
            <Box my={1} display="flex" justifyContent="flex-end">
              <CustomSelect
                list={["percentage", "new_value"]}
                label={""}
                handlerFunction={(value) =>
                  setFieldValue(
                    "selectedAction",
                    value as "percentage" | "new_value",
                  )
                }
                source={"macro"}
                sourceKey={"macro:adjustmentsPage.wizard.stepThree.action"}
                initialValue={values.selectedAction ?? "new_value"}
                width={"200px"}
              />
            </Box>
          ) : null}
          {renderAdjustmentComponent}
        </Box>
      )}
    </Box>
  );
};
