import { ProductsEditableColumnConfigItem } from "../MicroAdjustmentsTable/types";
import { TITLE_COLUMN_WIDTH } from "../../constants";
import { MRT_ColumnDef } from "material-react-table";
import { Box, Tooltip } from "@mui/material";
import { MicroColumnWithUnits } from "../MicroColumnWithUnits";
import { MicroColumnWithDropDown } from "../MicroColumnWithDropDown";
import { ProductColumnData } from "../../types";
import { appTheme } from "core/Theme/theme";
import getTooltipTitle from "./getTooltipTitle";
import { CustomIcon, Icons } from "components/common";

const palette = appTheme.palette;

function getTableColumns(
  products: Array<any>,
  metrics?: ProductsEditableColumnConfigItem[],
  size: number = TITLE_COLUMN_WIDTH,
  showToolTip?: boolean,
  showDropDown?: boolean,
  isEditing?: boolean,
): MRT_ColumnDef<any>[] {
  const tableColumns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "metric", //access nested data with dot notation
      header: "Metric",
      size: size,
      id: "metric",
      enableEditing: false,
      grow: false,
      Cell: ({ renderedCellValue, row }) => {
        return (
          <>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"space-between"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <MicroColumnWithUnits renderedCellValue={renderedCellValue} />

                {showToolTip ? (
                  <Box ml={1} display={"flex"} alignItems={"center"}>
                    <Tooltip
                      title={getTooltipTitle(
                        row.original.dataStructureKey,
                        renderedCellValue as string,
                      )}
                      arrow
                      placement="top"
                    >
                      <Box display={"flex"} justifyContent={"center"}>
                        <CustomIcon
                          name={Icons.INFORMATION}
                          width={18}
                          height={18}
                          fill={palette.primary.main}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                ) : null}
                {showDropDown &&
                row?.original?.id !== "new_serving_size" &&
                !row.originalSubRows ? (
                  <Box>
                    <MicroColumnWithDropDown row={row} isEditing={isEditing} />
                  </Box>
                ) : null}
              </Box>
            </Box>
          </>
        );
      },
    },
  ];

  products.forEach((product: ProductColumnData) => {
    tableColumns.push({
      accessorKey: `Product ${product.guid}`,
      header: product.customProductName
        ? product.customProductName
        : product.name,
      id: `Product ${product.guid}`,
      meta: { guid: product.guid, hasAdjustments: product.hasAdjustments },
    });
  });

  return tableColumns;
}

export default getTableColumns;
