import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SelectDropdown from "components/common/SelectDropdown/SelectDropdown";
import { useFormikContext } from "formik";
import { MacroFiltersAndFilteredRTDL } from "orval/generated/models/macroFiltersAndFilteredRTDL";
import { useTranslation } from "react-i18next";
import {
  MacroAdjustmentCombination,
  MacroAdjustmentCombinations,
  MacroAdjustmentPillars,
  MacroAdjustmentWizardFormikValues,
} from "../../MacroAdjustmentTypes";
import { MacroFilter, MacroFiltersForProducts } from "orval/generated/models";
import { useMemo } from "react";
import { AppConstant } from "constants/AppConstant";

export type EntitiesDropdownStepTwoProps = {
  adjustmentCombination: MacroAdjustmentCombination | undefined;
  filtersData: MacroFiltersAndFilteredRTDL | undefined;
  filtersByProducts: MacroFiltersForProducts | undefined;
  handleEntitiesSave: Function;
};

const EntitiesDropdownStepTwo = ({
  adjustmentCombination,
  filtersData,
  filtersByProducts,
  handleEntitiesSave,
}: EntitiesDropdownStepTwoProps) => {
  const { t } = useTranslation("macro");
  const { values } = useFormikContext<MacroAdjustmentWizardFormikValues>();

  const title = useMemo(() => {
    switch (values.selectedPillar) {
      case MacroAdjustmentPillars.Packaging:
        return t("adjustmentsPage.wizard.stepTwo.selectPartTypeMaterials");
      case MacroAdjustmentPillars.Manufacturing:
        return t("adjustmentsPage.wizard.stepTwo.selectEnergyType");
      case MacroAdjustmentPillars.Logistics:
        return t("adjustmentsPage.wizard.stepTwo.selectTransportMode");
      default:
        return t("adjustmentsPage.wizard.stepTwo.selectIngredients");
    }
  }, [values.selectedPillar, t]);

  const description = useMemo(() => {
    switch (values.selectedPillar) {
      case MacroAdjustmentPillars.Packaging:
        return t(
          "adjustmentsPage.wizard.stepTwo.selectPartTypeMaterialsDescription",
        );
      case MacroAdjustmentPillars.Manufacturing:
        return t("adjustmentsPage.wizard.stepTwo.selectEnergyTypeDescription");
      case MacroAdjustmentPillars.Logistics:
        return t(
          "adjustmentsPage.wizard.stepTwo.selectTransportModeDescription",
        );
      default:
        return t("adjustmentsPage.wizard.stepTwo.selectIngredientsDescription");
    }
  }, [values.selectedPillar, t]);

  const dropdown = useMemo(() => {
    if (!adjustmentCombination) return null;

    switch (adjustmentCombination.combo) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        return (
          <SelectDropdown
            listItems={
              filtersData?.filters.ingredients?.map(
                (ingredient: MacroFilter) => ingredient.value,
              ) || []
            }
            savedSelectedItems={values?.selectedEntities?.map(
              (entity) => entity?.value,
            )}
            onSave={(selectedItems) => {
              handleEntitiesSave(selectedItems, "ingredients");
            }}
            title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.ingredients")}*`}
            compact
            selectAll={true}
            disabled={values.selectedCountries.length === 0}
          />
        );
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
      case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
        return (
          <SelectDropdown
            listItems={
              filtersByProducts?.part_materials?.map(
                (part_material: MacroFilter) => part_material.value,
              ) || []
            }
            savedSelectedItems={values?.selectedEntities?.map(
              (entity) => entity?.value,
            )}
            onSave={(selectedItems) => {
              handleEntitiesSave(selectedItems, "part_materials");
            }}
            title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.partMaterials")}*`}
            compact
            selectAll={true}
            disabled={values.selectedProducts.length === 0}
          />
        );
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return (
          <SelectDropdown
            listItems={AppConstant.eurEnergyTypesList}
            savedSelectedItems={values?.selectedEntities?.map(
              (entity) => entity?.value,
            )}
            onSave={(selectedItems) => {
              handleEntitiesSave(selectedItems, "energy_types");
            }}
            title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.energyTypes")}*`}
            compact
            selectAll={true}
            disabled={values.selectedProducts.length === 0}
          />
        );
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return (
          <SelectDropdown
            listItems={
              filtersByProducts?.transport_modes?.map(
                (transport_mode: MacroFilter) => transport_mode.value,
              ) || []
            }
            savedSelectedItems={values?.selectedEntities?.map(
              (entity) => entity?.value,
            )}
            onSave={(selectedItems) => {
              handleEntitiesSave(selectedItems, "transport_modes");
            }}
            title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.transportModes")}*`}
            compact
            selectAll={true}
            disabled={values.selectedProducts.length === 0}
          />
        );
      default:
        return null;
    }
  }, [
    adjustmentCombination,
    filtersData?.filters.ingredients,
    values?.selectedEntities,
    values.selectedCountries.length,
    values.selectedProducts.length,
    t,
    filtersByProducts?.part_materials,
    filtersByProducts?.transport_modes,
    handleEntitiesSave,
  ]);

  if (!adjustmentCombination) return null;
  else
    return (
      <>
        <Box mt={4} mb={1}>
          <Typography className="header-H3 text-ellipsis">{title}</Typography>
        </Box>
        <Box mb={2}>
          <Typography>{description}</Typography>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid size={4}>{dropdown}</Grid>
          </Grid>
        </Box>
      </>
    );
};

export default EntitiesDropdownStepTwo;
