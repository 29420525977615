import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { GlobalLoaderProvider, NotificationProvider } from "components/common";
import { ModalProvider } from "components/common/Modal";
import { TermsProvider } from "features/terms-and-conditions/components/TermsProvider";
import "../src/App.scss";
import "../src/core";
import { MetadataProvider } from "core/MetadataContext/MetadataContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MemberInfoProvider } from "core/MemberInfoProvider/MemberInfoProvider";
import { appTheme } from "core/Theme/theme";
import axios from "axios";
import AppRoutes from "./core/navigation/routes";
import { AxiosProvider } from "core/AxiosContext/AxiosContext";
import { CountriesProvider } from "utils/CountriesProvider/CountriesProvider";

// Do NOT define inside App component without good reason - causes unstable query client.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          return false;
        }
        return failureCount < 3;
      },
    },
  },
});

function App() {
  // The useRoutes() hook allows you to define your routes as JavaScript objects
  // instead of <Routes> and <Route> elements. This is really just a style
  // preference for those who prefer to not use JSX for their routes config.
  let element = useRoutes(AppRoutes);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={appTheme}>
        <GlobalLoaderProvider>
          <NotificationProvider>
            <AxiosProvider>
              <TermsProvider>
                <MetadataProvider>
                  <ModalProvider>
                    <MemberInfoProvider>
                      <CountriesProvider>
                        <div className="App">{element}</div>
                      </CountriesProvider>
                    </MemberInfoProvider>
                  </ModalProvider>
                </MetadataProvider>
              </TermsProvider>
            </AxiosProvider>
          </NotificationProvider>
        </GlobalLoaderProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
