import {
  Typography,
  Box,
  Button,
  IconButton,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { CustomIcon, Icons } from "components/common/CustomIcon";

export interface ConfirmationModalProps {
  title: string;
  description: string;
  actionTitle: string;
  confirmAction: () => void;
  cancelAction: (event?: React.MouseEvent<HTMLElement>) => void;
}

const ConfirmationModal = ({
  title,
  description,
  actionTitle,
  confirmAction,
  cancelAction,
}: ConfirmationModalProps) => {
  const { t } = useTranslation(["micro", "common"]);
  const { palette } = useTheme();
  const [isPending, setIsPending] = useState(false);

  return (
    <Box maxWidth={500}>
      <Box marginRight={1} marginTop={1} display="flex" justifyContent="right">
        <IconButton
          aria-label={t("common:actions.cancel")}
          sx={{ aspectRatio: 1, float: "right" }}
          onClick={cancelAction}
        >
          <Box data-testid={"CloseIcon"}>
            <CustomIcon
              name={Icons.CLOSE}
              width={24}
              height={24}
              fill={palette.text.primary}
            />
          </Box>
        </IconButton>
      </Box>
      <Box paddingX={5} paddingBottom={4}>
        <Typography textAlign="center" variant="h4" marginBottom={3}>
          {title}
        </Typography>
        <Typography textAlign="center" variant="body2" marginBottom={3}>
          {description}
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            onClick={cancelAction}
            disabled={isPending}
          >
            {t("common:actions.cancel")}
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={() => {
              setIsPending(true);
              confirmAction();
            }}
            data-testid="confirm-action-button"
            disabled={isPending}
          >
            {actionTitle}
            {isPending ? (
              <CircularProgress sx={{ color: "white", ml: 1 }} size={16} />
            ) : null}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmationModal;
