import { MekkoChart } from "./MekkoChart/MekkoChart";
import { MekkoChartData, extractIds } from "./MekkoHelperService";
import { MekkoCustomLegend } from "./MekkoCustomLegend/MekkoCustomLegend";
import MekkoPillarCustomLegend from "./MekkoPillarCustomLegend/MekkoPillarCustomLegend";

export type MekkoChartSectionProps = {
  chartData: MekkoChartData;
};

type RgbColor = {
  r: number;
  g: number;
  b: number;
};

export const chartBaseRgbColours: RgbColor[] = [
  { r: 228, g: 92, b: 43 },
  { r: 217, g: 57, b: 84 },
  { r: 181, g: 121, b: 206 },
  { r: 255, g: 182, b: 0 },
  { r: 151, g: 151, b: 151 },
];

const colourIgnoredFields = ["weighting", "pillar", "total"];

export function MekkoChartSection({ chartData }: MekkoChartSectionProps) {
  const legendsArray = extractIds(chartData.dimensions);
  const pillars = chartData.data
    .map((data) => data.pillar)
    .filter((pillar): pillar is string => typeof pillar === "string");

  const chartColours = chartData.data.flatMap((data, index) => {
    const filteredKeys = Object.keys(data).filter(
      (key) => !colourIgnoredFields.includes(key),
    );
    const baseColour = chartBaseRgbColours[index % chartBaseRgbColours.length];
    return filteredKeys.map((_, subIndex) => {
      const opacity = 1 - 0.8 * (subIndex / filteredKeys.length);
      return `rgba(${baseColour.r}, ${baseColour.g}, ${baseColour.b}, ${opacity})`;
    });
  });

  return (
    <>
      <MekkoPillarCustomLegend
        pillars={pillars}
        chartBaseRgbColours={chartBaseRgbColours}
      />
      <MekkoChart chartData={chartData} chartColours={chartColours} />
      <MekkoCustomLegend keys={legendsArray} chartColours={chartColours} />
    </>
  );
}
