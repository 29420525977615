import { Checkbox, MenuItem, Grid2 as Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SelectAllRow from "../SelectAllRow/SelectAllRow";
import NoItemsRow from "../NoItemsRow/NoItemsRow";

export type SelectDropdownListItem<T extends string | number> = {
  title?: string;
  value: T | SelectDropdownListItem<T>[];
};

type SelectDropdownRowProps<T extends string | number> = {
  index: number;
  filteredItems: SelectDropdownListItem<T>[];
  selectedItems: T[];
  addAllItems: () => void;
  allItemsSelected: boolean;
  addItem: (item: T) => void;
  translate?: boolean;
  selectAll?: boolean;
  style?: React.CSSProperties;
  disabledItems?: T[];
};

export function SelectDropdownRow<T extends string | number>({
  index,
  filteredItems,
  selectedItems,
  addAllItems,
  allItemsSelected,
  addItem,
  translate,
  selectAll,
  style,
  disabledItems,
}: SelectDropdownRowProps<T>) {
  const { t } = useTranslation("common");
  const item = filteredItems[index].value;
  const [showSelectAllRow, setShowSelectAllRow] = useState(
    selectAll && filteredItems.length !== 0,
  );
  const [noItemsFound, setNoItemsFound] = useState(filteredItems.length === 0);

  useEffect(() => {
    setShowSelectAllRow(selectAll && filteredItems.length !== 0);
  }, [filteredItems, selectAll]);

  useEffect(() => {
    setNoItemsFound(filteredItems.length === 0);
  }, [filteredItems]);

  if (!item) {
    return null; // Return null if item is undefined
  }

  if (showSelectAllRow && index === 0) {
    return (
      <SelectAllRow
        addAllItems={addAllItems}
        allItemsSelected={allItemsSelected}
      ></SelectAllRow>
    );
  }

  if (noItemsFound) {
    return <NoItemsRow></NoItemsRow>;
  }

  if (typeof item === "string" || typeof item === "number") {
    return (
      <MenuItem
        sx={{ height: "2.5em", maxWidth: "100%", overflow: "hidden" }}
        onClick={() => {
          addItem(item as T);
        }}
        key={item}
        data-testid={`select-dropdown-item-${index}`}
        style={style}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Grid size={10}>
            <Typography sx={{ textWrap: "auto" }}>
              {translate ? t(`${item}`) : item}
            </Typography>
          </Grid>
          <Grid size={2}>
            <Checkbox
              checked={selectedItems.includes(item)}
              onClick={() => {
                addItem(item as T);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  addItem(item as T);
                }
              }}
              data-testid={`${item}-checkbox`}
              disabled={
                !disabledItems || !disabledItems.includes(item) ? false : true
              }
            />
          </Grid>
        </Grid>
      </MenuItem>
    );
  }

  return null;
}
