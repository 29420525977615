import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid2";
import StepTwoFilterDropdowns from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/StepTwoFilterDropdowns/StepTwoFilterDropdowns";
import { MacroAdjustmentCombination } from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";
import { useTranslation } from "react-i18next";
import {
  MacroFiltersAndFilteredRTDL,
  ViewMacroScenarioDto,
} from "orval/generated/models";
import CountriesDropdownStepTwo from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/CountriesDropdownStepTwo/CountriesDropdownStepTwo";

export type ApplyFiltersViewProps = {
  handleResetPageTwo: () => void;
  adjustmentCombination: MacroAdjustmentCombination | undefined;
  filtersData: MacroFiltersAndFilteredRTDL | undefined;
  setMacroFiltersParams: Function;
  scenario: ViewMacroScenarioDto | undefined;
  handleCountriesSave: Function;
};

const ApplyFiltersView = ({
  handleResetPageTwo,
  adjustmentCombination,
  filtersData,
  setMacroFiltersParams,
  scenario,
  handleCountriesSave,
}: ApplyFiltersViewProps) => {
  const { t } = useTranslation("macro");

  return (
    <>
      <Box>
        <Box
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          mb={2}
        >
          <Box>
            <Typography className="header-H2 text-ellipsis">
              {t("adjustmentsPage.wizard.stepTwo.title")}
            </Typography>
          </Box>
          <Box
            sx={{ minWidth: "200px" }}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Button
              variant="outlined"
              color={"primary"}
              startIcon={<ClearIcon />}
              onClick={handleResetPageTwo}
            >
              {t("adjustmentsPage.wizard.stepTwo.resetFilters")}
            </Button>
          </Box>
        </Box>

        <Box mt={2} mb={1}>
          <Typography className="header-H3 text-ellipsis">
            {t("adjustmentsPage.wizard.stepTwo.selectCountries")}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            {t("adjustmentsPage.wizard.stepTwo.selectCountriesDescription")}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid size={4}>
            <CountriesDropdownStepTwo
              adjustmentCombination={adjustmentCombination}
              scenario={scenario}
              handleCountriesSave={handleCountriesSave}
            />
          </Grid>
        </Grid>

        <Box mt={2} mb={1}>
          <Typography className="header-H3 text-ellipsis">
            {t("adjustmentsPage.wizard.stepTwo.optionalSelectFilters")}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            {t(
              "adjustmentsPage.wizard.stepTwo.optionalSelectFiltersDescription",
            )}
          </Typography>
        </Box>

        <Box mt={3}>
          <Grid container spacing={2}>
            <StepTwoFilterDropdowns
              adjustmentCombination={adjustmentCombination?.combo}
              filtersData={filtersData}
              setMacroFiltersParams={setMacroFiltersParams}
            ></StepTwoFilterDropdowns>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ApplyFiltersView;
