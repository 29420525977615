import { IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { Product } from "orval/generated/models";
import { useTranslation } from "react-i18next";
import { CustomIcon, Icons } from "components/common/CustomIcon";

export type SelectedProductRowProps = {
  item: Product;
  handleRemoveSelectedProduct: (id: string) => void;
};

export function SelectedProductRow({
  item,
  handleRemoveSelectedProduct,
}: SelectedProductRowProps) {
  const { t } = useTranslation("micro");
  const { palette } = useTheme();

  return (
    <Box
      key={`${item.custom_display_name ?? item.product_name}-${item.guid || ""}`}
      width={"90%"}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      data-testid="selected-product-row"
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        width={"auto"}
        alignItems={"center"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Typography>
            {item?.custom_display_name || item?.product_name}
          </Typography>
        </Box>
      </Box>
      {!item.guid ? (
        <IconButton
          data-testid={`delete-btn`}
          aria-label="delete"
          sx={{ aspectRatio: 1, marginLeft: "auto" }}
          onClick={() => {
            handleRemoveSelectedProduct(
              item.custom_display_name || item.product_name,
            );
          }}
        >
          <CustomIcon
            name={Icons.CLOSE}
            width={20}
            height={20}
            fill={palette.text.primary}
          />
        </IconButton>
      ) : (
        <Box sx={{ aspectRatio: 1, marginLeft: "auto" }} mr={1}>
          <Tooltip title={t("productsSection.selectProductModal.tooltip")}>
            <Box data-testid={"lock-icon"}>
              <CustomIcon
                name={Icons.LOCK_CLOSED}
                width={24}
                height={24}
                fill={palette.text.disabled}
              />
            </Box>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
