import { Skeleton, Stack } from "@mui/material";

const LoadingList = () => {
  return (
    <Stack spacing={1}>
      {Array.from({ length: 5 }, (_, index) => index).map((item) => (
        <Skeleton
          key={item}
          variant="rounded"
          width="100%"
          height={70}
          sx={{ bgcolor: "grey.100" }}
          data-testid="loading-list-item"
          animation="wave"
        />
      ))}
    </Stack>
  );
};

export default LoadingList;
