import {
  alpha,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { Product, SKUMinimalViewModel } from "orval/generated/models";
import { CSSProperties } from "react";
import { CustomIcon, Icons } from "components/common/CustomIcon";

type ProductRowProps = {
  index: number;
  style: CSSProperties;
  filteredSkus: SKUMinimalViewModel[];
  selectedProducts: Product[];
  productLimit: number;
  onChangeSelectProduct: (item: Product, checked: boolean) => void;
};

export function ProductRow({
  index,
  style,
  filteredSkus,
  selectedProducts,
  productLimit,
  onChangeSelectProduct,
}: ProductRowProps) {
  const { palette } = useTheme();
  const product = filteredSkus[index];

  const isSelected = selectedProducts.some((selectedProduct) => {
    return selectedProduct.product_name === product.product_name;
  });

  const isLocked = selectedProducts.some((selectedProduct) => {
    return (
      selectedProduct.product_name === product.product_name &&
      selectedProduct.guid
    );
  });

  const isDisabled =
    isLocked || (selectedProducts.length >= productLimit && !isSelected);

  return (
    <FormControlLabel
      labelPlacement="start"
      key={`${product.product_name}-${index}`}
      control={
        <Checkbox
          name={product.product_name}
          checked={isSelected}
          checkedIcon={
            isLocked ? (
              <Box data-testid={"lock-icon"}>
                <CustomIcon
                  name={Icons.LOCK_CLOSED}
                  width={24}
                  height={24}
                  fill={palette.text.disabled}
                />
              </Box>
            ) : (
              <CustomIcon
                name={Icons.CHECKMARK}
                width={24}
                height={24}
                fill={palette.text.primary}
              />
            )
          }
          onChange={(_e, checked) => {
            onChangeSelectProduct(product as unknown as Product, checked);
          }}
          icon={
            <CustomIcon
              name={Icons.ADD}
              width={24}
              height={24}
              fill={palette.text.primary}
            />
          }
          disabled={isDisabled}
          data-testid={`checkbox-index-${index}`}
        />
      }
      label={
        <Box display="flex" flexDirection="column" mb={0.5} mt={0.5}>
          <Typography>{product.product_name}</Typography>
        </Box>
      }
      sx={{
        justifyContent: "space-between",
        marginLeft: 0,
        width: "calc(100% - 4px) !important",
        paddingLeft: 2,
        backgroundColor: isLocked
          ? alpha(palette.activeColor.main, 0.4)
          : isSelected
            ? palette.activeColor.main
            : "",
      }}
      style={style}
    />
  );
}
