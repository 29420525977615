import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ChangeEvent, useState } from "react";
import { CustomIcon, Icons } from "components/common/CustomIcon";
import { useTheme } from "@mui/material";

export type SearchBarProps = {
  placeHolder?: string;
  handleSearch?: (search: string) => void;
  disabled?: boolean;
};

export function SearchBar({
  placeHolder,
  handleSearch,
  disabled,
}: SearchBarProps) {
  const { t } = useTranslation("common");
  const [searchString, setSearchString] = useState("");
  const { palette } = useTheme();

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    if (handleSearch) {
      handleSearch(e.target.value);
      setSearchString(e.target.value);
    }
  }

  return (
    <Box width={"100%"}>
      <TextField
        id="search"
        variant="outlined"
        fullWidth
        placeholder={placeHolder || t("labels.search")}
        onChange={(e) => handleChange(e)}
        autoComplete="search"
        InputProps={{
          startAdornment: (
            <Box data-testid={"SearchIcon"} display="flex" alignItems="center">
              <CustomIcon
                name={Icons.SEARCH}
                width={23}
                height={23}
                fill={palette.primary.main}
              />
            </Box>
          ),
        }}
        value={searchString}
        size="small"
        disabled={disabled}
        data-testid="search-bar"
      />
    </Box>
  );
}
