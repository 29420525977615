import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { CustomIcon, IconName } from "components/common/CustomIcon";
import { useTheme } from "@mui/material";
import { Box } from "@mui/system";

export interface Tablist {
  label: string;
  icon?: IconName;
}

export interface TabProps {
  tabList: Readonly<Tablist[]>;
  value: number;
  setValue: Function;
  removeTranslate?: boolean;
  disabled?: boolean;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function CustomTabs({
  setValue,
  value,
  tabList,
  removeTranslate,
  disabled,
}: TabProps) {
  const { t } = useTranslation();
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { palette } = useTheme();

  return (
    <Tabs value={value} onChange={handleChange}>
      {tabList.map((tab, index) => {
        const label = removeTranslate ? tab.label : t(tab.label);
        const color =
          value === index ? palette.primary.main : palette.text.secondary;

        return (
          <Tab
            label={
              tab.icon ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    color: color,
                  }}
                >
                  <Box data-testid={tab.icon}>
                    <CustomIcon
                      name={tab.icon}
                      width={24}
                      height={24}
                      fill={color}
                    />
                  </Box>
                  <span>{label}</span>
                </div>
              ) : (
                <span style={{ color: color }}>{label}</span>
              )
            }
            style={{ textTransform: "inherit" }}
            key={tab.label}
            disabled={disabled}
            {...a11yProps(index)}
          />
        );
      })}
    </Tabs>
  );
}
