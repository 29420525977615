export enum ProductConfigurationsMicro {
  SERVING_SIZE = "serving_size",
  INGREDIENTS = "ingredients",
  INGREDIENTS_EMISSION_FACTORS = "ingredients_ef",
  BODY_AND_LID_PACKAGING = "body_and_lid_packaging",
  BODY_AND_LID_EMISSION_FACTORS = "body_and_lid_ef",
  OTHER_PACKAGING = "other_packaging",
  OTHER_PACKAGING_PRIMARY = "other_primary",
  OTHER_PACKAGING_SECONDARY = "secondary",
  OTHER_PACKAGING_TERTIARY = "tertiary",
  OTHER_PACKAGING_EMISSION_FACTORS = "other_packaging_ef",
  MANUFACTURING = "manufacturing",
  LOGISTICS = "logistics",
  RESULTS = "results",
  OTHER_PRIMARY = "other_primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
}

export enum ProductConfigurationsMacro {
  ABSOLUTE = "absolute",
  PER_LITRE = "per_litre",
  PERCENTAGE = "percentage",
  SALES = "sales",
  MEKKO = "mekko",
  WATERFALL = "waterfall",
  SALES_VOLUME_BAR_ZERO_DP = "sales_volume_bar_zero_dp",
  SALES_VOLUME_BAR_ONE_DP = "sales_volume_bar_one_dp",
  ZERO_DP = "zero_dp",
  ONE_DP = "one_dp",
  TWO_DP = "two_dp",
  THREE_DP = "three_dp",
  FOUR_DP = "four_dp",
}

export type ProductsEditableColumnConfigItem = {
  field_id?: number;
  field_key: string;
  label: string;
  defaultUnit: MicroUnits.OVERRIDE | MicroUnits.PERCENTAGE;
};

export enum MicroUnits {
  PERCENTAGE = "percentage",
  OVERRIDE = "",
}

export type rowValidationType = {
  metric: string;
  field_key: string;
  type: "percentage" | "unit";
  isNonZero: boolean;
};

export type SubRow = {
  id: string;
  metric: string;
  validationType: string;
  validationIsNonZero: boolean;
  [key: string]: string | boolean;
};

export type TableRow = {
  metric: string;
  dataStructureKey: string;
  subRows: SubRow[];
  [key: string]: string | number | SubRow[];
};

export type TableData = TableRow[];
