import {
  Accordion,
  // AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Chip,
  Tooltip,
  // Chip,
  // Grid2,
  Typography,
  useTheme,
  // useTheme,
} from "@mui/material";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import styled from "@emotion/styled";
import ThreeDotMenu from "components/common/ThreeDotMenu/ThreeDotMenu";
import { useTranslation } from "react-i18next";
// import AppliedFilter from "./AppliedFilter";
import { useCallback, useMemo } from "react";
import ConfirmationModal from "components/common/ConfirmationModal/ConfirmationModal";
import { useModal } from "components/common/Modal";
import {
  CountryViewModel,
  MacroScenarioAdjustmentOverview,
  // MacroFilters,
  // MacroFiltersAndFilteredRTDL,
  // MacroFilterValue,
  YearsTargetYear,
} from "orval/generated/models";
import {
  getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey,
  useDeleteByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdDelete,
} from "orval/generated/endpoint";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { CustomIcon, Icons, useGlobalLoader } from "components/common";
import { useNavigate } from "react-router-dom";
import { MacroToolRoutesConfig } from "features/macro-tool/navigation";

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))({
  border: `1px solid #CBCBCB`,
  borderRadius: "8px",
  padding: 0,
  maxWidth: "100%",
});

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary {...props} />
))({
  position: "relative",
  "& .MuiAccordionSummary-expandIconWrapper": {
    //TODO: remove later when the collapse functionality is back
    display: "none",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
  },
});

// const CustomAccordionDetails = styled(AccordionDetails)({
//   margin: "10px 20px",
//   overflow: "hidden",
// });

export type MacroAdjustmentRowProps = {
  adjustmentDetails: MacroScenarioAdjustmentOverview;
  scenarioCountries: CountryViewModel[];
  // filters: MacroFiltersAndFilteredRTDL;
  totalRtdl: number;
  expanded: boolean;
  toggleExpanded: (id: number) => void;
  targetYear: YearsTargetYear | undefined;
  scenarioId: number;
};

const MacroAdjustmentRow = ({
  expanded,
  // filters,
  toggleExpanded,
  adjustmentDetails,
  scenarioCountries,
  totalRtdl,
  targetYear,
  scenarioId,
}: MacroAdjustmentRowProps) => {
  const { t } = useTranslation(["macro", "common"]);
  const { openModal, closeModal } = useModal();
  // const theme = useTheme();
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();
  const { showGlobalLoader } = useGlobalLoader();
  const navigate = useNavigate();
  const { palette } = useTheme();

  const { mutateAsync: deleteMacroAdjustment } =
    useDeleteByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdDelete({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey:
              getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey(
                scenarioId,
              ),
          });

          showGlobalLoader(false);

          showSnackbar(
            t("macro:adjustmentsPage.confirmationModal.deletedAdjustment"),
            "success",
          );
        },
        onError: (error: any) => {
          showSnackbar(
            t("macro:adjustmentsPage.confirmationModal.failedToDelete"),
            "error",
          );
          console.warn(error);
          return error;
        },
      },
    });

  //TODO: Uncomment when filters are added to adjustment view model

  // const filtersLists = useMemo(() => {
  //   const newFilters: { [key: string]: MacroFilterValue[] } = {};

  //   for (const [key, ids] of Object.entries(adjustmentDetails.filters)) {
  //     newFilters[key] = filters.filters[key as keyof MacroFilters]
  //       ?.filter((item) => (ids as number[]).includes(item.id))
  //       .map((item) => item.value);
  //   }

  //   return newFilters;
  // }, [adjustmentDetails.filters, filters]);

  const handleConfirmDeletion = useCallback(async () => {
    await deleteMacroAdjustment({
      scenarioId: scenarioId,
      adjustmentId: adjustmentDetails.id,
    });
    closeModal();
  }, [adjustmentDetails.id, closeModal, deleteMacroAdjustment, scenarioId]);

  const threeDotsMenuItems = useMemo(() => {
    return [
      {
        label: t("common:actions.edit"),
        onClick: () => {
          navigate(
            MacroToolRoutesConfig.editAdjustment
              .replace(":id", String(scenarioId))
              .replace(":adjustmentId", String(adjustmentDetails.id)),
          );
        },
        disabled: false,
      },
      {
        label: t("common:actions.delete"),
        onClick: () => {
          openModal(
            <ConfirmationModal
              title={t("macro:adjustmentsPage.confirmationModal.title")}
              description={t(
                "macro:adjustmentsPage.confirmationModal.description",
              )}
              actionTitle={t(
                "macro:adjustmentsPage.confirmationModal.actionTitle",
              )}
              confirmAction={handleConfirmDeletion}
              cancelAction={() => {
                closeModal();
              }}
            />,
          );
        },
      },
    ];
  }, [
    adjustmentDetails.id,
    closeModal,
    handleConfirmDeletion,
    navigate,
    openModal,
    scenarioId,
    t,
  ]);

  const countriesMap = useMemo(() => {
    const hashMap: { [key: string]: string } = {};

    scenarioCountries.forEach(({ country, display_name }) => {
      if (!hashMap[country]) {
        hashMap[country] = display_name;
      }
    });

    return hashMap;
  }, [scenarioCountries]);

  const countriesChipLabel = useMemo(() => {
    const { countries } = adjustmentDetails;
    return countries.length === 1
      ? countriesMap[countries[0]]
      : `${countries.length} ${t("macro:adjustmentsPage.countries")}`;
  }, [adjustmentDetails, countriesMap, t]);

  const countriesTooltipTitle = useMemo(() => {
    const { countries } = adjustmentDetails;
    if (countries.length === 1) {
      return countriesMap[countries[0]];
    }
    return countries.length > 10 ? (
      countries.join(", ")
    ) : (
      <ul style={{ margin: 0, padding: 0 }}>
        {countries.sort().map((country, index) => (
          <li key={index}>{countriesMap[country]}</li>
        ))}
      </ul>
    );
  }, [adjustmentDetails, countriesMap]);

  // const salesVolumePercentage = useMemo(() => {
  //   return ((filters.filtered_rtdl / totalRtdl) * 100).toFixed(2);
  // }, [filters.filtered_rtdl, totalRtdl]);

  return (
    <CustomAccordion
      slotProps={{ transition: { unmountOnExit: true } }}
      expanded={expanded}
      onChange={() => toggleExpanded(adjustmentDetails.id)}
      data-testid="single-adjustment-accordion"
      // disabled={true} //TODO: Remove this temp disable when more data is available to show on ViewMacroAdjustment
    >
      <CustomAccordionSummary
        expandIcon={
          expanded ? (
            // <ExpandLessIcon sx={{ marginRight: "50px" }} />
            <Box mr={2}>
              <CustomIcon
                name={Icons.CHEVRON_DOWN}
                width={24}
                height={24}
                fill={palette.primary.main}
              />
            </Box>
          ) : (
            //TODO: put back ExpandLessIcon when the collapse feat is ready to be shown
            <Box mr={2}>
              <CustomIcon
                name={Icons.CHEVRON_DOWN}
                width={24}
                height={24}
                fill={palette.primary.main}
              />
            </Box>
          )
        }
        data-testid="accordion-summary"
      >
        <Box sx={{ position: "absolute", right: 8 }}>
          <ThreeDotMenu menuItems={threeDotsMenuItems} />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          pr="30px"
        >
          <Box>
            <Typography mb={1} sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
              {adjustmentDetails.name}
            </Typography>
            <Typography
              sx={{ fontWeight: 600, fontSize: "1rem", color: "#6A6A6A" }}
            >
              {`${adjustmentDetails.pillar} - ${adjustmentDetails.input_area}`}
            </Typography>

            {adjustmentDetails.description && (
              <Typography variant="body2" sx={{ mt: 2 }}>
                {`${adjustmentDetails.description}`}
              </Typography>
            )}
          </Box>
          <Tooltip title={countriesTooltipTitle}>
            <Chip
              variant="filled"
              label={countriesChipLabel}
              sx={{
                backgroundColor: `${palette.textColor.light} !important`,
                fontWeight: "500 !important",
                color: `${palette.textColor.dark} !important`,
                border: "0px",
                borderRadius: "4px !important",
              }}
            />
          </Tooltip>
        </Box>
      </CustomAccordionSummary>
      {/* <CustomAccordionDetails data-testid="accordion-details">
        <Grid2 container>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Typography mb={2} sx={{ fontWeight: 600, fontSize: "1rem" }}>
              {t("macro:adjustmentsPage.filtersApplied")}:
            </Typography> */}
      {/* <Typography mb={2}>
              {t("macro:adjustmentsPage.salesVolumeStatementFirstPart")}
              <span style={{ color: theme.palette.primary.main }}>
                {` ${salesVolumePercentage}%
                ${t("macro:adjustmentsPage.salesVolumeStatementSecondPart")} ${targetYear}`}
              </span>
            </Typography> */}

      {/* {adjustmentDetails.countries.length !== allCountries.length ? (
              <AppliedFilter
                list={adjustmentDetails.countries.map(
                  (code) =>
                    allCountries.find((country) => country.country === code)
                      ?.display_name as string,
                )}
                total={allCountries.length}
                filtered={adjustmentDetails.countries.length}
                name={t(`macro:adjustmentsPage.countries`)}
              />
            ) : null} */}

      {/*   //TODO: Uncomment when filters are added to adjustment view model */}
      {/* {Object.keys(filtersLists).map((key) => {
              return filtersLists[key]?.length &&
                filtersLists[key]?.length !==
                  filters.filters[key as keyof MacroFilters]?.length ? (
                <AppliedFilter
                  list={filtersLists[key]}
                  total={filters.filters[key as keyof MacroFilters]?.length}
                  filtered={filtersLists[key]?.length}
                  name={t(`macro:adjustmentsPage.${key}`)}
                />
              ) : null;
            })} */}
      {/* </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}> */}
      {/* <Typography mb={2} sx={{ fontWeight: 600, fontSize: "1rem" }}>
              {t("macro:adjustmentsPage.adjustment")}:
            </Typography> */}
      {/* <Typography mb={2}> */}
      {/* {t("macro:adjustmentsPage.decreaseStatementFirstPart")} */}
      {/* <span style={{ color: theme.palette.primary.main }}> */}
      {/*TODO: Change 'ingredients' dynamically to be scallable matching any type of adjustment */}
      {/*TODO: Change 12 to real value */}
      {/* {` ${adjustmentDetails.entities.length}`} */}
      {/* <span style={{ color: theme.palette.text.primary }}>
                  {` ingredients ${t("macro:adjustmentsPage.which")}`}
                </span>
                {` ${t("macro:adjustmentsPage.decreaseStatementSecondPart")} 12 ${t("macro:adjustmentsPage.unit")}`} */}
      {/* </span> */}
      {/* </Typography> */}

      {/*TODO: Change  to real value */}
      {/* <Grid2 container alignItems="center" spacing={2}>
              <Grid2>Sugar:</Grid2>
              <Grid2>
                <Chip
                  variant="filled"
                  label="3 Percentage changes"
                  sx={{
                    backgroundColor: "#C3D3E2 !important",
                    fontWeight: "500 !important",
                    color: "#40576D!important",
                    border: "0px",
                    borderRadius: "4px !important",
                  }}
                />
              </Grid2>
            </Grid2> */}
      {/* </Grid2>
        </Grid2>
      </CustomAccordionDetails> */}
    </CustomAccordion>
  );
};
export default MacroAdjustmentRow;
