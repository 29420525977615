import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ScenarioResults } from "../../components";
import { DateAndCountryInputSection } from "../../components/macro-hero-section";
import { MacroToolRoutesConfig } from "../../navigation/config";
import { CustomIcon, Icons, NoData } from "components/common";
import {
  UpdateMacroScenarioDto,
  ViewMacroScenarioDto,
} from "orval/generated/models";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { useQueryClient } from "@tanstack/react-query";
import { ScenarioHeroSection } from "components/common/ScenarioHeroSection/ScenarioHeroSection";
import { useMemberInfo } from "core/MemberInfoProvider/MemberInfoProvider";
import { AxiosError } from "axios";
import {
  getGetMacroScenarioByIdScenariosMacroScenarioIdGetQueryKey,
  useGetMacroScenarioByIdScenariosMacroScenarioIdGet,
  useGetYearsYearsGet,
  useUpdateMacroScenarioScenariosMacroScenarioIdPatch,
} from "orval/generated/endpoint";
import { CircularProgress } from "@mui/material";

const redirectURL = MacroToolRoutesConfig.countryLevelAnalytics;

function MacroToolScenarioPage() {
  const queryClient = useQueryClient();
  const { t } = useTranslation("macro");
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: urlScenarioId } = useParams<{ id: string }>();
  const [resultsHidden, setResultsHidden] = useState<boolean>(false);
  const scenarioId = urlScenarioId || location.state?.id;

  const {
    data: scenario,
    isPending: scenarioIsPending,
    error: scenarioError,
  } = useGetMacroScenarioByIdScenariosMacroScenarioIdGet(parseInt(scenarioId));
  const { data: years } = useGetYearsYearsGet();
  const [headersIndent, setHeadersIndent] = useState<string>(
    location.state?.headersIndent || "14px",
  );
  if (headersIndent !== "14px") {
    setTimeout(() => setHeadersIndent("14px"), 300);
  }

  useEffect(() => {
    if (scenarioError) {
      showSnackbar(scenarioError as AxiosError<unknown, any>, "info");
    }
  }, [scenarioError, scenarioIsPending, showSnackbar]);

  const {
    mutateAsync: updateMacroScenario,
    isPending: updateMacroScenarioIsPending,
  } = useUpdateMacroScenarioScenariosMacroScenarioIdPatch({
    mutation: {
      onSuccess: async () => {
        scenario &&
          scenario.id &&
          (await queryClient.invalidateQueries({
            queryKey:
              getGetMacroScenarioByIdScenariosMacroScenarioIdGetQueryKey(
                scenario.id,
              ),
          }));
        showSnackbar(t("notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.updateScenario"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const handleRenameScenario = async (value: string) => {
    if (scenario && scenario.name !== value && scenario.id) {
      const _updateScenarioPayload: UpdateMacroScenarioDto = {
        name: value,
      };
      if (scenario.id) {
        try {
          await updateMacroScenario({
            scenarioId: scenario.id,
            data: _updateScenarioPayload,
          });
        } catch (error) {
          showSnackbar(t("errorMessages.duplicateName"), "error");
        }
      }
    }
  };

  const handleRenameDescription = async (value: string) => {
    const scenarioDescriptionHasBeenChanged =
      scenario && scenario.description !== value;
    if (scenarioDescriptionHasBeenChanged) {
      const scenarioUpdates: UpdateMacroScenarioDto = {
        description: value,
      };

      try {
        scenario.id &&
          (await updateMacroScenario({
            scenarioId: scenario.id,
            data: scenarioUpdates,
          }));
      } catch (e) {
        showSnackbar(t("errorMessages.updateScenario"), "error");
        console.warn(e);
      }
    }
  };

  // ? Hides results if no base year or target year
  useEffect(() => {
    const allFieldsFilled =
      scenario &&
      scenario.results &&
      (scenario as ViewMacroScenarioDto)?.inputs?.years?.base_year &&
      (scenario as ViewMacroScenarioDto)?.inputs?.years?.target_year &&
      ((scenario as ViewMacroScenarioDto)?.inputs?.countries ?? []).length > 0;
    if (allFieldsFilled) {
      setResultsHidden(false);
    } else {
      setResultsHidden(true);
    }
  }, [scenario]);

  const handleUpdateScenario = async (
    scenarioUpdateModel: UpdateMacroScenarioDto,
  ) => {
    if (scenario?.id) {
      try {
        await updateMacroScenario({
          scenarioId: scenario.id,
          data: scenarioUpdateModel,
        });
      } catch (error) {
        showSnackbar(t("errorMessages.updateScenario"), "error");
      }
    }
  };

  //
  // IMPORTANT - Not currently in use, here for future use if required
  const { memberInfo } = useMemberInfo();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isPwCUser = memberInfo?.email?.includes("@pwc.com");
  // IMPORTANT - Not currently in use, here for future use if required
  //

  const handleNavigateToAdjustmentsPage = () => {
    if (scenario?.id) {
      navigate(
        MacroToolRoutesConfig.adjustmentsPage.replace(
          ":id",
          String(scenario.id),
        ),
        {
          state: {
            id: scenario.id,
          },
        },
      );
    }
  };

  const notAdjustable =
    scenario?.inputs?.countries?.length === 0 ||
    scenario?.inputs?.years?.base_year === null ||
    scenario?.inputs?.years?.target_year === null;

  return (
    <>
      <Box>
        <ScenarioHeroSection
          scenario={scenario as ViewMacroScenarioDto}
          onRenameScenario={handleRenameScenario}
          handleRenameDescription={handleRenameDescription}
          headersIndent={headersIndent}
          updateScenarioIsPending={updateMacroScenarioIsPending}
          backBtnHandler={() => {
            navigate(redirectURL, {
              state: {
                headersIndent: headersIndent,
              },
            });
          }}
          backBtnText={t("createScenarioSection.backToMacroHome")}
          actionsSection={
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={handleNavigateToAdjustmentsPage}
              endIcon={
                <CustomIcon
                  name={Icons.LIST}
                  width={24}
                  height={24}
                  fill={"white"}
                />
              }
              disabled={notAdjustable}
              //TODO: disable on editName || editDescription
            >
              {t("buttonLabel.viewAdjustments")}
            </Button>
          }
        />

        <DateAndCountryInputSection
          years={years?.map((year) => year.year) ?? []}
          baseYear={
            (scenario as ViewMacroScenarioDto)?.inputs?.years?.base_year ?? null
          }
          targetYear={
            (scenario as ViewMacroScenarioDto)?.inputs?.years?.target_year ??
            null
          }
          countries={
            (scenario as ViewMacroScenarioDto)?.inputs?.countries ?? []
          }
          handleUpdateScenario={handleUpdateScenario}
          disabled={false}
          hasAdjustments={Boolean(scenario?.has_adjustments)}
        />
        {updateMacroScenarioIsPending || scenarioIsPending ? (
          <Box mt={2} ml={2}>
            <CircularProgress size={"32px"} />
          </Box>
        ) : resultsHidden ? (
          <NoData label={t("noResultsTitle")}></NoData>
        ) : scenario ? (
          <ScenarioResults
            scenario={scenario as ViewMacroScenarioDto}
            disabled={
              !(
                !!(scenario as ViewMacroScenarioDto)?.inputs?.countries ||
                !!scenarioId
              )
            }
          />
        ) : null}
      </Box>
    </>
  );
}

export default MacroToolScenarioPage;
