import { Box, Button, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormikProps } from "formik";
import {
  MacroAdjustmentCombinations,
  MacroAdjustmentStep,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";

export type MacroAdjustmentsWizardFooterProps = {
  handleLeftButtonClick: () => void;
  steps: (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => MacroAdjustmentStep[];
  formikProps: FormikProps<MacroAdjustmentWizardFormikValues>;
  activeStep: number;
  handleRightButtonClick: (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => Promise<void>;
  adjustmentCombination: MacroAdjustmentCombinations | null;
  validationErrors: Record<number, string | undefined>;
};

const MacroAdjustmentsWizardFooter = ({
  handleLeftButtonClick,
  steps,
  formikProps,
  activeStep,
  handleRightButtonClick,
  adjustmentCombination,
  validationErrors,
}: MacroAdjustmentsWizardFooterProps) => {
  const [rightButtonDisabled, setRightButtonDisabled] = useState(true);
  const { values } = formikProps;
  const [isPending, setIsPending] = useState(false);

  const updateRightButtonDisabled = useCallback(() => {
    switch (activeStep) {
      // STEP ONE PAGE:
      case 0:
        setRightButtonDisabled(
          adjustmentCombination === null ||
            values.selectedPillar === null ||
            values.selectedInputArea === null ||
            values.selectedAdjustmentType === null ||
            values.payload.name === "" ||
            values.payload.name === null ||
            values.payload.name.length < 3,
        );
        break;
      // STEP TWO PAGE - ADDITIONAL SWITCH FOR STEP TWO:
      case 1:
        switch (adjustmentCombination) {
          case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
            setRightButtonDisabled(values.selectedEntities.length === 0);
            break;
          case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
            setRightButtonDisabled(
              values.selectedEntities.length === 0 ||
                values.payload.filters.brands?.length === 0,
            );
            break;
          case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
          case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
          case MacroAdjustmentCombinations.Packaging_RecycledContent:
          case MacroAdjustmentCombinations.Packaging_NetLossRate:
          case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
          case MacroAdjustmentCombinations.Packaging_RecoveryRate:
          case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
          case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
            setRightButtonDisabled(
              values.selectedProducts.length === 0 ||
                values.selectedEntities.length === 0,
            );
            break;
          default:
            setRightButtonDisabled(false);
        }
        break;
      // STEP THREE PAGE:
      case 2:
        setRightButtonDisabled(
          values.payload.entities.filter((entity) => entity.adjusted_value)
            .length < 1 ||
            Object.values(validationErrors).some(
              (error) => error !== undefined,
            ),
        );
        break;
      default:
        setRightButtonDisabled(false);
    }
  }, [activeStep, adjustmentCombination, validationErrors, values]);

  useEffect(() => {
    updateRightButtonDisabled();
  }, [activeStep, formikProps, updateRightButtonDisabled]);

  return (
    <Box display={"flex"} justifyContent={"space-between"} mb={3}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleLeftButtonClick()}
        disabled={isPending}
      >
        {steps(formikProps)[activeStep].leftBtnText}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          activeStep === 2 ? setIsPending(true) : setIsPending(false);

          try {
            await handleRightButtonClick(formikProps);
          } catch (e) {
            console.log(e);
          } finally {
            setIsPending(false);
          }
        }}
        disabled={rightButtonDisabled || isPending}
      >
        {steps(formikProps)[activeStep].rightBtnText}
        {isPending ? (
          <CircularProgress size={24} sx={{ color: "white", ml: 1 }} />
        ) : null}
      </Button>
    </Box>
  );
};

export default MacroAdjustmentsWizardFooter;
