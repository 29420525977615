import { Skeleton } from "@mui/material";

export type LoadingItemProps = {
  width: number | string;
  height: number;
};

function LoadingItem({ width, height }: LoadingItemProps) {
  return (
    <Skeleton
      variant="rounded"
      width={width}
      height={height}
      sx={{ bgcolor: "grey.100" }}
      data-testid="loading-item"
      animation="wave"
    />
  );
}

export default LoadingItem;
