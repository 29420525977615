import {
  ListMacroScenarioDto,
  ListMicroScenarioDto,
  Product,
  SKUAdjustmentAccordionDetails,
  SKUField,
  ViewMicroScenarioDto,
} from "orval/generated/models";
import { ProductConfigurationsMicro } from "features/micro-tool/components/MicroAdjustmentsTable/types";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import { useMemo } from "react";

export type AdjustmentCountChipProps = {
  scenario:
    | ViewMicroScenarioDto
    | ListMicroScenarioDto
    | ListMacroScenarioDto
    | undefined;
  accordionType?: ProductConfigurationsMicro;
};

const isViewMicroScenarioDto = (
  scenario: any,
): scenario is ViewMicroScenarioDto => {
  return (scenario as ViewMicroScenarioDto).products !== undefined;
};

const AdjustmentCountChip = ({
  scenario,
  accordionType,
}: AdjustmentCountChipProps): JSX.Element | null => {
  const { t } = useTranslation("micro");

  const adjustmentCount = useMemo(() => {
    let count = 0;
    if (isViewMicroScenarioDto(scenario)) {
      scenario.products?.forEach((product: Product) => {
        product.adjustments?.forEach(
          (adjustment: SKUAdjustmentAccordionDetails) => {
            if (adjustment.accordion === accordionType) {
              adjustment.fields?.forEach((field: SKUField) => {
                if (field.adjusted_value !== null) {
                  count++;
                }
              });
              adjustment.sub_accordions?.forEach(
                (subAccordion: SKUAdjustmentAccordionDetails) => {
                  subAccordion.fields?.forEach((field: SKUField) => {
                    if (field.adjusted_value !== null) {
                      count++;
                    }
                  });
                },
              );
            }
          },
        );
      });
    } else if (
      (scenario as ListMicroScenarioDto | ListMacroScenarioDto)
        ?.adjustment_count
    ) {
      count = (scenario as ListMicroScenarioDto | ListMacroScenarioDto)
        ?.adjustment_count;
    }
    return count;
  }, [scenario, accordionType]);

  return (
    <>
      {adjustmentCount > 0 ? (
        <Chip
          variant="outlined"
          avatar={
            <Avatar
              sx={{
                backgroundColor: "#FFFFFF",
                fontWeight: "500",
                marginRight: "4px !important",
              }}
            >
              {adjustmentCount}
            </Avatar>
          }
          label={t("adjustmentsSection.adjustmentsMade")}
          sx={{
            backgroundColor: "#C3D3E2 !important",
            fontWeight: "500 !important",
            color: "#40576D !important",
            border: "0px",
            "& .MuiChip-label": {
              marginLeft: "-6px !important",
            },
            ml: 2,
          }}
          data-testid={`adjustment-chip-${accordionType}`}
        />
      ) : null}
    </>
  );
};
export default AdjustmentCountChip;
