import {
  CombinedDataItem,
  TableResultsFilterDataAccessorKey,
  TableResultsType,
} from "../../ScenarioResults/types";
import {
  EmissionForEachYear,
  EmissionPerPillar,
  ViewMacroScenarioDto,
  Years,
} from "orval/generated/models";
import { formatNumberOrEmpty } from "utils/formatNumber/formatNumber";

function getResultsOverviewTableData(
  type: TableResultsType,
  scenario: ViewMacroScenarioDto,
  absoluteOrPerLitre: TableResultsFilterDataAccessorKey,
): CombinedDataItem[] {
  // By pillar does not contain the absoluteOrPerLitre keys
  if (type === TableResultsType.BY_PILLAR) {
    return [];
  }

  const dataObject = scenario?.results?.[type];

  if (!dataObject) {
    return [];
  }

  const filteredDataObject = Object.entries(dataObject).map(([key, value]) => {
    return {
      [key]: value[absoluteOrPerLitre],
    };
  });

  const combinedDataItems: CombinedDataItem[] = [];

  filteredDataObject.forEach((item: { [p: string]: EmissionPerPillar }) => {
    const key = Object.keys(item)[0];
    const categoryData = item[key] as EmissionPerPillar;

    ["base", "glide", "scenario"].forEach((type) => {
      const yearType =
        type === "base" ? "base" : type === "scenario" ? "target" : "";
      combinedDataItems.push({
        category: key,
        [type]: scenario.inputs
          ? (scenario.inputs.years as Years)?.[
              `${yearType}_year` as keyof Years
            ]
          : "",
        ingredients: formatNumberOrEmpty(
          categoryData.ingredients[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
        packaging: formatNumberOrEmpty(
          categoryData.packaging[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
        manufacturing: formatNumberOrEmpty(
          categoryData.manufacturing[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
        logistics: formatNumberOrEmpty(
          categoryData.logistics[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
        cde: formatNumberOrEmpty(
          categoryData.cde[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
        total: formatNumberOrEmpty(
          categoryData.total[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
      });
    });
  });

  return combinedDataItems;
}

export default getResultsOverviewTableData;
