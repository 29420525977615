import SelectDropdown from "components/common/SelectDropdown/SelectDropdown";
import Autocomplete from "@mui/material/Autocomplete";
import {
  MacroAdjustmentCombination,
  MacroAdjustmentCombinations,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";
import { CountryViewModel, ViewMacroScenarioDto } from "orval/generated/models";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { TextField } from "@mui/material";

export type CountriesDropdownStepTwoProps = {
  adjustmentCombination: MacroAdjustmentCombination | undefined;
  scenario: ViewMacroScenarioDto | undefined;
  handleCountriesSave: Function;
};

const CountriesDropdownStepTwo = ({
  adjustmentCombination,
  scenario,
  handleCountriesSave,
}: CountriesDropdownStepTwoProps) => {
  const { t } = useTranslation("macro");
  const { values, setFieldValue } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  if (!adjustmentCombination) {
    return null;
  }

  switch (adjustmentCombination.combo) {
    case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
      return (
        <SelectDropdown
          listItems={
            scenario?.inputs?.countries?.map(
              (country) => country.display_name,
            ) || []
          }
          savedSelectedItems={values.selectedCountries?.map(
            (country) => country.display_name,
          )}
          onSave={(selectedItems) => {
            const selectedCountriesAsCountryViewModels = selectedItems?.map(
              (countryDisplayName) => {
                return scenario?.inputs?.countries?.find(
                  (country) => country.display_name === countryDisplayName,
                );
              },
            );

            setFieldValue(
              "selectedCountries",
              selectedCountriesAsCountryViewModels,
            ).catch((error) => {
              console.warn(error);
            });
            handleCountriesSave(
              selectedCountriesAsCountryViewModels as CountryViewModel[],
            );
          }}
          title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.countries")}*`}
          compact
          selectAll={true}
          disabled={scenario?.inputs?.countries?.length === 0}
        />
      );
    case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      return (
        <Autocomplete
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.country")}*`}
              name={"country"}
            />
          )}
          value={values.selectedCountries[0]?.display_name || null}
          options={
            scenario?.inputs?.countries?.map(
              (country) => country.display_name,
            ) || []
          }
          size={"small"}
          onChange={(_event, value) => {
            const selectedItemAsCountryViewModel =
              scenario?.inputs?.countries?.find(
                (country) => country.display_name === value,
              );

            if (selectedItemAsCountryViewModel) {
              setFieldValue("selectedCountries", [
                selectedItemAsCountryViewModel,
              ]).catch((error) => {
                console.warn(error);
              });
              handleCountriesSave([
                selectedItemAsCountryViewModel,
              ] as CountryViewModel[]);
            }
          }}
          clearOnBlur
          handleHomeEndKeys
        />
      );
    default:
      return null;
  }
};

export default CountriesDropdownStepTwo;
