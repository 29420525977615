import { Box, Grid2 as Grid } from "@mui/material";
import { NoData, SearchBar } from "components/common";
import SelectDropdown from "../../../../components/common/SelectDropdown/SelectDropdown";
import Sort from "../../../../components/common/Sort/Sort";
import { SortConfig, SortValues } from "utils/SortConfig";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MacroAdjustmentRow from "../MacroAdjustmentRow/MacroAdjustmentRow";
import {
  CountryViewModel,
  MacroScenarioAdjustmentOverview,
  ViewMacroScenarioDto,
} from "orval/generated/models";
import { deepClone } from "utils";
import { debounce } from "lodash";
import {
  useGetAllScenariosMacroScenarioIdAdjustmentsGet,
  // useGetMacroFiltersScenarioMacroFiltersGet,
  // useGetTotalRtdlScenarioMacroTotalRtdlGet,
} from "orval/generated/endpoint";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import LoadingList from "components/common/LoadingList/LoadingList";

export type MacroAdjustmentsPageTableProps = {
  scenario: ViewMacroScenarioDto;
};

const MacroAdjustmentsPageTable = ({
  scenario,
}: MacroAdjustmentsPageTableProps) => {
  const { t } = useTranslation("macro");
  const showSnackbar = useSnackbar();

  // const { data: filters, isPending: isFetchingFilters } =
  //   useGetMacroFiltersScenarioMacroFiltersGet({
  //     countries: scenario.inputs?.countries?.map(
  //       (country) => country.country,
  //     ) as string[],
  //     target_year: scenario.inputs?.years?.target_year as number,
  //   });

  // const { data: totalRtdl, isPending: isFetchingTotalRtdl } =
  //   useGetTotalRtdlScenarioMacroTotalRtdlGet({
  //     countries: scenario.inputs?.countries?.map(
  //       (country) => country.country,
  //     ) as string[],
  //     target_year: scenario.inputs?.years?.target_year as number,
  //   });

  const {
    data: adjustments,
    isPending: isFetchingAdjustments,
    isError: errorFetchingAdjustments,
  } = useGetAllScenariosMacroScenarioIdAdjustmentsGet(scenario.id as number);

  const [searchValue, setSearchValue] = useState<string>("");
  const [sortValue, setSortValue] = useState<SortValues>(
    SortValues.DATE_UPDATED_DESCENDING,
  );
  const [sortedData, setSortedData] = useState<
    MacroScenarioAdjustmentOverview[]
  >([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);

  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (adjustments?.length) {
      const newState: { [key: string]: boolean } = {};
      adjustments.forEach(
        (adjustment) => (newState[`${adjustment.id}`] = false),
      );
      setExpanded(newState);
    }
  }, [adjustments]);

  const toggleExpanded = (id: number) => {
    setExpanded((prev) => ({ ...prev, [`${id}`]: !prev[`${id}`] }));
  };

  const debouncedHandleSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchValue(value);
      }, 300),
    [],
  );

  const handleCountriesTypeChange = useCallback(
    (newSelectedCountries: string[]) => {
      setSelectedCountries(newSelectedCountries);
    },
    [],
  );

  useEffect(() => {
    if (adjustments) {
      if (adjustments.length) {
        let sortedItems: MacroScenarioAdjustmentOverview[] = [];
        //search
        const words = searchValue
          .replace(",", " ")
          .trim()
          .toLocaleLowerCase()
          .split(" ");
        const _adjustments: any[] = deepClone(adjustments);

        if (words.length) {
          _adjustments.forEach((adjustment) => {
            words.forEach((word) => {
              if (
                `${adjustment.name}${adjustment.pillar}${adjustment.input_area}`
                  .toLocaleLowerCase()
                  .includes(word)
              )
                if (
                  !sortedItems.find(
                    (_adjustment) => _adjustment.id === adjustment.id,
                  )
                ) {
                  sortedItems.push(adjustment);
                }
            });
          });
        } else {
          sortedItems = _adjustments;
        }

        //sorting
        switch (sortValue) {
          case SortValues.NAME_DESCENDING:
            sortedItems = sortedItems.sort((a, b) =>
              b.name.localeCompare(a.name),
            );
            break;
          case SortValues.NAME_ASCENDING:
            sortedItems = sortedItems.sort((a, b) =>
              a.name.localeCompare(b.name),
            );
            break;
          case SortValues.DATE_UPDATED_ASCENDING:
            sortedItems = sortedItems.sort((a, b) => {
              const dateA = new Date(a.updated_on);
              const dateB = new Date(b.updated_on);
              return dateA.getTime() - dateB.getTime();
            });
            break;
          default:
            sortedItems = sortedItems.sort((a, b) => {
              const dateA = new Date(a.updated_on);
              const dateB = new Date(b.updated_on);
              return dateB.getTime() - dateA.getTime();
            });
            break;
        }

        //filters
        if (selectedCountries.length) {
          sortedItems = sortedItems.filter((adjustment) =>
            adjustment.countries.some((country: string) =>
              selectedCountries.includes(
                scenario.inputs?.countries?.find(
                  (_country) => _country.country === country,
                )?.display_name as string,
              ),
            ),
          );
        }

        setSortedData(sortedItems);
      } else {
        setSortedData([]);
      }
    }
  }, [
    adjustments,
    scenario.inputs?.countries,
    searchValue,
    selectedCountries,
    sortValue,
  ]);

  useEffect(() => {
    if (errorFetchingAdjustments) {
      showSnackbar(t("adjustmentsPage.failedToFetchAdjustments"), "error");
    }
  }, [errorFetchingAdjustments, showSnackbar, t]);

  return (
    <Box>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid size={{ xs: 12, lg: 7 }}>
          <SearchBar
            handleSearch={debouncedHandleSearch}
            disabled={!adjustments?.length}
          />
        </Grid>

        <Grid
          size={{ xs: 4, lg: 2 }}
          mt={"4px"}
          data-testid="coutries-dropdown"
        >
          {scenario?.inputs?.countries && (
            <SelectDropdown
              listItems={scenario.inputs?.countries?.map(
                (country) => country.display_name,
              )}
              savedSelectedItems={selectedCountries}
              onSave={handleCountriesTypeChange}
              title={t("adjustmentsPage.countriesDropdownTitle")}
              compact
              showSearchBar={false}
              selectAll={true}
              disabled={!adjustments?.length}
            ></SelectDropdown>
          )}
        </Grid>

        <Grid size={{ lg: 3 }} display="flex" justifyContent="flex-end">
          <Sort<SortValues>
            sortValue={sortValue}
            setSortValue={setSortValue}
            values={SortConfig}
            disabled={!adjustments?.length}
          />
        </Grid>
      </Grid>
      {isFetchingAdjustments ? (
        <LoadingList />
      ) : !adjustments?.length ? (
        <Box mt={2}>
          <NoData
            label={t("adjustmentsPage.noAdjustmentsAvailableLabel")}
            label2={t("adjustmentsPage.noAdjustmentsAvailableDescription")}
          />
        </Box>
      ) : !sortedData?.length ? (
        <Box mt={2}>
          <NoData label={t("adjustmentsPage.noAdjustmentsMatch")} />
        </Box>
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="center"
          spacing={2}
          my={2}
        >
          {sortedData.map((adjustment) => (
            <Grid maxWidth="100% !important" size={12} key={adjustment.id}>
              <MacroAdjustmentRow
                adjustmentDetails={adjustment}
                scenarioId={scenario.id as number}
                totalRtdl={0} // TODO: replace with real value
                scenarioCountries={
                  scenario.inputs?.countries as CountryViewModel[]
                }
                targetYear={scenario.inputs?.years?.target_year}
                expanded={expanded[`${adjustment.id}`]}
                toggleExpanded={toggleExpanded}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
export default MacroAdjustmentsPageTable;
