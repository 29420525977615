import { Typography, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import { FC, useEffect, useMemo, useState } from "react";
import { AppConstant } from "constants/AppConstant";
import { UserDataTypes } from "orval/generated/models";
import { formatNumber } from "utils/formatNumber/formatNumber";
import { numberParser } from "utils/numberParser";
import { ProductConfigurationsMicro } from "../types";

export type MicroAdjustmentsCustomPillProps = {
  // Tried to remove these any's - very difficult
  row: any;
  column: any;
  value: string;
  parentAccordion?: ProductConfigurationsMicro;
};

const MicroAdjustmentsCustomPill: FC<MicroAdjustmentsCustomPillProps> = ({
  row,
  column,
  value,
  parentAccordion,
}) => {
  const [newCellValue, setNewCellValue] = useState<string | null>(null);
  const [percentageChange, setPercentageChange] = useState<string | null>(null);

  const type = useMemo<"new_value" | "percentage" | null>(
    () => (row.subRows && row.subRows.length ? row.subRows[0].type : null),
    [row.subRows],
  );

  useEffect(() => {
    if (row.subRows[0] && column.id) {
      const rowValue = row[column.id as string];
      const subRowValue = row.subRows[0][column.id as string];

      const rowValueAsNumber: number = numberParser.parse(rowValue);

      const subRowValueAsNumber: number = numberParser.parse(subRowValue);

      const subRowValueAsString: string = subRowValue as string;

      if (subRowValueAsString === AppConstant.emptyCell) {
        setNewCellValue(null);
      } else {
        if (type === UserDataTypes.percentage && value) {
          const _newCellValue =
            rowValueAsNumber * (1 + subRowValueAsNumber / 100);

          setNewCellValue(
            formatNumber(_newCellValue, row.dataStructureKey, parentAccordion),
          );
          setPercentageChange(subRowValueAsNumber.toString());
        } else if (type === UserDataTypes.new_value) {
          const _percentageChange: number = parseFloat(
            (
              ((subRowValueAsNumber - rowValueAsNumber) /
                (rowValueAsNumber === 0 ? 1 : rowValueAsNumber)) *
              100
            ).toFixed(2),
          );
          setNewCellValue(
            formatNumber(
              subRowValueAsNumber,
              row.dataStructureKey,
              parentAccordion,
            ),
          );
          setPercentageChange(_percentageChange.toString());
        }
      }
    }
  }, [
    row,
    column,
    value,
    column.id,
    type,
    row.dataStructureKey,
    parentAccordion,
  ]);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        data-testid="custom-pill-box"
      >
        <Box mr={1}>
          <Typography variant="body2" data-testid="original-value">
            {value}
          </Typography>
        </Box>
        {newCellValue !== null ? (
          <Box data-testid="adjusted-value-box">
            <Chip
              label={
                parseFloat(percentageChange || "0") !== Infinity
                  ? `${newCellValue} (${formatNumber(percentageChange, row.dataStructureKey, parentAccordion)}%)`
                  : newCellValue
              }
              sx={{
                backgroundColor: "#C3D3E2 !important",
                color: "#40576D",
              }}
            />
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default MicroAdjustmentsCustomPill;
