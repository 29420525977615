import { useTranslation } from "react-i18next";
import {
  CategoryViewModel,
  CountryViewModel,
  DataExtractRequest,
  PackTypeViewModel,
  YearViewModel,
} from "orval/generated/models";
import { useMemo } from "react";
import { FormikProps } from "formik";
import SelectDropdown from "../../../../../components/common/SelectDropdown/SelectDropdown";
import Grid from "@mui/material/Grid2";

export type DataExtractionFormPartThreeProps = {
  countryDropdownData: CountryViewModel[] | undefined;
  categoriesDropdownData: CategoryViewModel[] | undefined;
  packTypesDropdownData: PackTypeViewModel[] | undefined;
  yearsDropdownData: YearViewModel[] | undefined;
  formik: FormikProps<DataExtractRequest>;
};

type DataExtractionFilterDropDown = {
  data:
    | CountryViewModel[]
    | CategoryViewModel[]
    | PackTypeViewModel[]
    | YearViewModel[]
    | undefined;
  labelKey: string;
  formikKey: string;
  savedItems: string[] | undefined;
  onSave: (selectedItems: string[]) => string[] | number[];
};

export default function DataExtractionFormPartThree({
  countryDropdownData,
  categoriesDropdownData,
  packTypesDropdownData,
  yearsDropdownData,
  formik,
}: DataExtractionFormPartThreeProps) {
  const { t } = useTranslation("productConfiguration");

  const dropdowns: DataExtractionFilterDropDown[] = useMemo(
    () => [
      {
        data: countryDropdownData,
        labelKey:
          "dataManagementPage.dataExtraction.optionalDropdowns.countries",
        formikKey: "country",
        savedItems:
          formik.values.filters?.country?.map(
            (countryCode: string) =>
              countryDropdownData!.find(
                (country: CountryViewModel) => country.country === countryCode,
              )!.display_name,
          ) ?? [],
        onSave: (selectedItems: number[] | string[]) => {
          return (selectedItems as string[]).map(
            (item: string) =>
              (countryDropdownData as CountryViewModel[])!.find(
                (d: CountryViewModel) => d.display_name === item,
              )!.country,
          );
        },
      },
      {
        data: categoriesDropdownData,
        labelKey:
          "dataManagementPage.dataExtraction.optionalDropdowns.category",
        formikKey: "category",
        savedItems: formik.values.filters?.category?.map(
          (selectedCategory: number) =>
            categoriesDropdownData!.find(
              (category: CategoryViewModel) => category.id === selectedCategory,
            )!.category,
        ),
        onSave: (selectedItems: string[]) => {
          return (selectedItems as string[]).map(
            (item: string) =>
              (categoriesDropdownData as CategoryViewModel[])!.find(
                (d: CategoryViewModel) => d.category === item,
              )!.id,
          );
        },
      },
      {
        data: packTypesDropdownData,
        labelKey:
          "dataManagementPage.dataExtraction.optionalDropdowns.packType",
        formikKey: "pack_type",
        savedItems: formik.values.filters?.pack_type?.map(
          (selectedPack: number) =>
            packTypesDropdownData!.find(
              (pack: PackTypeViewModel) => pack.id === selectedPack,
            )!.pack_type,
        ),
        onSave: (selectedItems: number[] | string[]) => {
          return (selectedItems as string[]).map(
            (selectedPack: string) =>
              (packTypesDropdownData as PackTypeViewModel[])!.find(
                (d: PackTypeViewModel) => d.pack_type === selectedPack,
              )!.id,
          );
        },
      },
      {
        data: yearsDropdownData,
        labelKey: "dataManagementPage.dataExtraction.optionalDropdowns.years",
        formikKey: "year",
        savedItems:
          formik.values.filters?.year?.map((year) => year.toString()) ?? [],
        onSave: (selectedItems: string[]) => {
          return selectedItems.map((item: string) => parseInt(item));
        },
      },
    ],
    [
      countryDropdownData,
      categoriesDropdownData,
      packTypesDropdownData,
      yearsDropdownData,
      formik.values.filters?.country,
      formik.values.filters?.category,
      formik.values.filters?.pack_type,
      formik.values.filters?.year,
    ],
  );

  function isCountryViewModel(item: any): item is CountryViewModel {
    return "country" in item;
  }

  function isCategoryViewModel(item: any): item is CategoryViewModel {
    return "category" in item;
  }

  function isPackTypeViewModel(item: any): item is PackTypeViewModel {
    return "pack_type" in item;
  }

  function isYearViewModel(item: any): item is YearViewModel {
    return "year" in item;
  }

  return (
    <>
      <Grid container spacing={3}>
        {dropdowns.map(
          ({ data, labelKey, formikKey, onSave, savedItems }, index) => {
            const dataArray = Array.isArray(data) ? data : [];
            return (
              <Grid size={3} key={index}>
                <SelectDropdown<string>
                  title={t(labelKey)}
                  listItems={
                    dataArray.map(
                      (
                        item:
                          | CountryViewModel
                          | CategoryViewModel
                          | PackTypeViewModel
                          | YearViewModel,
                      ) => {
                        let display;
                        if (isCountryViewModel(item)) {
                          display = item.display_name;
                        } else if (isCategoryViewModel(item)) {
                          display = item.category;
                        } else if (isPackTypeViewModel(item)) {
                          display = item.pack_type;
                        } else if (isYearViewModel(item)) {
                          display = item.year.toString();
                        } else {
                          return "";
                        }
                        return display;
                      },
                    ) ?? []
                  }
                  savedSelectedItems={savedItems ?? []}
                  onSave={async (selectedItems: string[]) => {
                    const dataToSave = onSave && onSave(selectedItems);
                    await formik.setFieldValue(
                      `filters.${formikKey}`,
                      dataToSave,
                    );
                  }}
                  selectAll
                />
              </Grid>
            );
          },
        )}
      </Grid>
    </>
  );
}
