import Box from "@mui/material/Box";
import {
  MacroAdjustmentCombination,
  MacroAdjustmentCombinations,
  MacroAdjustmentPillars,
  MacroAdjustmentWizardFormikValues,
} from "../MacroAdjustmentTypes";
import {
  CountryAverageDistanceTravelled,
  CountryAverageManufacturingEur,
  CountryPackagingAverageRecoveryRate,
  CountryViewModel,
  IngredientEntity,
  IngredientMacroFilters,
  MacroFilter,
  MacroFilters,
  MacroFiltersAndFilteredRTDL,
  MacroFiltersAndFilteredRTDLForProducts,
  MacroFiltersForProducts,
  MacroFilterValue,
  PackagingEntity,
  ViewMacroScenarioDto,
} from "orval/generated/models";
import { useCallback, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import {
  useGetAggregatedDistanceTravelledScenariosMacroAdjustmentsAggregatedLogisticsDistanceTravelledPost,
  useGetAggregatedManufacturingEurScenariosMacroAdjustmentsAggregatedManufacturingEurPost,
  useGetAggregatedPackagingWeightedAvgRecoveryRateScenariosMacroAdjustmentsAggregatedPackagingRecoveryRatePost,
  useGetMacroFiltersAndFilteredRtdlForProductsScenariosMacroFiltersForProductsPost,
  useGetTotalRtdlScenariosMacroTotalRtdlGet,
} from "orval/generated/endpoint";
import SalesVolumeChartArea from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/SalesVolumeChartArea/SalesVolumeChartArea";
import EntitiesDropdownStepTwo from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/EntitiesDropdownStepTwo/EntitiesDropdownStepTwo";
import ProductSelectionView from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/ProductSelectionView/ProductSelectionView";
import ApplyFiltersView from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/ApplyFiltersView/ApplyFiltersView";

export type MacroAdjustmentStepTwoProps = {
  adjustmentCombination: MacroAdjustmentCombination | null;
  scenario: ViewMacroScenarioDto | undefined;
  filtersData: MacroFiltersAndFilteredRTDL | undefined;
  setMacroFiltersParams: Function;
  filtersDataIsPending: boolean;
};

export const MacroAdjustmentStepTwo = ({
  adjustmentCombination,
  scenario,
  filtersData,
  setMacroFiltersParams,
  filtersDataIsPending,
}: MacroAdjustmentStepTwoProps) => {
  const { values, setFieldValue } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  const { data: totalRTDL } = useGetTotalRtdlScenariosMacroTotalRtdlGet({
    countries:
      scenario?.inputs?.countries?.map((country) => country.country) || [],
    target_year: scenario?.inputs?.years?.target_year || 2030,
  });

  const [filteredByProducts, setFilteredByProducts] =
    useState<MacroFiltersAndFilteredRTDLForProducts | null>(null);

  const { mutateAsync: getFilteredRTDLByProducts } =
    useGetMacroFiltersAndFilteredRtdlForProductsScenariosMacroFiltersForProductsPost();

  const [aggregatedWeightsForCountries, setAggregatedWeightsForCountries] =
    useState<
      | CountryPackagingAverageRecoveryRate[]
      | CountryAverageManufacturingEur[]
      | CountryAverageDistanceTravelled[]
      | null
    >(null);

  const { mutateAsync: getAggregatedRecoveryRateWeights } =
    useGetAggregatedPackagingWeightedAvgRecoveryRateScenariosMacroAdjustmentsAggregatedPackagingRecoveryRatePost();

  const { mutateAsync: getAggregatedManufacturingEur } =
    useGetAggregatedManufacturingEurScenariosMacroAdjustmentsAggregatedManufacturingEurPost();

  const { mutateAsync: getAggregatedLogisticsDistanceTravelledData } =
    useGetAggregatedDistanceTravelledScenariosMacroAdjustmentsAggregatedLogisticsDistanceTravelledPost();

  useEffect(() => {
    if (values.selectedProducts?.length) {
      const payload = {
        products: values.selectedProducts,
        entities: values.selectedEntities.map((entity) => entity?.id),
        base_year: scenario?.inputs?.years?.base_year as number,
        target_year: scenario?.inputs?.years?.target_year as number,
      };

      const fetchAggregatedWeights = async () => {
        let data;
        switch (adjustmentCombination?.combo) {
          case MacroAdjustmentCombinations.Packaging_RecoveryRate:
            data = await getAggregatedRecoveryRateWeights({ data: payload });
            setAggregatedWeightsForCountries(data);
            break;
          case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
            data = await getAggregatedManufacturingEur({ data: payload });
            setAggregatedWeightsForCountries(data);
            break;
          case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
            data = await getAggregatedLogisticsDistanceTravelledData({
              data: payload,
            });
            setAggregatedWeightsForCountries(data);
            break;
          default:
            return;
        }
      };

      fetchAggregatedWeights().catch((e) => console.warn(e));
    }
  }, [
    adjustmentCombination,
    getAggregatedLogisticsDistanceTravelledData,
    getAggregatedManufacturingEur,
    getAggregatedRecoveryRateWeights,
    scenario?.inputs?.years?.base_year,
    scenario?.inputs?.years?.target_year,
    values.selectedEntities,
    values.selectedProducts,
  ]);

  useEffect(() => {
    if (
      Boolean(values.selectedProducts.length) &&
      (values.selectedPillar === MacroAdjustmentPillars.Packaging ||
        values.selectedPillar === MacroAdjustmentPillars.Manufacturing ||
        values.selectedPillar === MacroAdjustmentPillars.Logistics)
    ) {
      const fetchRtdl = async () => {
        try {
          const filtered = await getFilteredRTDLByProducts({
            data: values.selectedProducts,
          });

          if (filtered) {
            const checkEntities: MacroFilter[] = [];
            const entityIds = new Set<number>();
            let macroFilterType: keyof MacroFiltersForProducts =
              "part_materials";

            switch (values.selectedPillar) {
              case MacroAdjustmentPillars.Manufacturing:
                macroFilterType = "energy_types";
                break;
              case MacroAdjustmentPillars.Logistics:
                macroFilterType = "transport_modes";
                break;
              default:
                break;
            }

            values.selectedEntities.forEach((entity) => {
              const record = filtered.filters[macroFilterType].find(
                (e: { id: number }) => e.id === entity.id,
              );
              if (record && !entityIds.has(entity.id)) {
                checkEntities.push(entity);
                entityIds.add(entity.id);
              }
            });

            if (checkEntities.length !== values.selectedEntities.length) {
              setFieldValue("selectedEntities", checkEntities).catch((e) =>
                console.warn(e),
              );
            }
            setFilteredByProducts(filtered);
          }
        } catch (e) {
          console.warn(e);
        }
      };

      fetchRtdl().catch((error) => {
        console.warn(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getFilteredRTDLByProducts,
    setFieldValue,
    values.selectedPillar,
    values.selectedProducts,
  ]);

  const handleCountriesSave = useCallback(
    (countries: CountryViewModel[]) => {
      let selectedCountryCodes: string[] = [];
      if (!countries.length) {
        selectedCountryCodes =
          scenario?.inputs?.countries?.map((country) => country.country) || [];
      } else {
        selectedCountryCodes = countries?.map((country) => country.country);
      }
      setFieldValue("payload.filters", {
        ...values.payload.filters,
        countries: selectedCountryCodes,
      }).catch((error) => {
        console.warn(error);
      });

      setMacroFiltersParams((prev: any) => ({
        ...prev,
        countries: selectedCountryCodes,
      }));
    },
    [
      scenario?.inputs?.countries,
      setFieldValue,
      setMacroFiltersParams,
      values.payload.filters,
    ],
  );

  const handleEntitiesSave = useCallback(
    (
      selectedDropdownItems: MacroFilterValue[],
      entityType: keyof MacroFilters | keyof MacroFiltersForProducts,
    ) => {
      let selectedEntities: MacroFilter[] = [];

      switch (adjustmentCombination?.combo) {
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
          selectedEntities =
            filtersData?.filters[entityType].filter((item: MacroFilter) =>
              selectedDropdownItems.includes(item.value),
            ) || [];
          break;

        default:
          selectedEntities =
            filteredByProducts?.filters[
              entityType as keyof MacroFiltersForProducts
            ].filter((item: MacroFilter) =>
              selectedDropdownItems.includes(item.value),
            ) || [];
          break;
      }

      if (selectedEntities?.length) {
        setFieldValue("selectedEntities", selectedEntities).catch((error) => {
          console.warn(error);
        });
      } else {
        setFieldValue("selectedEntities", []).catch((error) => {
          console.warn(error);
        });
      }

      if (selectedEntities?.length !== values.payload.entities.length) {
        switch (adjustmentCombination?.combo) {
          case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
          case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
            setFieldValue(
              "payload.entities",
              selectedEntities?.map((entity) => {
                const payloadEntities: IngredientEntity[] = values.payload
                  .entities as unknown as IngredientEntity[];
                const existing = payloadEntities.find(
                  (e) => e.ingredient_id === entity.id,
                );

                if (existing) {
                  return existing;
                } else {
                  return {
                    ingredient_id: entity.id,
                    adjusted_value: null,
                    action: "percentage",
                  };
                }
              }),
            ).catch((error) => {
              console.warn(error);
            });
            break;

          case MacroAdjustmentCombinations.Packaging_RecoveryRate:
          case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
          case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
            const countries: string[] = aggregatedWeightsForCountries?.map(
              (c) => c.country,
            ) as string[];

            setFieldValue(
              "payload.entities",
              selectedEntities
                ?.map((entity) => {
                  const payloadEntities: any[] = values.payload.entities;
                  switch (adjustmentCombination.combo) {
                    case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
                      const existingEnergyTypeEntities = payloadEntities.filter(
                        (e) => e.energy_type_id === entity.id,
                      );
                      if (existingEnergyTypeEntities.length > 0) {
                        return existingEnergyTypeEntities;
                      } else {
                        return countries.map((country) => ({
                          energy_type_id: entity.id,
                          adjusted_value: null,
                          country,
                        }));
                      }
                    case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
                      const existingTransportModeEntities =
                        payloadEntities.filter(
                          (e) => e.transport_mode_id === entity.id,
                        );
                      if (existingTransportModeEntities.length > 0) {
                        return existingTransportModeEntities;
                      } else {
                        return countries.map((country) => ({
                          transport_mode_id: entity.id,
                          adjusted_value: null,
                          country,
                        }));
                      }
                    default:
                      const existingPartMaterialEntities =
                        payloadEntities.filter(
                          (e) => e.part_material_id === entity.id,
                        );
                      if (existingPartMaterialEntities.length > 0) {
                        return existingPartMaterialEntities;
                      } else {
                        return Object.keys(countries).map((country) => ({
                          part_material_id: entity.id,
                          adjusted_value: null,
                          action: "new_value",
                          country,
                        }));
                      }
                  }
                })
                .flat(),
            ).catch((error) => {
              console.warn(error);
            });
            break;

          default:
            setFieldValue(
              "payload.entities",
              selectedEntities?.map((entity) => {
                const payloadEntities: PackagingEntity[] = values.payload
                  .entities as unknown as PackagingEntity[];

                const existing = payloadEntities.find(
                  (e) => e.part_material_id === entity.id,
                );

                if (existing) {
                  return existing;
                } else {
                  return {
                    part_material_id: entity.id,
                    adjusted_value: null,
                    action:
                      adjustmentCombination?.combo ===
                        MacroAdjustmentCombinations.Packaging_RecycledContent ||
                      adjustmentCombination?.combo ===
                        MacroAdjustmentCombinations.Packaging_NetLossRate
                        ? "new_value"
                        : "percentage",
                  };
                }
              }),
            ).catch((error) => {
              console.warn(error);
            });
            break;
        }
      }
    },
    [
      adjustmentCombination,
      aggregatedWeightsForCountries,
      filteredByProducts?.filters,
      filtersData?.filters,
      setFieldValue,
      values.payload.entities,
    ],
  );

  const handleResetPageTwo = useCallback(() => {
    setFieldValue("payload.filters", {
      categories: [],
      brand_groups: [],
      brands: [],
      ingredients: [],
    }).catch((error) => {
      console.warn(error);
    });

    setFieldValue("selectedCountries", []).catch((error) => {
      console.warn(error);
    });
    setFieldValue("selectedEntities", []).catch((error) => {
      console.warn(error);
    });
    setMacroFiltersParams({
      countries:
        scenario?.inputs?.countries?.map((country) => country.country) || [],
      target_year: scenario?.inputs?.years?.target_year || 2045,
    });
  }, [
    scenario?.inputs?.countries,
    scenario?.inputs?.years?.target_year,
    setFieldValue,
    setMacroFiltersParams,
  ]);

  useEffect(() => {
    if (filtersData) {
      Object.keys(values.payload.filters ?? {}).forEach((filter) => {
        if (
          filtersData.filters[filter as keyof MacroFilters]?.length <
          (values.payload.filters[filter as keyof IngredientMacroFilters]
            ?.length as number)
        ) {
          setFieldValue(
            `payload.filters.${filter}`,
            filtersData.filters[filter as keyof MacroFilters]?.map(
              (_filter) => _filter.id,
            ),
          ).catch((error) => {
            console.warn(error);
          });

          setMacroFiltersParams((prev: any) => ({
            ...prev,
            [filter]: filtersData.filters[filter as keyof MacroFilters]?.map(
              (_filter) => _filter.id,
            ),
          }));
        }
      });
    }
  }, [
    filtersData,
    setFieldValue,
    setMacroFiltersParams,
    values.payload.filters,
  ]);

  return (
    <Box>
      {(() => {
        switch (adjustmentCombination?.combo) {
          case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
          case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
          case MacroAdjustmentCombinations.Packaging_RecycledContent:
          case MacroAdjustmentCombinations.Packaging_NetLossRate:
          case MacroAdjustmentCombinations.Packaging_RecoveryRate:
          case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
          case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
          case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
            return (
              <ProductSelectionView
                countriesList={scenario?.inputs?.countries || []}
                targetYear={scenario?.inputs?.years?.target_year as number}
              />
            );
          default:
            return (
              <ApplyFiltersView
                handleResetPageTwo={handleResetPageTwo}
                adjustmentCombination={adjustmentCombination || undefined}
                filtersData={filtersData}
                setMacroFiltersParams={setMacroFiltersParams}
                scenario={scenario}
                handleCountriesSave={handleCountriesSave}
              />
            );
        }
      })()}

      <SalesVolumeChartArea
        totalRTDL={totalRTDL || 0}
        filteredRTDL={
          (values.selectedPillar === MacroAdjustmentPillars.Packaging ||
          values.selectedPillar === MacroAdjustmentPillars.Manufacturing ||
          values.selectedPillar === MacroAdjustmentPillars.Logistics
            ? values.selectedProducts.length === 0
              ? 0
              : filteredByProducts?.filtered_rtdl
            : filtersData?.filtered_rtdl) ??
          (filtersData?.filtered_rtdl || 0)
        }
        targetYear={scenario?.inputs?.years?.target_year || 2030}
        filtersDataIsPending={filtersDataIsPending}
      />

      <EntitiesDropdownStepTwo
        adjustmentCombination={adjustmentCombination || undefined}
        filtersData={filtersData}
        filtersByProducts={filteredByProducts?.filters}
        handleEntitiesSave={handleEntitiesSave}
      />
    </Box>
  );
};
