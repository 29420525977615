import { useCallback, useEffect, useState } from "react";
import MacroAdjustmentStepThreeBasicTable, {
  TableEntityType,
} from "./MacroAdjustmentStepThreeBasicTable";
import {
  AverageDistanceTravelled,
  AverageManufacturingEur,
  PackagingAverageRecoveryRate,
  PackagingEntity,
} from "orval/generated/models";
import { useFormikContext } from "formik";
import {
  MacroAdjustmentCombinations,
  MacroAdjustmentWizardFormikValues,
} from "../MacroAdjustmentTypes";
import { MRT_ColumnDef } from "material-react-table";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { CustomIcon, Icons } from "components/common";
import { useTranslation } from "react-i18next";
import {
  convertIsocodeToCountry,
  useCountries,
} from "utils/CountriesProvider/CountriesProvider";

export type MacroAdjustmentStepThreeAccordionArrayProps = {
  aggregatedData: any[] | null; //TODO: add other similar types of aggregated weights
  columns: MRT_ColumnDef<TableEntityType>[];
  adjustmentCombination: MacroAdjustmentCombinations;
};

function MacroAdjustmentStepThreeAccordionArray({
  aggregatedData,
  columns,
  adjustmentCombination,
}: MacroAdjustmentStepThreeAccordionArrayProps) {
  const { palette } = useTheme();
  const { values } = useFormikContext<MacroAdjustmentWizardFormikValues>();
  const { t } = useTranslation(["macro"]);
  const { countries } = useCountries();

  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
  const [columnVisibility, setColumnVisibility] = useState({
    baseYear: true,
    targetYear: true,
  });

  const getTableData = useCallback(
    (aggregatedData: any): TableEntityType[] => {
      switch (adjustmentCombination) {
        case MacroAdjustmentCombinations.Packaging_RecoveryRate:
          return values.selectedEntities.map((entity) => {
            const existingEntity = values.payload.entities.find(
              (adjustedEntity) =>
                adjustedEntity.part_material_id === entity.id &&
                adjustedEntity.country === aggregatedData.country,
            ) as PackagingEntity | undefined;

            return {
              id: entity.id,
              adjusted_value: existingEntity?.adjusted_value ?? null,
              action: "new_value",
              baseYear: aggregatedData.base_year.find(
                (item: PackagingAverageRecoveryRate) => item.id === entity.id,
              )?.avg_recovery_rate,
              targetYear: aggregatedData.target_year.find(
                (item: PackagingAverageRecoveryRate) => item.id === entity.id,
              )?.avg_recovery_rate,
              country: aggregatedData.country,
            };
          });
        case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
          return values.selectedEntities.map((selectedEntity) => {
            const existingEntity = values.payload.entities.find(
              (adjustedEntity) =>
                adjustedEntity.energy_type_id === selectedEntity.id &&
                adjustedEntity.country === aggregatedData.country,
            );

            return {
              id: selectedEntity.id,
              adjusted_value: existingEntity?.adjusted_value ?? null,
              baseYear: aggregatedData.base_year.find(
                (item: AverageManufacturingEur) =>
                  item.id === selectedEntity.id,
              )?.avg_eur,
              targetYear: aggregatedData.target_year.find(
                (item: AverageManufacturingEur) =>
                  item.id === selectedEntity.id,
              )?.avg_eur,
              country: aggregatedData.country,
            };
          });
        case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
          return values.selectedEntities.map((selectedEntity) => {
            const existingEntity = values.payload.entities.find(
              (adjustedEntity) =>
                adjustedEntity.transport_mode_id === selectedEntity.id &&
                adjustedEntity.country === aggregatedData.country,
            );

            return {
              id: selectedEntity.id,
              adjusted_value: existingEntity?.adjusted_value ?? null,
              baseYear: aggregatedData.base_year.find(
                (item: AverageDistanceTravelled) =>
                  item.id === selectedEntity.id,
              )?.avg_distance,
              targetYear: aggregatedData.target_year.find(
                (item: AverageDistanceTravelled) =>
                  item.id === selectedEntity.id,
              )?.avg_distance,
              country: aggregatedData.country,
            };
          });
        default:
          return [];
      }
    },
    [adjustmentCombination, values.payload.entities, values.selectedEntities],
  );

  useEffect(() => {
    const expandedObject: { [key: string]: boolean } = {};

    aggregatedData?.forEach((accordion, i) => {
      switch (adjustmentCombination) {
        case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
          if (!expandedObject[accordion.country]) {
            expandedObject[accordion.country] = i === 0;
          }
          break;
        default:
          break;
      }
    });

    setExpanded(expandedObject);
  }, [adjustmentCombination, aggregatedData]);

  const handleExpandedChange = useCallback((country: string) => {
    setExpanded((prev) => ({ ...prev, [country]: !prev[country] }));
  }, []);

  const handleExpandAll = useCallback(() => {
    const expandedObject: { [key: string]: boolean } = {};

    Object.keys(expanded).forEach((key) => {
      expandedObject[key] = true;
    });
    setExpanded(expandedObject);
  }, [expanded]);

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid>
          <Button variant="text" onClick={handleExpandAll}>
            {t("macro:adjustmentsPage.wizard.stepThree.expandAll")}
          </Button>
        </Grid>
      </Grid>
      {aggregatedData?.map((accordion) => (
        <Accordion
          key={accordion.country}
          expanded={expanded?.[accordion.country] || false}
          onChange={() => handleExpandedChange(accordion.country)}
        >
          <AccordionSummary
            expandIcon={
              <CustomIcon
                name={Icons.CHEVRON_DOWN}
                width={20}
                height={20}
                fill={palette.primary.main}
              />
            }
          >
            <Typography className="header-H4">
              {convertIsocodeToCountry(countries, accordion.country)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MacroAdjustmentStepThreeBasicTable
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              columns={columns}
              tableData={getTableData(accordion)}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

export default MacroAdjustmentStepThreeAccordionArray;
