import {
  createContext,
  useContext,
  useEffect,
  useState,
  FC,
  ReactNode,
} from "react";
import { useGetMemberCountriesMembersCountriesGet } from "orval/generated/endpoint";
import { CountryViewModel } from "orval/generated/models";

export interface CountriesContextValue {
  countries: CountryViewModel[];
  isLoading: boolean;
  error: Error | null;
}

export const CountriesContext = createContext<
  CountriesContextValue | undefined
>(undefined);

export const CountriesProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const {
    data,
    error: fetchError,
    isLoading,
  } = useGetMemberCountriesMembersCountriesGet();
  const [countries, setCountries] = useState<CountryViewModel[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (data) {
      setCountries(data);
    }
    if (fetchError) {
      setError(
        fetchError instanceof Error ? fetchError : new Error("Unknown error"),
      );
    }
  }, [data, fetchError]);

  return (
    <CountriesContext.Provider value={{ countries, isLoading, error }}>
      {children}
    </CountriesContext.Provider>
  );
};

export const useCountries = () => {
  const context = useContext(CountriesContext);
  if (!context) {
    throw new Error("useCountries must be used within a CountriesProvider");
  }
  return context;
};

export const convertIsocodeToCountry = (
  countries: CountryViewModel[],
  isoCode: string,
): string | undefined => {
  const country = countries.find((c) => c.country === isoCode);
  return country?.display_name || isoCode;
};
