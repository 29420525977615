import { CustomIconProps, Icons } from "./types";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow.svg";
import { ReactComponent as ChevronDoubleRightIcon } from "../../../assets/icons/chevronDoubleRight.svg";
import { ReactComponent as DatabaseIcon } from "../../../assets/icons/database.svg";
import { ReactComponent as DownloadSimpleIcon } from "../../../assets/icons/downloadSimple.svg";
import { ReactComponent as GrowRevenueIcon } from "../../../assets/icons/growRevenue.svg";
import { ReactComponent as HomeIcon } from "../../../assets/icons/home.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as ToggleIcon } from "../../../assets/icons/toggle.svg";
import { ReactComponent as CircleDeleteIcon } from "../../../assets/icons/CircleDelete.svg";
import { ReactComponent as ExclamationIcon } from "../../../assets/icons/exclamation.svg";
import { ReactComponent as ConfirmedIcon } from "../../../assets/icons/confirmed.svg";
import { ReactComponent as InformationIcon } from "../../../assets/icons/information.svg";
import { ReactComponent as HelpOutlineIcon } from "../../../assets/icons/help-outline.svg";
import { ReactComponent as ChevronLeftIcon } from "../../../assets/icons/ChevronLeft.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close.svg";
import { ReactComponent as CircleErrorIcon } from "../../../assets/icons/CircleError.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/ArrowRight2.svg";
import { ReactComponent as ChevronRightIcon } from "../../../assets/icons/ChevronRight.svg";
import { ReactComponent as MoveIcon } from "../../../assets/icons/Move.svg";
import { ReactComponent as UpdateIcon } from "../../../assets/icons/Update.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/ChevronDown.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/ArrowLeft.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/Filter.svg";
import { ReactComponent as RevertIcon } from "../../../assets/icons/Revert.svg";
import { ReactComponent as CheckmarkIcon } from "../../../assets/icons/Checkmark.svg";
import { ReactComponent as LockClosedIcon } from "../../../assets/icons/LockClosed.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { ReactComponent as CircleRemoveIcon } from "../../../assets/icons/CircleRemove.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/Upload.svg";
import { ReactComponent as MoreVerticalIcon } from "../../../assets/icons/MoreVertical.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/Logout.svg";
import { ReactComponent as AccountBoxIcon } from "../../../assets/icons/AccountBox.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/Email.svg";
import { ReactComponent as ListIcon } from "../../../assets/icons/List.svg";
import { ReactComponent as CircleCheckmarkIcon } from "../../../assets/icons/CircleCheckmark.svg";
import { ReactComponent as SaveIcon } from "../../../assets/icons/Save.svg";
import { CustomIconDefaultConfig } from "./config";

export function getIcon({ name, width, height, fill }: CustomIconProps): any {
  const iconWidth = width || CustomIconDefaultConfig.width;
  const iconHeight = height || CustomIconDefaultConfig.height;
  switch (name) {
    case Icons.ADD:
      return <AddIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.EDIT:
      return <EditIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.ARROW:
      return <ArrowIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.ARROW_RIGHT:
      return (
        <ArrowRightIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.ARROR_LEFT:
      return (
        <ArrowLeftIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.CHEVRON_DOUBLE_RIGHT:
      return (
        <ChevronDoubleRightIcon
          width={iconWidth}
          height={iconHeight}
          fill={fill}
        />
      );
    case Icons.DATABASE:
      return <DatabaseIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.DOWNLOAD_SIMPLE:
      return (
        <DownloadSimpleIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.GROW_RESPONSE:
      return (
        <GrowRevenueIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.HOME:
      return <HomeIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.SETTINGS:
      return <SettingsIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.TOGGLE:
      return <ToggleIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.CIRCLE_DELETE:
      return (
        <CircleDeleteIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.CIRCLE_ERROR:
      return (
        <CircleErrorIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.CIRCLE_REMOVE:
      return (
        <CircleRemoveIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.EXCLAMATION:
      return (
        <ExclamationIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.CONFIRMED:
      return (
        <ConfirmedIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.INFORMATION:
      return (
        <InformationIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.HELP_OUTLINE:
      return (
        <HelpOutlineIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.CHEVRON_LEFT:
      return (
        <ChevronLeftIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.CHEVRON_RIGHT:
      return (
        <ChevronRightIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.CHEVRON_DOWN:
      return (
        <ChevronDownIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.CLOSE:
      return <CloseIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.MOVE:
      return <MoveIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.UPDATE:
      return <UpdateIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.FILTER:
      return <FilterIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.REVERT:
      return <RevertIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.CHECKMARK:
      return (
        <CheckmarkIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.LOCK_CLOSED:
      return (
        <LockClosedIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.DELETE:
      return <DeleteIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.UPLOAD:
      return <UploadIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.MORE_VERTICAL:
      return (
        <MoreVerticalIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.SEARCH:
      return <SearchIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.LOGOUT:
      return <LogoutIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.EMAIL:
      return <EmailIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.ACCOUNT_BOX:
      return (
        <AccountBoxIcon width={iconWidth} height={iconHeight} fill={fill} />
      );
    case Icons.LIST:
      return <ListIcon width={iconWidth} height={iconHeight} fill={fill} />;
    case Icons.CIRCLE_CHECKMARK:
      return (
        <CircleCheckmarkIcon
          width={iconWidth}
          height={iconHeight}
          fill={fill}
        />
      );
    case Icons.SAVE:
      return <SaveIcon width={iconWidth} height={iconHeight} fill={fill} />;
    default:
      break;
  }
}
