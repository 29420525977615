export enum Icons {
  ADD = "Add",
  ARROW = "Arrow",
  DATABASE = "Database",
  DOWNLOAD_SIMPLE = "DownloadSimple",
  GROW_RESPONSE = "GrowRevenue",
  HOME = "Home",
  SETTINGS = "Settings",
  TOGGLE = "Toggle",
  CIRCLE_DELETE = "CircleDelete",
  CIRCLE_REMOVE = "CircleRemove",
  CIRCLE_ERROR = "CircleError",
  EXCLAMATION = "Exclamation",
  CONFIRMED = "Confirmed",
  INFORMATION = "Information",
  HELP_OUTLINE = "HelpOutline",
  CHEVRON_LEFT = "ChevronLeft",
  CLOSE = "Close",
  ARROW_RIGHT = "ArrowRight",
  ARROR_LEFT = "ArrowLeft",
  CHEVRON_RIGHT = "ChevronRight",
  CHEVRON_DOUBLE_RIGHT = "ChevronDoubleRight",
  CHEVRON_DOWN = "ChevronDown",
  MOVE = "Move",
  UPDATE = "Update",
  FILTER = "Filter",
  REVERT = "Revert",
  CHECKMARK = "Checkmark",
  LOCK_CLOSED = "LockClosed",
  EDIT = "Edit",
  DELETE = "Delete",
  UPLOAD = "Upload",
  MORE_VERTICAL = "MoreVertical",
  SEARCH = "Search",
  LOGOUT = "Logout",
  EMAIL = "Email",
  ACCOUNT_BOX = "AccountBox",
  LIST = "List",
  CIRCLE_CHECKMARK = "CircleCheckmark",
  SAVE = "Save",
}

export type IconName = Icons;

export type CustomIconProps = {
  name: IconName;
  width?: number | string | undefined;
  height?: number | string | undefined;
  fill?: string;
};
